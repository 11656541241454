import { LabeledFileHandle } from "../pictures";
import { QuestionType } from "../questionTypes";
import { QuizSettings } from "./quizSettings";
import { QuestionPrompt, isFillInTheBlankPrompt } from "./prompt";
import { QuestionSettingsModel } from "../QuestionSettings";
import { Hints } from "../QuestionHints";
import { QuizProgressEnvelope } from "./progress";
import * as E from "TextEditor";

export type Quiz = {
  quizId: string;
  settings: QuizSettings;
  questions: QuizQuestion[];
  startTime: string;
  completionTime?: string;
  canceled: boolean;
  progress: QuizProgressEnvelope | null;
  ordinal: number;
};

export function rehydrateFragmentIds(quiz: Quiz): Quiz {
  const newQuiz = {
    ...quiz,
    questions: quiz.questions.map(q => {
      const b = q.body;
      if (isFillInTheBlankPrompt(q.body) && q.body.distractors) {
        return {
          ...q,
          distractors: q.body.distractors.map(d => E.rehydrate(d))
        };
      } else {
        return q;
      }
    })
  }
  return newQuiz;
}

export type QuizQuestion = {
  questionId: string;
  index: number;
  body: QuestionPrompt;
  images: LabeledFileHandle[];
  questionType: QuestionType;
  settings: QuestionSettingsModel;
  hints: Hints;
};
