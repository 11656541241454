import { Content, constructContent } from "TextEditor";
import { LabeledFileHandle } from "pictures";
import { SelectableAnswer, constructSelectableAnswer } from "./selectableAnswer";
import { OrderingAnswer, constructOrderingAnswer } from "./orderingAnswer";

export const questionTypes = [
  'write-in',
  'multiple-choice',
  'fill-in-the-blank',
  'true-false',
  'multi-select',
  'ordering'
] as const;
export type QuestionType = typeof questionTypes[number];

export const QUESTION_TYPE_COUNT = questionTypes.length;

export type WriteIn = {
  question: Content;
  answers: WriteInAnswer[];
};

export type WriteInAnswer = {
  content: Content;
  position: number;
};

export type MultipleChoice = {
  question: Content;
  correctAnswer: SelectableAnswer;
  otherAnswers: SelectableAnswer[];
};

export type FillInTheBlank = {
  // elements: FillInTheBlankElement[];
  body: Content;
  distractors: Content[];
}

export type FillInTheBlankElement = TextElement | BlankElement | SuppliedElement;

export type TextElement = {
  text: Content;
};

export type BlankElement = {
  answer: Content;
};

export type SuppliedElement = {
  suppliedAnswer: Content;
};

export type TrueFalse = {
  question: Content;
  validity: boolean;
};

export type MultiSelect = {
  question: Content;
  correctAnswers: SelectableAnswer[];
  otherAnswers: SelectableAnswer[];
};

export type Ordering = {
  question: Content;
  order: OrderingAnswer[];
  inactivePartitions: number[];
};

export type QuestionBody = WriteIn | MultipleChoice | FillInTheBlank | TrueFalse | MultiSelect | Ordering;

export type TextualQuestion = WriteIn | MultipleChoice | TrueFalse | MultiSelect | Ordering;

export type SelectionQuestion = MultipleChoice | MultiSelect;

export function initialWriteIn(): WriteIn {
  return {
    question: constructContent(),
    answers: [{
      content: constructContent(),
      position: 0
    }]
  };
}

export function initialMultipleChoice(): MultipleChoice {
  return {
    question: constructContent(),
    correctAnswer: constructSelectableAnswer(),
    otherAnswers: []
  };
}

export function initialFillInTheBlank(): FillInTheBlank {
  return {
    body: constructContent(),
    distractors: []
  };
}

export function initialTrueFalse(): TrueFalse {
  return {
    question: constructContent(),
    validity: false
  };
}

export function initialMultiSelect(): MultiSelect {
  return {
    question: constructContent(),
    correctAnswers: [],
    otherAnswers: []
  };
}

export function initialOrdering(): Ordering {
  return {
    question: constructContent(),
    order: [0,1].map(position => constructOrderingAnswer(position)),
    inactivePartitions: []
  }
}

// export function isTextElement(f: E.EditorFragment): e is TextElement {
//   return (e as TextElement).text !== undefined;
// }

// export function isSuppliedElement(f: E.EditorFragment): e is SuppliedElement {
//   return (e as SuppliedElement).suppliedAnswer !== undefined;
// }

// export function isBlankElement(f: E.EditorFragment): e is BlankElement {
//   return (e as BlankElement).answer !== undefined;
// }
