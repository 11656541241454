import { SignUpResponse } from 'authentication';
import { User } from "user";
import { LatestQuestionDefaults, NewQuestionIdEnvelope } from "NewQuestionPage";
import { Glossary } from "language";
import { LabeledFile } from "pictures";
import { QuestionSubmission } from "questionTypes";
import { QuestionEditSubmission } from "QuestionEditor";
import { QuestionEditContext, QuestionContext } from "questionContext";
import { ImageType } from "pictures";
import { NewQuizContext, QuizSettings, Quiz, QuizSubmission, QuizIdentifier, QuizProgressEnvelope, QuizFeasibility } from "quiz";
import { ActiveQuizPayload } from "quizContext";
import { QuizResultEnvelope, RetakeVersion } from "QuizResultsPage";
import { ResultOrStatus } from "./resultOrStatus";
import { QuestionSearchPayload, QuestionPartialLoad } from "QuestionsPage";
import { HomePageContext } from "UserHomePage";
import { QuestionVersionHistory } from "QuestionPage";

export const port = 8063;
const TOKEN_KEY = 'token';
const api = process.env.REACT_APP_BACKEND_LOCATION;// `https://localhost:${port}`;
const MULTIPART_FORM_DATA = 'multipart/form-data';
const APPLICATION_JSON = 'application/json';
const PNG = 'image/png';
type MimeType = (typeof MULTIPART_FORM_DATA) | (typeof APPLICATION_JSON) | (typeof PNG);

function fetchConfiguration(method: string, mimeType?: string | null): RequestInit {
  return {
    method: method,
    mode: "cors",
    credentials: "include",
    headers: {
      ...contentTypeByMethod(method, mimeType),
      'Authorization': `Bearer ${getToken()}`
    }
  };
}

function contentTypeByMethod(method: string, mimeType?: string | null): Record<string, string> {
  if (['GET', 'OPTIONS'].includes(method)) {
    return {
      "Content-Length": "0"
    };
  } else if (['POST', 'PUT'].includes(method)) {
    return {
      "Content-Type": mimeType || "application/json"
    };
  } else {
    throw Error(`unaccepted method ${method}`);
  }
}

function get(url: string): Promise<any> {
  return getRaw(url).then(response => response.json());
}

function post(url: string, body: any): Promise<any> {
  return postRaw(url, body)
  .then(response => response.json());
}

function put(url: string, body: any): Promise<any> {
  return putRaw(url, body)
  .then(response => response.json());
} 

function getRaw(url: string): Promise<any> {
  return fetch(`${api}${url}`, fetchConfiguration("GET"));
}

function postRaw(url: string, body: any, mimeType?: MimeType | null): Promise<any> {
  const finalBody = (mimeType === APPLICATION_JSON || mimeType === undefined) ? JSON.stringify(body) : body;
  const configuration = fetchConfiguration("POST", mimeType);
  console.log(url, configuration);
  return fetch(`${api}${url}`, {
    ...configuration,
    body: finalBody,
  });
}

function putRaw(url: string, body: any): Promise<any> {
  return fetch(`${api}${url}`, {
    ...fetchConfiguration("PUT"),
    body: JSON.stringify(body)
  });
}

function normalizeResultOrStatus<T>(response: Response): Promise<ResultOrStatus<T>> {
  if (response.status >= 300) {
    return Promise.resolve({
      status: response.status
    });
  } else {
    console.log(response);
    return response.json();
  }
}

function getResultOrStatus<T>(url: string): Promise<ResultOrStatus<T>> {
  return getRaw(url).then(response => normalizeResultOrStatus(response));
}

function postResultOrStatus<T>(url: string, body: any, mimeType?: MimeType | null): Promise<ResultOrStatus<T>> {
  return postRaw(url, body, mimeType).then(response => normalizeResultOrStatus(response));
}

function putResultOrStatus<T>(url: string, body: any): Promise<ResultOrStatus<T>> {
  return putRaw(url, body).then(response => normalizeResultOrStatus(response));
}

export function signUp(email: string, loginName: string, password: string): Promise<SignUpResponse> {
  const body = {
    email: email,
    password: password,
    loginName: loginName === "" ? null : loginName
  }
  return postRaw("/sign-up", body)
  .then(result => {
    return storeTokenOnResponse(result);
  });
}

export function ongoingSession(): Promise<User | null> {
  return getRaw("/ongoing-session")
  .then(async response => {
    console.log(response);
    if (response.status === 404 || response.status === 401) {
      return null;
    } else {
      return response.json();
    }
  })
  .catch(error => {
    console.error(error);
    return null;
  })
}

export function signOut(): Promise<void> {
  return post("/sign-out", {}).finally(() => removeToken());
}

export function signIn(identifier: string, password: string): Promise<User | null> {
  return postRaw("/sign-in", {
    identifier: identifier,
    password: password
  })
  .then(async result => {
    return storeTokenOnResponse(result);
  })
}

function storeTokenOnResponse(result: Response) {
  if (result.status === 403) {
    return null;
  } else {
    storeToken(result);
    return result.json();
  }
}

function storeToken(response: Response) {
   setToken(authorizationToken(response));
}

function removeToken() {
  setToken("");
}

function setToken(token: string) {
  localStorage.setItem(TOKEN_KEY, token.replace(" ", ""));
}

export function getToken(): string {
  return localStorage.getItem(TOKEN_KEY) || "";
}

function authorizationToken(response: Response): string {
  return response.headers.get('Authorization')?.substring(8) || "";
}

export function retrieveLatestQuestionSettings(userId: string): Promise<ResultOrStatus<LatestQuestionDefaults>> {
  return getResultOrStatus(`/latest-question-settings`);
}

export function retrieveGlossary(language: string): Promise<Glossary> {
  return get(`/glossary/${language}`)
  .then(glossary => JSON.parse(glossary));
}

type ParsedFile = {
  title: string;
  contents: string;
  imageId: string;
  imageType: string;
}

type ParsedFilePayload = {
  files: ParsedFile[];
}
  
async function readImage(file: File): Promise<[string, string]> {
  // return file.arrayBuffer().
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onload = event => {
      const result = event.target!.result as string;
      const commaIndex = result.indexOf(",") + 1;
      const prior = result.substring(0, commaIndex);
      const containsJPEG = prior.includes("jpg") || prior.includes("jpeg");
      const containsPNG = prior.includes("png");
      const imageType = containsJPEG ? "jpg" : (containsPNG ? "png" : "--");
      const image = result.substring(commaIndex);
      resolve([image, imageType])
    };
    reader.readAsDataURL(file);
  })
}

export async function uploadPictures(files: LabeledFile[]): Promise<ResultOrStatus<any>> {
  // let data = new FormData();
  // files.filter(file => file.file !== null).forEach(file => {
  //   console.log("adding file")
  //   data.append(file.id, file.file!);
  // });
  console.log("files:", files);
  const filtered = files.filter(file => file.file !== null)
  const parsed: ParsedFilePayload = {
    files: await Promise.all(filtered.map(async file => {
      const [text, imageType] = await readImage(file.file!);
      return {
        title: file.label,
        contents: text,
        imageId: file.id,
        imageType
      }
    }))
  };

  console.log("parsed:", parsed);

  // return fetch(`${api}/upload-pictures/`, {
  //   method: "POST",
  //   body: data
  // })
  // IMAGES DONT WORK RIGHT NOW NEED TO COME BACK TO IT
  // return Promise.resolve({});
  // console.log("about to upload:", data.entries().toArray().length);
  return postRaw(`/upload-pictures`, parsed, APPLICATION_JSON);
}

export function persistNewQuestion(model: QuestionSubmission): Promise<ResultOrStatus<NewQuestionIdEnvelope>> {
  return postResultOrStatus(`/new-question`, model);
}

export function retrieveQuestionContext(questionId: string): Promise<QuestionContext> {
  return get(`/question-context/${questionId}`);
}

export function imageUrl(imageId: string, imageType: ImageType): string {
  return `${api}/retrieve-picture/${imageId}/${imageType}`;
}

export function retrieveImage(imageId: string, imageType: ImageType): Promise<Blob> {
  return getRaw(`/retrieve-picture/${imageId}/${imageType}`)
    .then(response => response.blob())
    .catch(error => {
      console.log("iamge error:", error);
      return new Blob();
    });
}

export function deleteQuestion(questionId: string): Promise<any> {
  return post(`/delete-question/${questionId}`, {});
}

export function updateQuestion(question: QuestionEditSubmission): Promise<any> {
  return postRaw(`/update-question`, question);
}

export function retrieveLatestQuizSettings(): Promise<NewQuizContext> {
  return get(`/latest-quiz-settings`);
}

export function newQuizRequest(payload: QuizSettings): Promise<QuizIdentifier> {
  return post(`/new-quiz`, payload);
}

export function retrieveQuiz(quizId: string): Promise<Quiz> {
  return get(`/quiz/${quizId}`);
}

export function cancelQuiz(quizId: string) {
  return post(`/cancel-quiz/${quizId}`, {});
}

export function activeQuiz(): Promise<ActiveQuizPayload> {
  return get(`/active-quiz`);
}

export function submitQuiz(quizId: string, submission: QuizSubmission): Promise<any> {
  return postRaw(`/submit-quiz/${quizId}`, submission);
}

export function retrieveQuizResults(quizId: string): Promise<ResultOrStatus<QuizResultEnvelope>> {
  return getResultOrStatus(`/quiz-results/${quizId}`);
}

export function retakeQuiz(quizId: string, retakeVersion: RetakeVersion): Promise<ResultOrStatus<QuizIdentifier>> {
  return postResultOrStatus(`/retake-quiz/${quizId}/${retakeVersion}`, {});
}

export function getHomePageContext(): Promise<ResultOrStatus<HomePageContext>> {
  return getResultOrStatus(`/home-page-context`);
}

export function loadQuestions(parameters: QuestionSearchPayload): Promise<ResultOrStatus<QuestionPartialLoad>> {
  return postResultOrStatus(`/questions-by-criteria`, parameters);
}

export function loadAllQuestionVersions(questionId: string): Promise<ResultOrStatus<QuestionVersionHistory>> {
  return getResultOrStatus(`/question-all-versions/${questionId}`);
}

export async function saveQuizProgress(progress: QuizProgressEnvelope): Promise<ResultOrStatus<any>> {
  return post("/save-quiz-progress", progress)
    .catch(error => console.error(error));
}

export function quizSettings(settings: QuizSettings): Promise<ResultOrStatus<QuizFeasibility>> {
  return post("/quiz-settings", settings);
}