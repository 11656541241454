import React, { ReactNode, useRef, useState, useEffect, RefObject, useCallback, memo } from 'react';
import QuizQuestionBox from "./QuizQuestionBox";
import * as E from "../TextEditor";
import * as Z from "../quiz";
import * as QS from "../QuestionSettings";
import { OptionalAnswerLabelDisplay, AnswerLabel } from "labels";
import styles from "./quizQuestionForms.module.scss";
import * as QP from "../QuizPage";
import * as Q from "../questionTypes";
import Toggle from "commonComponents/Toggle";
import { LabeledFileHandle, ImageHandleDisplay } from "../pictures";
import { useGlossary as g } from "../language";
import * as B from "questionBodyDisplay";
import OrderedList, { ReorderingOperation, arrowAction } from "commonComponents/OrderedList";
import CheckBox from "commonComponents/CheckBox";
import * as F from "TextEditor/featureFilter"
import * as P from "quiz/prompt";
import * as D from "drag";
import { v4 } from "uuid";
import { ErrorBoundary } from "react-error-boundary";

export type QuestionLocation = 'modal' | 'page';

export type QuizQuestionFormProps = QP.ResponseStateProps & {
  question: Z.QuizQuestion;
  flagged: boolean;
  toggleFlagged: () => void;
  hintsRevealed: number;
  revealNextHint: () => void;
  highlight: boolean;
  location: QuestionLocation;
}

export const WriteInQuizQuestion = memo(function f(props: QuizQuestionFormProps) {
  const body = props.question.body as Z.MultipleInputPrompt;
  const questionIndex = props.question.index;
  const settings = props.question.settings as QS.WriteInModel;
  const labelType = settings.labelType;
  const inputCount: number = Z.writeInInputs(body, settings);
  const response = props.getResponse(questionIndex) as Z.WriteInResponse;
  return (
    <QuizQuestionBox
      question={props.question}
      flagged={props.flagged}
      toggleFlagged={props.toggleFlagged}
      questionSlot={<E.TextDisplay value={body.question}/>}
      hintsRevealed={props.hintsRevealed}
      revealNextHint={props.revealNextHint}
      highlight={props.highlight}
      location={props.location}
      answerSlot={
        <>
          {response.answers.map((originalAnswer, index) => {
            const answer = props.location === 'modal' ? {
              ...originalAnswer,
              content: E.documentNonConflictCopy(originalAnswer.content, 'modal')
            } : originalAnswer;
            return (
              <WriteInAnswer
                key={index}
                answer={answer.content}
                onChange={content => {
                  response.answers.splice(index, 1, {
                    content: props.location === 'modal' ? E.documentNonConflictRestore(content) : content,
                    position: index
                  });
                  props.updateResponse(questionIndex, response);
                }}
                index={index}
                labelType={labelType}
              />
            )
          })}
        </>
      }
    />
  );
});

type WriteInAnswerProps = {
  answer: E.Content;
  onChange: (_: E.Content) => void;
  index: number;
  labelType: AnswerLabel | null;
};

function WriteInAnswer(props: WriteInAnswerProps) {
  return (
    <div className={styles['write-in-answer-wrapper']}>
      <OptionalAnswerLabelDisplay position={props.index} labelType={props.labelType}/>
      <E.Editor
        value={props.answer}
        onChange={props.onChange}
        featureFilter={F.writeInAnswerFeatures}
        externalResponsive={true}
      />
    </div>
  );
}

export function MultipleChoiceQuizQuestion(props: QuizQuestionFormProps) {
  const body = props.question.body as Z.SelectionPrompt;
  const settings = props.question.settings as QS.MultipleChoiceModel;
  const labelType = settings.labelType;
  const questionId = props.question.questionId;
  const questionIndex = props.question.index;
  const selection = props.getResponse(questionIndex) as Z.MultipleChoiceResponse;
  return (
    <QuizQuestionBox
      question={props.question}
      flagged={props.flagged}
      toggleFlagged={props.toggleFlagged}
      questionSlot={<E.TextDisplay value={body.question}/>}
      revealNextHint={props.revealNextHint}
      hintsRevealed={props.hintsRevealed}
      highlight={props.highlight}
      location={props.location}
      answerSlot={
        <div>
          {body.answers.map((answer, index) => (
            <MultipleChoiceOption
              key={index}
              answer={answer}
              index={index}
              labelType={labelType}
              onSelect={() => {
                const mcValue: Z.MultipleChoiceResponse = {
                  selection: Q.stringRepresentation(answer)
                };
                props.updateResponse(questionIndex, mcValue);
              }}
              selected={selection.selection !== null && Q.stringRepresentation(answer) === selection.selection}
            />
          ))}
        </div>
      }
    />
  );
}

function clampLabel(labelType: AnswerLabel | null): AnswerLabel {
  return labelType === null ? 'uppercaseLabel' : labelType;
}

type OptionProps = {
  answer: Q.SelectableAnswer;
  index: number;
  labelType: AnswerLabel | null;
  onSelect: () => void;
  selected: boolean;
};

function MultipleChoiceOption(props: OptionProps) {
  return (
    <MultiOption selected={props.selected} onSelect={props.onSelect} images={props.answer.images}>
      <RadioButton selected={props.selected}/>
      <QuizFormLabel position={props.index} labelType={props.labelType}/>
      <E.TextDisplay value={props.answer.content} additionalClasses={styles['option-contents']} selectable={true}/>
    </MultiOption>
  );
}

type MultiOptionProps = {
  selected: boolean;
  onSelect: () => void;
  children: ReactNode;
  images: LabeledFileHandle[];
};

function MultiOption(props: MultiOptionProps) {
  const className = styles[props.selected ? 'option-selected' : 'option'];
  return (
    <div className={className} onClick={props.onSelect}>
      <div className={styles['option-answer-wrapper']}>
        {props.children}
      </div>
      <ImageHandleDisplay handles={props.images} below={true}/>
    </div>
  )
}

type RadioButtonProps = {
  selected: boolean;
};

function RadioButton(props: RadioButtonProps) {
  const outerClass = styles[props.selected ? 'radio-button-selected' : 'radio-button'];
  const innerClass = styles[props.selected ? 'radio-button-inner-selected' : 'radio-button-inner'];
  return (
    <span className={outerClass}>
      <span className={innerClass}></span>
    </span>
  );
}

export const MultiSelectQuizQuestion = memo(function f(props: QuizQuestionFormProps) {
  const body = props.question.body as Z.SelectionPrompt;
  const settings = props.question.settings as QS.MultiSelectModel;
  const labelType = settings.labelType;
  const questionIndex = props.question.index;
  const selections = props.getResponse(questionIndex) as Z.MultiSelectResponse;
  const selected = (answer: Q.SelectableAnswer) => {
    return selections.selections.has(Q.stringRepresentation(answer));
  }
  return (
    <QuizQuestionBox
      question={props.question}
      flagged={props.flagged}
      toggleFlagged={props.toggleFlagged}
      questionSlot={<E.TextDisplay value={body.question}/>}
      hintsRevealed={props.hintsRevealed}
      revealNextHint={props.revealNextHint}
      highlight={props.highlight}
      location={props.location}
      answerSlot={
        <>
          {body.answers.map((answer, index) => (
            <MultiSelectOption
              key={index}
              index={index}
              labelType={labelType}
              answer={answer}
              selected={selected(answer)}
              onSelect={() => {
                const raw = Q.stringRepresentation(answer);
                if (selections.selections.has(raw)) {
                  selections.selections.delete(raw);
                } else {
                  selections.selections.add(raw);
                }
                props.updateResponse(questionIndex, selections);
              }}
            />
          ))}
      </>}
    />
  );
})

type QuizFormLabelProps = {
  labelType: AnswerLabel | null;
  position: number;
};

function QuizFormLabel(props: QuizFormLabelProps) {
  return (
    <div className={styles['label-wrapper']}>
      <OptionalAnswerLabelDisplay labelType={props.labelType} position={props.position}/>
    </div>
  )
}

function MultiSelectOption(props: OptionProps) {
  return (
    <MultiOption selected={props.selected} onSelect={props.onSelect} images={props.answer.images}>
      <CheckBox selected={props.selected}/>
      <QuizFormLabel labelType={props.labelType} position={props.index}/>
      <E.TextDisplay value={props.answer.content} selectable={true}/>
    </MultiOption>
  );
}

export const FillInTheBlankQuizQuestion = memo(function f(props: QuizQuestionFormProps) {
  const questionIndex = props.question.index;
  const response = props.getResponse(questionIndex) as Z.FillInTheBlankResponse;
  const body = props.location === 'modal' ? E.documentNonConflictCopy(response.body, 'modal') : response.body;
  const distractors = (props.question.body as P.FillInTheBlankPrompt).distractors;
  const [dropEvent, setDropEvent] = useState<MouseEvent | undefined>(undefined);
  const [dropPayload, setDropPayload] = useState<E.Content | undefined>(undefined);
  return (
    <ErrorBoundary FallbackComponent={FillInTheBlankError}>
    <QuizQuestionBox
      question={props.question}
      flagged={props.flagged}
      toggleFlagged={props.toggleFlagged}
      questionSlot={null}
      revealNextHint={props.revealNextHint}
      hintsRevealed={props.hintsRevealed}
      highlight={props.highlight}
      location={props.location}
      answerSlot={
        <div>
          <E.Editor
            featureFilter={F.fillInTheBlankQuizFeatures}
            value={body}
            onChange={content => {
              return props.updateResponse(props.question.index, {
                ...response,
                body: E.documentNonConflictRestore(content)
              });
            }}
            editable={'blanks-only'}
            dropEvent={dropEvent}
            dropPayload={dropPayload}
            externalResponsive={true}
          />
          {distractors && distractors.length > 1 ? (
            <FillInTheBlankDragOptions
              options={distractors}
              setDropEvent={setDropEvent}
              setDropPayload={setDropPayload}
            />
          ) : null}
        </div>
      }
    />
    </ErrorBoundary>
  );
});

function FillInTheBlankError() {
  return <div>...</div>;
}

type FillInTheBlankDragOptionsProps = {
  options: E.Content[];
  setDropEvent: (_: MouseEvent) => void;
  setDropPayload: (_: E.Content) => void;
}

function FillInTheBlankDragOptions(props: FillInTheBlankDragOptionsProps) {
  const [dragIndex, setDragIndex] = useState<number | null>(null);
  const [draggedYCenter, setDraggedYCenter] = useState<number | null>(null);
  const [blankAreaHeight, setBlankAreaHeight] = useState<number | null>(null);
  const [keys, setKeys] = useState<string[]>(props.options.map(() => v4()));
  const yieldDragged = (event: MouseEvent, wrapperRef: RefObject<HTMLDivElement>) => {
    if (dragIndex !== null) {
      props.setDropEvent(event);
      props.setDropPayload(props.options[dragIndex]);
    }
    setDragIndex(null);
    setDraggedYCenter(null);
  }
  return (
    <div className={styles['distractor-list']}>
      <label>{g('distractorLabel')}</label>
      {props.options.map((distractor, index) => {
        return (<FillInTheBlankDragOption
          key={keys[index]}
          option={distractor}
          dragging={index === dragIndex}
          setDragged={() => {
            setDragIndex(index)
          }}
          yieldDragged={yieldDragged}
          draggedYCenter={draggedYCenter}
          setDraggedYCenter={setDraggedYCenter}
          setBlankAreaHeight={setBlankAreaHeight}
          extraClasses={""}
          dragClass=""
          wrapperClass=""
        />
        )
      }
)}
    </div>
  );
}

type FillInTheBlankDragOptionProps = {
  option: E.Content; 
} & D.ExternalDraggableItemProps

function FillInTheBlankDragOption(props: FillInTheBlankDragOptionProps) {
  const dragClass = styles[props.dragging ? 'dragging' : 'not-dragging'];
  const wrapperClass = styles[props.dragging ? 'dragging-distractor-item' : 'distractor-item'];
  let placeholder = null;
  if (props.dragging) {
    if (E.includesMath(props.option)) {
      placeholder = <div className={styles['math-placeholder']}/>;
    } else {
      placeholder = (
        <div className={styles['distractor-item']}>
          <E.TextDisplay
            value={props.option}
            draggableReplacement={true}
          />
        </div>
      );
    }
  }
  return (
    <>
      <D.DraggableItem
        dragging={props.dragging}
        setDragged={props.setDragged}
        yieldDragged={props.yieldDragged}
        setBlankAreaHeight={props.setBlankAreaHeight}
        draggedYCenter={props.draggedYCenter}
        setDraggedYCenter={props.setDraggedYCenter}
        extraClasses={props.extraClasses}
        dragClass={dragClass}
        wrapperClass={wrapperClass}
        place={() => {}}
        element={(className, wrapperRef, style, dragRef, dragClass) => (
          <div className={className} ref={wrapperRef} style={style}>
            <div ref={dragRef} className={dragClass}>
              <E.TextDisplay
                value={props.option}
                draggable={true}
                dragging={props.dragging}
              />
            </div>
          </div>
        )}
      />
      {placeholder}
    </>
  );
}

type BlankElementProps = B.ElementProps & {
  onChange: (_: E.Content) => void;
};

function BlankElementForm(props: BlankElementProps) {
  return (
    <input
      type="text"
      value={E.stringRepresentation(props.content)}
      className={styles['blank-element']}
      onChange={event => props.onChange(E.fromString(event.target.value))}
    />
  );
}

export const TrueFalseQuizQuestion = memo(function f(props: QuizQuestionFormProps) {
  const body = props.question.body as Z.InputPrompt;
  const questionIndex = props.question.index;
  const response = props.getResponse(questionIndex) as Z.TrueFalseResponse;
  const falseSelected = response.option === false;
  const trueSelected = response.option === true;
  const onClick = (b: boolean) => props.updateResponse(questionIndex, { option: b })
  return (
    <QuizQuestionBox
      question={props.question}
      flagged={props.flagged}
      toggleFlagged={props.toggleFlagged}
      hintsRevealed={props.hintsRevealed}
      revealNextHint={props.revealNextHint}
      highlight={props.highlight}
      location={props.location}
      questionSlot={<E.TextDisplay value={body.question}/>}
      answerSlot={
        <B.TrueFalseRow
          onClick={onClick}
          variant={'indefinite'}
          falseSelected={falseSelected}
          trueSelected={trueSelected}
        />
      }
    />
  );
});

export const OrderingQuizQuestion = memo(function f(props: QuizQuestionFormProps) {
  const body = props.question.body as Z.OrderingPrompt;
  const settings = props.question.settings as QS.OrderingModel;
  const labelType = settings.labelType;
  const questionIndex = props.question.index;
  const response = props.getResponse(questionIndex) as Z.OrderingResponse;
  const responseRef = useRef<Z.OrderingResponse>(response);
  responseRef.current = response;
  const onShift = (index: number, operation: ReorderingOperation) => {
    arrowAction(index, operation, responseRef.current.order, newOrder => {
      const positioned = Q.reposition(newOrder);
      props.updateResponse(questionIndex, {
        order: positioned
      });
    });
  };
  return (
    <QuizQuestionBox
      question={props.question}
      flagged={props.flagged}
      toggleFlagged={props.toggleFlagged}
      hintsRevealed={props.hintsRevealed}
      revealNextHint={props.revealNextHint}
      highlight={props.highlight}
      location={props.location}
      questionSlot={<E.TextDisplay value={body.question}/>}
      answerSlot={
        <OrderedList
          operations={{moveUp: true, moveDown: true}}
          edgeAware={true}
          variant={'responsive'}
          onShift={onShift}
          entries={responseRef.current.order.map((answer, index) => (
            <OrderingAnswer labelType={labelType} position={index} content={answer.content}/>
          ))}
          extraEntryClasses={styles['ordering-answer-wrapper']}
          extraDropZoneClasses={styles['ordering-answer-drop-zone']}
        />
      }
    />
  );
});

type OrderingAnswerProps = {
  labelType: AnswerLabel | null;
  position: number;
  content: E.Content;
};

function OrderingAnswer(props: OrderingAnswerProps) {
  return (
    <div className={styles['quiz-ordering-answer']}>
      <QuizFormLabel labelType={props.labelType} position={props.position}/>
      <E.TextDisplay value={props.content}/>
    </div>
  );
}
