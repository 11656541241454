import React, { useState, useRef } from 'react';
import { BsChevronDown, BsChevronRight } from "react-icons/bs";
import styles from "./QuestionSettings.module.scss";
import Toggle, { SymmetricToggle } from "commonComponents/Toggle";
import { QuestionType, functionalityByType } from "../questionTypes";
import Info from "../Info";
import { OverallInfo } from "./SettingsInfo";
import {
  applicableSettingsFields,
  maximalSettingsModelFromSearch,
  applicableSettingsFieldsMultiple,
  mergeMaximalSettingsToSearch,
  validErrorMargin
} from "./settingsOperations";
import { g, GlossaryKey } from "../language";
import { AnswerLabel, answerLabelOptions, ANSWER_LABELS } from "labels";
import useClosable from "../closable";
import { MaximalQuestionSettingsModel, Setting, SettingsSearchModel, SETTINGS_FORM_ORDER } from "./questionSettingsModel";
import { setToggle, typedKeys, defaultBoolean } from "logicUtils";
import CheckBox from "commonComponents/CheckBox";
import { zip, setToList } from "utils";
import globalStyles from "styles/reference.module.scss";
import AllOrSomeSelect from "commonComponents/AllOrSomeSelect";
import PercentInput from "commonComponents/PercentInput";
import NumberPredicateSelector, { NumberPredicate } from "commonComponents/NumberPredicateSelector";
import disabledStyles from "styles/disabled.module.scss";

type QuestionSettingsProps = {
  questionType: QuestionType;
  settings: MaximalQuestionSettingsModel;
  setSettings: (_: MaximalQuestionSettingsModel) => void;
  answerCount: number | null;
}

export default function QuestionSettings(props: QuestionSettingsProps) {
  const [visible, setVisible] = useState(true);
  return (
    <div className={styles['settings-wrapper']}>
      <div className={styles['toggle-and-info']}>
        <span className={styles['visible-toggle']} onClick={() => setVisible(!visible)}>
          <span>{g('questionSettingsHeader')}</span>
          {visible ? <BsChevronDown/> : <BsChevronRight/>}
        </span>
        <Info title={g('questionSettingsHeader')}>
          <OverallInfo questionType={props.questionType}/>
        </Info>
      </div>
      {visible ? <QuestionSettingsEditor {...props}/> : null}
    </div>
  );
}

type QuestionSettingsEditorProps = QuestionSettingsProps;

function QuestionSettingsEditor(props: QuestionSettingsEditorProps) {
  const applicableSettings = applicableSettingsFields(props.questionType);
  const applicable = (s: Setting): boolean => {
    return applicableSettings.has(s);
  };
  const standardRowProps: SettingRowProps = {
    applicable,
    settings: props.settings,
    setSettings: props.setSettings
  };
  return (
    <div className={styles['settings-editor']}>
      <div className={styles['settings-toggles']}>
        <IgnoreWhitespaceRow {...standardRowProps}/>
        <IgnoreCapitalizationRow {...standardRowProps}/>
        <IgnorePunctuationRow {...standardRowProps}/>
        <LabelTypeRow {...standardRowProps}/>
        <OrderedRow {...standardRowProps}/>
        <ErrorMarginRow {...standardRowProps}/>
        <RequiredCountRow
          questionType={props.questionType}
          answerCount={props.answerCount}
          settings={props.settings}
          setSettings={props.setSettings}
          applicable={applicable}
        />
        <RandomizeRow {...standardRowProps}/>
        <ShowAfterRow {...standardRowProps}/>
        <ImageAfterRow {...standardRowProps}/>
      </div>
    </div>
  );
}

type SettingsLabelProps = {
  labelKey: GlossaryKey;
  disabled?: boolean;
};

function SettingsLabel(props: SettingsLabelProps) {
  const disabled = defaultBoolean(props.disabled, false);
  const className = `${styles['settings-label']} ${disabled ? disabledStyles['disabled-text'] : ''}`
  return (
    <span className={className}>{g(props.labelKey)}</span>
  );
}

type SettingRowProps = {
  settings: MaximalQuestionSettingsModel;
  setSettings: (_: MaximalQuestionSettingsModel) => void;
  applicable: (_: Setting) => boolean;
  disabled?: boolean;
};

function IgnoreWhitespaceRow(props: SettingRowProps) {
  const setIgnoreWhitespace = (ignoreWhitespace: boolean) => {
    props.setSettings({
      ...props.settings,
      ignoreWhitespace
    });
  };
  return props.applicable('ignoreWhitespace') ? (
    <SettingsToggle
      labelKey={'ignoreWhitespaceHeader'}
      value={props.settings.ignoreWhitespace}
      setValue={setIgnoreWhitespace}
      disabled={props.disabled}
    />
  ) : null;
}

function IgnoreCapitalizationRow(props: SettingRowProps) {
  const setIgnoreCapitalization = (ignoreCapitalization: boolean) => {
    props.setSettings({
      ...props.settings,
      ignoreCapitalization
    });
  };
  return props.applicable('ignoreCapitalization') ? (
    <SettingsToggle
      labelKey={'ignoreCapitalizationHeader'}
      value={props.settings.ignoreCapitalization}
      setValue={setIgnoreCapitalization}
      disabled={props.disabled}
    />
  ) : null;
}

function IgnorePunctuationRow(props: SettingRowProps) {
  const setIgnorePunctuation = (ignorePunctuation: boolean) => {
    props.setSettings({
      ...props.settings,
      ignorePunctuation
    });
  };
  return props.applicable('ignorePunctuation') ? (
    <SettingsToggle
      labelKey={'ignorePunctuationHeader'}
      value={props.settings.ignorePunctuation}
      setValue={setIgnorePunctuation}
      disabled={props.disabled}
    />
  ) : null;
}

type SettingsToggleProps = {
  labelKey: GlossaryKey;
  value: boolean;
  setValue: (value: boolean) => void;
  disabled?: boolean;
}

function SettingsToggle(props: SettingsToggleProps) {
  return (
    <>
      <SettingsLabel labelKey={props.labelKey} disabled={props.disabled}/>
      <Toggle on={props.value} onClick={() => props.setValue(!props.value)} disabled={props.disabled}/>
    </>
  )
}

function LabelTypeRow(props: SettingRowProps) {
  const setLabelType = (labelType: AnswerLabel) => {
    props.setSettings({
      ...props.settings,
      labelType
    })
  }
  const value = props.settings.labelType;
  const disabled = value === null;
  const display = (labelType: AnswerLabel) => {
    return g(labelType);
  };
  const className = styles['label-options'];
  const selected = value === null ? 'noLabel' : value;
  const [open, setOpen] = useState(false);
  return props.applicable('labelType') ? (
    <>
      <span className={styles['settings-label']}>{g('labelTypeHeader')}</span>
      <div className={className}>
        <OptionSelector
          selected={selected}
          options={answerLabelOptions}
          onSelect={option => setLabelType(option)}
          display={display}
          open={open}
          setOpen={setOpen}
        />
      </div>
    </>
  ) : null;
}

type LabelOptionProps = {
  labelType: AnswerLabel;
  selected: boolean;
  onClick: () => void;
};

function LabelOption(props: LabelOptionProps) {
  const className = styles[props.selected ? 'selected-label-option' : 'label-option'];
  return (
    <span className={className} onClick={props.onClick}>
      {g(props.labelType)}
    </span>
  )
}

type OptionSelectorProps<T> = {
  selected: T;
  options: readonly T[];
  onSelect: (_: T) => void;
  display: (_: T) => string;
  open: boolean;
  setOpen: (_: boolean) => void;
};

function OptionSelector<T>(props: OptionSelectorProps<T>) {
  const ref = useRef(null);
  useClosable(ref, () => props.setOpen(false));
  return (
    <div className={styles['dropdown-wrapper']}>
      <div className={styles['dropdown-label']} onClick={() => props.setOpen(!props.open)}>
        {props.display(props.selected)}
      </div>
      {props.open && (
        <div className={styles['dropdown']} ref={ref}>
          {props.options.map((option, index) => (
            <div
              className={styles[option === props.selected ? "selected-option" : "option"]}
              onClick={() => props.onSelect(option)}
              key={index}
            >{props.display(option)}</div>
          ))}
        </div>
      )}
    </div>
  )
}

function OrderedRow(props: SettingRowProps) {
  const setOrdered = (ordered: boolean) => {
    props.setSettings({
      ...props.settings,
      ordered
    });
  };
  return props.applicable('ordered') ? (
    <SettingsToggle
      labelKey={'orderedHeader'}
      value={props.settings.ordered}
      setValue={setOrdered}
      disabled={props.disabled}
    />
  ) : null;
}

type RequiredCountRowProps = SettingRowProps & {
  questionType: QuestionType;
  answerCount: number | null;
}

function RequiredCountRow(props: RequiredCountRowProps) {
  const setRequiredCount = (requiredCount: number | null) => {
    props.setSettings({
      ...props.settings,
      requiredCount
    });
  };
  const requiredCount = props.settings.requiredCount;
  const allSelected = requiredCount === null;
  const min = functionalityByType(props.questionType).minRequiredCount();
  const allAutomatic = props.answerCount !== null && props.answerCount <= min;
  const allClass = styles[allSelected ? 'selected-all-required' : 'all-required'];
  return props.applicable('requiredCount') && props.answerCount !== null ? (
    <>
      <span className={styles['settings-label']}>{g('requiredCountHeader')}</span>
      <span>
        <input
          type="number"
          min={`${min}`}
          max={`${props.answerCount - 1}`}
          step="1"
          disabled={allAutomatic}
          className={styles['settings-input']}
          value={requiredCount || ""}
          onChange={event => {
            const raw = event.target.value;
            if (raw.match(/^[0-9]+$/)) {
              const n = parseInt(raw, 10);
              if (n >= min && n < props.answerCount!) {
                setRequiredCount(n);
              }
            } else if (raw === "") {
              setRequiredCount(null);
            }
          }}
        />
        <span className={allClass} onClick={() => setRequiredCount(null)}>
          {g('allRequired')}
        </span>
      </span>
    </>
  ) : null;
}

function ErrorMarginRow(props: SettingRowProps) {
  const setErrorMargin = (errorMargin: number) => {
    props.setSettings({
      ...props.settings,
      errorMargin
    });
  };
  const errorMargin = props.settings.errorMargin;

  return props.applicable('errorMargin') ? (
    <>
      <span className={styles['settings-label']}>{g('errorMarginHeader')}</span>
      <PercentInput
        percent={errorMargin}
        onChange={value => {
          if (validErrorMargin(value)) {
            setErrorMargin(value);
          }
        }}
      />
    </>
  ) : null;
}

function RandomizeRow(props: SettingRowProps) {
  const setRandomize = (randomize: boolean) => {
    props.setSettings({
      ...props.settings,
      randomize
    });
  };
  return props.applicable('randomize') ? (
    <SettingsToggle
      labelKey={'randomizeHeader'}
      value={props.settings.randomize}
      setValue={setRandomize}
      disabled={props.disabled}
    />
  ) : null;
}

function ShowAfterRow(props: SettingRowProps) {
  const setShowAnswers = (showAnswers: boolean) => {
    props.setSettings({
      ...props.settings,
      showAnswers
    });
  }
  return props.applicable('showAnswers') ? (
    <SettingsToggle
      labelKey={'showAnswersHeader'}
      value={props.settings.showAnswers}
      setValue={setShowAnswers}
      disabled={props.disabled}
    />
  ) : null;
}

function ImageAfterRow(props: SettingRowProps) {
  const setImageAfter = (imageAfter: boolean) => {
    props.setSettings({
      ...props.settings,
      imageAfter
    });
  };

  return props.applicable('imageAfter') ? (
    <>
      <SettingsLabel labelKey={'pictureLocationHeader'} disabled={props.disabled}/>
      <SymmetricToggle
        leftKey={'imageTop'}
        rightKey={'imageBottom'}
        onClick={() => setImageAfter(!props.settings.imageAfter)}
        leftClick={() => setImageAfter(false)}
        rightClick={() => setImageAfter(true)}
        disabled={props.disabled}
        on={props.settings.imageAfter}
      />
    </>
  ) : null;
}

type QuestionSettingsSearchProps = {
  search: SettingsSearchModel;
  setSearch: (_: SettingsSearchModel) => void;
  includedSettings: Set<Setting>;
  setIncludedSettings: (_: Set<Setting>) => void;
  questionTypes: Set<QuestionType>;
};

export function QuestionSettingsSearch(props: QuestionSettingsSearchProps) {
  const [maximalSettings, setMaximalSettings] = useState<MaximalQuestionSettingsModel>(maximalSettingsModelFromSearch(props.search));
  const applicable = (_: Setting) => true;
  const active = applicableSettingsFieldsMultiple(setToList(props.questionTypes));
  const setSettings = (settings: MaximalQuestionSettingsModel) => {
    setMaximalSettings(settings);
    props.setSearch(mergeMaximalSettingsToSearch(settings, props.search));
  };
  const disabledSetting = (s: Setting) => !active.has(s) || !props.includedSettings.has(s);
  const standardRowProps = (s: Setting) => {
    return {
      applicable,
      settings: maximalSettings,
      setSettings: setSettings,
      disabled: disabledSetting(s)
    };
  };
  const searchRowProps = (s: Setting) => {
    return {
      applicable,
      search: props.search,
      setSearch: props.setSearch,
      disabled: disabledSetting(s)
    };
  };
  const toggleSettingInclusion = (s: Setting) => {
    props.setIncludedSettings(new Set(setToggle(s, props.includedSettings)));
  }
  const inclusionToggles = SETTINGS_FORM_ORDER.map(setting => (
    <CheckBox
      selected={props.includedSettings.has(setting)}
      onClick={() => toggleSettingInclusion(setting)}
      disabled={!active.has(setting)}
      additionalClasses={styles['settings-checkbox']}
      enhancedVisibility={true}
    />
  ));
  const settingRows = [
    <IgnoreWhitespaceRow {...standardRowProps('ignoreWhitespace')}/>,
    <IgnoreCapitalizationRow {...standardRowProps('ignoreCapitalization')}/>,
    <IgnorePunctuationRow {...standardRowProps('ignorePunctuation')}/>,
    <LabelTypeSearchRow {...searchRowProps('labelType')}/>,
    <OrderedRow {...standardRowProps('ordered')}/>,
    <ErrorMarginSearchRow {...searchRowProps('errorMargin')}/>,
    <RequiredCountSearchRow {...searchRowProps('requiredCount')}/>,
    <RandomizeRow {...standardRowProps('randomize')}/>,
    <ImageAfterRow {...standardRowProps('imageAfter')}/>
  ];
  const gridRows = zip(inclusionToggles, settingRows);
  return (
    <div>
      <div className={styles['settings-search']}>
        <label>{g('includeSettingHeader')}</label>
        <label>{g('settingNameHeader')}</label>
        <label>{g('settingValueHeader')}</label>
      </div>
      <div className={styles['settings-search']}>
        {gridRows.map(([toggle, setter]) => <>{toggle}{setter}</>)}
      </div>
      <div className={styles['disclaimer']}>{g('settingsSearchDisclaimer')}</div>
    </div>
  );
}

type IncludeSettingProps = {
  selected: boolean;
  onClick: () => void;
  additionalClasses?: string;

};

function IncludeSetting(props: IncludeSettingProps) {
  return (
    <div onClick={props.onClick}>
      <CheckBox selected={props.selected}/>
    </div>
  );
}

type SearchRowProps = {
  search: SettingsSearchModel;
  setSearch: (_: SettingsSearchModel) => void;
  applicable: (_: Setting) => void;
  disabled?: boolean;
};

function LabelTypeSearchRow(props: SearchRowProps) {
  const initialAll = props.search.labelType === 'all';
  const labelTypes: readonly AnswerLabel[] = props.search.labelType === 'all' ? ANSWER_LABELS : props.search.labelType;
  const selected: Set<AnswerLabel> = new Set(labelTypes);
  const setSelected = (newSelected: Set<AnswerLabel>) => {
    const labels = newSelected.size === ANSWER_LABELS.length ? 'all' : setToList(newSelected);
    props.setSearch({
      ...props.search,
      labelType: labels
    });
  };
  const localGlossary = (l: AnswerLabel) => l;
  return (
    <>
      <SettingsLabel labelKey={'labelTypeHeader'} disabled={props.disabled}/>
      <AllOrSomeSelect<AnswerLabel>
        options={ANSWER_LABELS}
        initialAll={initialAll}
        selectedOptions={selected}
        localGlossary={localGlossary}
        setSelectedOptions={setSelected}
        disabled={props.disabled}
      />
    </>
  );
}

function ErrorMarginSearchRow(props: SearchRowProps) {
  const setErrorMargin = (errorMargin: NumberPredicate) => {
    if (validErrorMargin(errorMargin.base)) {
      props.setSearch({
        ...props.search,
        errorMargin
      });
    }
  }
  return (
    <>
      <SettingsLabel labelKey={'errorMarginHeader'} disabled={props.disabled}/>
      <NumberPredicateSelector
        disabled={props.disabled}
        exact={false}
        value={props.search.errorMargin}
        setValue={setErrorMargin}
      />
    </>
  );
}

function RequiredCountSearchRow(props: SearchRowProps) {
  const setAll = (value: boolean) => {
    if (!props.disabled) {
      props.setSearch({
        ...props.search,
        requiredCount: value
      });
    }
  }
  const toggleAll = () => {
    setAll(!props.search.requiredCount);
  };
  return (
    <>
      <SettingsLabel labelKey={'requiredCountHeader'} disabled={props.disabled}/>
      <SymmetricToggle
        leftKey={'answersRequiredPartial'}
        rightKey={'answersRequiredAll'}
        leftClick={() => setAll(false)}
        rightClick={() => setAll(true)}
        on={props.search.requiredCount}
        onClick={toggleAll}
        disabled={props.disabled}
      />
    </>
  )
}
