import React, { useState } from 'react';
import { Editor } from "TextEditor";
import styles from "./HomePage.module.scss";
import { RawState, initialRawState } from "TextEditor/editorState";

export default function HomePage() {
  const [raw, setRaw] = useState<RawState>(initialRawState());
  return (
    <div className={styles['home-page']}>
      <div>Home Sweet Gnome</div>
      <input type="text"/>
      <Editor featureFilter={true} onChange={state => setRaw(state)} editable={'editable'}/>
      {/*<TextEditor initial={raw} featureFilter={true} onChange={() => {}} editable={false}/>*/}
    </div>
  )
}
