import React, { ReactNode, useEffect, useRef, useState, RefObject, useCallback } from 'react';
import styles from "./OrderedList.module.scss";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import { GrDrag } from "react-icons/gr";
import isMobile from "responsive";
import { v4 } from "uuid";
import * as D from "drag";

export type OrderedListActionVariant = 'drag' | 'click';

export type OrderedListVariant = OrderedListActionVariant | 'responsive';

export type ReorderingOperations = {
  moveUp?: boolean;
  moveDown?: boolean;
  swap?: boolean;
};

export type SwapOperation = {
  destination: number;
};

export function isSwapOperation(operation: ReorderingOperation): operation is SwapOperation {
  return (operation as SwapOperation).destination !== undefined;
}

export type ReorderingOperation = 'moveUp' | 'moveDown' | SwapOperation;

type Dividers = {
  present: boolean;
  onClick: () => void;
};

type OrderedListAlternateProps = {
  operations: ReorderingOperations;
  entries: ReactNode[];
  onShift: (_: number, __: ReorderingOperation) => void;
  edgeAware: boolean;
  extraEntryClasses?: string;
  extraDropZoneClasses?: string;
};

type OrderedListProps = OrderedListAlternateProps & {
  variant: OrderedListVariant;
};

export default function OrderedList(props: OrderedListProps) {
  const variant = resolveVariant(props.variant);
  switch (variant) {
    case 'drag': return <OrderedListDrag {...props}/>
    case 'click': return <OrderedListClick {...props}/>
  }
}

function OrderedListClick(props: OrderedListAlternateProps) {
  return (
    <div>
      {props.entries.map((entry, index) => (
        <ClickEntry
          index={index}
          totalEntries={props.entries.length}
          onShift={props.onShift}
          operations={props.operations}
          edgeAware={props.edgeAware}
          key={index}
          entry={entry}
          extraClasses={props.extraEntryClasses}
        />
      ))}
    </div>
  )
}

type EmptyEntryAdjacency = 'above' | 'below';

function dropIndex(subjacent: number, adjacency: EmptyEntryAdjacency): number {
  switch (adjacency) {
    case 'above': return subjacent;
    case 'below': return subjacent + 1;
  }
}

type ClickEntryProps = {
  index: number;
  totalEntries: number;
  onShift: (_: number, __: ReorderingOperation) => void;
  operations: ReorderingOperations;
  edgeAware: boolean;
  entry: ReactNode;
  extraClasses?: string;
}

function ClickEntry(props: ClickEntryProps) {
  const upPossible = !props.edgeAware || props.index > 0;
  const downPossible = !props.edgeAware || props.index < props.totalEntries - 1;
  const up = props.operations.moveUp ? () => props.onShift(props.index, 'moveUp') : () => {};
  const down = props.operations.moveDown ? () => props.onShift(props.index, 'moveDown') : () => {};
  const className = `${props.extraClasses || ""} ${styles['ordered-list-row']}`;
  return (
    <div className={className}>
      <div className={styles['click-shifter']}>
        {props.operations.moveUp && upPossible && (
          <span className={styles['arrow-button']} onClick={up}>
            <MdKeyboardArrowUp/>
          </span>
        )}
        {props.operations.moveDown && downPossible && (
          <span className={styles['arrow-button']} onClick={down}>
            <MdKeyboardArrowDown/>
          </span>
        )}
      </div>
      {props.entry}
    </div>
  );
}



function OrderedListDrag(props: OrderedListAlternateProps) {
  const [dragIndex, setDragIndex] = useState<number | null>(null);
  const [subjacentIndex, setSubjacentIndex] = useState<number | null>(null);
  const [blankAreaHeight, setBlankAreaHeight] = useState<number | null>(null);
  const [draggedYCenter, setDraggedYCenter] = useState<number | null>(null);
  const [emptyEntryAdjacency, setEmptyEntryAdjacency] = useState<EmptyEntryAdjacency | null>(null);
  const [keys, setKeys] = useState<string[]>(props.entries.map((_) => v4()));
  const subjacentRef = useRef<number | null>(null);
  const dragRef = useRef<number | null>(null);
  const adjacencyRef = useRef<EmptyEntryAdjacency | null>(null);
  const emptyEntry = blankAreaHeight !== null ? <EmptyListRegion key="empty" height={blankAreaHeight} extraClasses={props.extraDropZoneClasses}/> : null;
  useEffect(() => {
    subjacentRef.current = subjacentIndex;
    dragRef.current = dragIndex;
    adjacencyRef.current = emptyEntryAdjacency;
  }, [subjacentIndex, dragIndex, emptyEntryAdjacency]);
  function clearState() {
    setDragIndex(null);
    setSubjacentIndex(null);
    setDraggedYCenter(null);
    setBlankAreaHeight(null);
    setEmptyEntryAdjacency(null);
  }

  const yieldDragged = () => {
    const subjacent = subjacentRef.current;
    const drag = dragRef.current;
    const adjacency = adjacencyRef.current;
    if (drag !== null && subjacent !== null && adjacency !== null) {
      props.onShift(drag, {
        destination: dropIndex(subjacent, adjacency)
      });
    }
    setKeys(props.entries.map((_) => v4()));
    clearState();
  }
  return (
    <div>
      {props.entries.map((entry, index) => (
        <>
          {subjacentIndex === index && emptyEntryAdjacency === 'above' ? emptyEntry : null}
          <DragEntry
            key={keys[index]}
            entry={entry}
            dragging={index === dragIndex}
            subjacent={index === subjacentIndex}
            setDragged={() => {
              if (index > 0) {
                setSubjacentIndex(index - 1)
                setEmptyEntryAdjacency('below');
              } else {
                setSubjacentIndex(index + 1);
                setEmptyEntryAdjacency('above');
              }
              setDragIndex(index);
            }}
            yieldDragged={yieldDragged}
            setSubjacent={() => setSubjacentIndex(index)}
            setBlankAreaHeight={setBlankAreaHeight}
            draggedYCenter={draggedYCenter}
            setDraggedYCenter={setDraggedYCenter}
            setEmptyEntryAdjacency={setEmptyEntryAdjacency}
            first={firstEntry(index, dragIndex)}
            last={lastEntry(index, dragIndex, props.entries.length)}
            extraClasses={props.extraEntryClasses}
          />
          {subjacentIndex === index && emptyEntryAdjacency === 'below' ? emptyEntry : null}
        </>
      ))}
    </div>
  )
}

function firstEntry(index: number, draggedIndex: number | null): boolean {
  return index === 0 || (draggedIndex === 0 && index === 1);
}

function lastEntry(index: number, draggedIndex: number | null, count: number): boolean {
  return index === count - 1 || (draggedIndex === count - 1 && index === count - 2);
}

type DragEntryProps = {
  entry: ReactNode;
  dragging: boolean;
  subjacent: boolean;
  setDragged: () => void;
  yieldDragged: (_: MouseEvent) => void;
  setSubjacent: () => void;
  setBlankAreaHeight: (_: number) => void;
  draggedYCenter: number | null;
  setDraggedYCenter: (_: number) => void;
  setEmptyEntryAdjacency: (_: EmptyEntryAdjacency | null) => void;
  first: boolean;
  last: boolean;
  extraClasses?: string;
};

function DragEntry(props: DragEntryProps) {
  const dragClass = styles[props.dragging ? 'dragging-indicator' : 'drag-indicator'];
  const wrapperClass = styles[props.dragging ? 'dragging-ordered-list-row' : 'ordered-list-row'];
  const place = (wrapperRef: RefObject<HTMLDivElement>) => {
    if (!props.dragging && props.draggedYCenter !== null) {
      const rect = wrapperRef!.current!.getBoundingClientRect();
      const belowTop = props.draggedYCenter >= rect.y;
      const aboveBottom = props.draggedYCenter <= rect.y + rect.height;
      const inRange = belowTop && aboveBottom || (belowTop && props.last) || (aboveBottom && props.first);
      if (inRange) {
        props.setSubjacent();
        const aboveCenter = props.draggedYCenter < rect.y + rect.height / 2;
        props.setEmptyEntryAdjacency(aboveCenter ? 'above' : 'below');
      }
    }
  };
  return (
    <D.DraggableItem
      dragging={props.dragging}
      setDragged={props.setDragged}
      yieldDragged={props.yieldDragged}
      setBlankAreaHeight={props.setBlankAreaHeight}
      draggedYCenter={props.draggedYCenter}
      setDraggedYCenter={props.setDraggedYCenter}
      place={place}
      extraClasses={props.extraClasses}
      dragClass={dragClass}
      wrapperClass={wrapperClass}
      element={(className, wrapperRef, style, dragRef, dragClass) => (
        <div className={className} ref={wrapperRef} style={style}>
          <div ref={dragRef}>
            <GrDrag className={dragClass}/>
          </div>
          {props.entry}
        </div>
      )}
    />
  );
}

type EmptyListRegionProps = {
  height: number;
  extraClasses?: string;
}

function EmptyListRegion(props: EmptyListRegionProps) {
  const style = {
    height: `${props.height}px`
  }
  const className = `${props.extraClasses || ""} ${styles['blank-list-entry']}`;
  return (
    <div className={className} style={style}/>
  );
}

function resolveVariant(variant: OrderedListVariant): OrderedListActionVariant {
  if (variant === 'responsive') {
    if (isMobile()) {
      return 'click';
    } else {
      return 'drag';
    }
  } else {
    return variant;
  }
}
