import * as Q from "./questionTypes";
import * as E from "../TextEditor";
import * as SA from "./selectableAnswer";
import { allMultipleChoiceAnswers, allMultiSelectAnswers } from "./questionOperations";
import { OrderingAnswer } from "./orderingAnswer";

function isSimpleQuestion(q: Q.QuestionBody): q is Q.TextualQuestion {
  return (q as Q.TextualQuestion).question !== undefined;
}

export function isTrueFalse(q: Q.QuestionBody): q is Q.TrueFalse {
  return (q as Q.TrueFalse).validity !== undefined;
}

export function isMultipleChoice(q: Q.QuestionBody): q is Q.MultipleChoice {
  return (q as Q.MultipleChoice).correctAnswer !== undefined;
}

export function isMultiSelect(q: Q.QuestionBody): q is Q.MultiSelect {
  return (q as Q.MultiSelect).correctAnswers !== undefined;
}

export function isWriteIn(q: Q.QuestionBody): q is Q.WriteIn {
  return (q as Q.WriteIn).answers !== undefined;
}

export function isFillInTheBlank(q: Q.QuestionBody): q is Q.FillInTheBlank {
  return (q as Q.FillInTheBlank).body !== undefined;
}

export function isOrdering(q: Q.QuestionBody): q is Q.Ordering {
  return (q as Q.Ordering).order !== undefined;
}

export function isTextualQuestion(q: Q.QuestionBody): q is Q.TextualQuestion {
  return (q as Q.TextualQuestion).question !== undefined;
}

function questionText(q: Q.QuestionBody): E.Content {
  if (isSimpleQuestion(q)) {
    return q.question;
  } else {
    return E.constructContent();
  }
}

export function toTrueFalse(q: Q.QuestionBody): Q.TrueFalse {
  if (isTrueFalse(q)) {
    return q;
  }
  return standardToTrueFalse(q);
}

export function standardToTrueFalse(q: Q.QuestionBody): Q.TrueFalse {
  return {
    question: questionText(q),
    validity: false
  }
}

export function toWriteIn(q: Q.QuestionBody): Q.WriteIn {
  if (isWriteIn(q)) {
    return q;
  }
  let answers = [E.constructContent()];
  if (isMultipleChoice(q)) {
    return multipleChoiceToWriteIn(q);
  }
  if (isTrueFalse(q)) {
    answers = [E.fromString(q.validity ? "true" : "false")];
  }
  if (isMultiSelect(q)) {
    return multiSelectToWriteIn(q);
  }
  if (isOrdering(q)) {
    answers = q.order.map(answer => answer.content);
  }
  return generalToWriteIn(q, answers);
}

export function standardToWriteIn(q: Q.QuestionBody): Q.WriteIn {
  return generalToWriteIn(q, [E.constructContent()]);
}

export function multipleChoiceToWriteIn(q: Q.MultipleChoice): Q.WriteIn {
  return generalToWriteIn(q, [q.correctAnswer.content].concat(q.otherAnswers.map(a => a.content)));
}

export function multiSelectToWriteIn(q: Q.MultiSelect): Q.WriteIn {
  return generalToWriteIn(q, q.correctAnswers.concat(q.otherAnswers).map(a => a.content));
}

export function generalToWriteIn(q: Q.QuestionBody, answers: E.Content[]): Q.WriteIn {
  return {
    question: questionText(q),
    answers: answers.map((answer, index) => {
      return {
        content: answer,
        position: index
      };
    })
  };
}

export function toMultipleChoice(q: Q.QuestionBody): Q.MultipleChoice {
  if (isMultipleChoice(q)) {
    return q;
  }
  let correctAnswer = SA.constructSelectableAnswer();
  let otherAnswers = [] as SA.SelectableAnswer[];
  if (isMultiSelect(q)) {
    return multiSelectToMultipleChoice(q);
  }
  if (isWriteIn(q)) {
    return writeInToMultipleChoice(q);
  }
  return generalToMultipleChoice(q, correctAnswer, otherAnswers);
}

export function standardToMultipleChoice(q: Q.QuestionBody): Q.MultipleChoice {
  return generalToMultipleChoice(q, SA.constructSelectableAnswer(), []);
}

export function multiSelectToMultipleChoice(q: Q.MultiSelect): Q.MultipleChoice {
  return generalToMultipleChoice(q, SA.constructSelectableAnswer(), q.correctAnswers.concat(q.otherAnswers));
}

export function writeInToMultipleChoice(q: Q.WriteIn): Q.MultipleChoice {
  const correctAnswer = q.answers.length > 0 ? SA.contentToSelectableAnswer(q.answers[0].content) : SA.constructSelectableAnswer();
  const otherAnswers = q.answers.filter((_, index) => index !== 0).map(c => {
    return SA.contentToSelectableAnswer(c.content);
  });
  return generalToMultipleChoice(q, correctAnswer, otherAnswers);
}

function generalToMultipleChoice(q: Q.QuestionBody, correctAnswer: SA.SelectableAnswer, otherAnswers: SA.SelectableAnswer[]): Q.MultipleChoice {
  return {
    question: questionText(q),
    correctAnswer,
    otherAnswers
  }
}

export function toMultiSelect(q: Q.QuestionBody): Q.MultiSelect {
  if (isMultiSelect(q)) {
    return q;
  }
  let correctAnswers = [] as SA.SelectableAnswer[];
  let otherAnswers = [] as SA.SelectableAnswer[];
  if (isMultipleChoice(q)) {
    correctAnswers = [q.correctAnswer];
    otherAnswers = q.otherAnswers;
  }
  if (isWriteIn(q) && q.answers.length > 0) {
    correctAnswers = q.answers.map(c => SA.contentToSelectableAnswer(c.content));
  }
  return {
    question: questionText(q),
    correctAnswers,
    otherAnswers
  }
}

export function toFillInTheBlank(q: Q.QuestionBody): Q.FillInTheBlank {
  if (isFillInTheBlank(q)) {
    return q;
  }
  let elements: Q.FillInTheBlankElement[] = [{
    text: E.constructContent()
  }];
  if (isWriteIn(q)) {
    const first: Q.FillInTheBlankElement[] = [{
      text: q.question
    }];
    const rest: Q.FillInTheBlankElement[] = q.answers.flatMap(answer => {
      const blank = {
        answer: answer.content
      };
      const textual = {
        text: E.fromString("")
      };
      return [blank, textual];
    });
    elements = first.concat(rest);
  }
  return {
    distractors: [],
    body: E.constructContent()
  };
}

function selectableToOrdering(answer: SA.SelectableAnswer, position: number): OrderingAnswer {
  return {
    content: answer.content,
    position: position,
    images: answer.images
  };
}

export function toOrdering(q: Q.QuestionBody): Q.Ordering {
  if (isOrdering(q)) {
    return q;
  }
  let answers: OrderingAnswer[] = [];
  if (isMultipleChoice(q)) {
    answers = allMultipleChoiceAnswers(q).map((answer, index) => selectableToOrdering(answer, index));
  }
  if (isMultiSelect(q)) {
    answers = allMultiSelectAnswers(q).map((answer, index) => selectableToOrdering(answer, index));
  }
  if (isWriteIn(q)) {
    answers = q.answers.map(answer => ({
      content: answer.content,
      position: answer.position,
      images: []
    }));
  }
  return {
    question: questionText(q),
    order: answers,
    inactivePartitions: []
  }
}
