export { default as Editor, TextDisplay } from "./TextEditor";
export type { FocusRequest } from "./TextEditor";
// export type { SimpleEditorState as Content } from "./SimpleEditor";
export type {
  RawState as Content,
  EditorFragment
} from "./editorState";
export {
  stringRepresentation,
  equals,
  fromString,
  initialRawState as constructContent,
  nonEmpty,
  nonEmptyBlank,
  isAnyBlank,
  undoSufficientDifference,
  isSupplied,
  isTextContent,
  rawStateFromString,
  rehydrate,
  includesMath,
  documentNonConflictCopy,
  documentNonConflictRestore
} from "./editorState";
// export {
//   constructSimpleEditorState as constructContent,
//   textLength,
//   stringRepresentation,
//   fromString,
//   nonEmpty,
//   equals,
//   undoSufficientDifference
// } from "./SimpleEditor";
// export { default as TextEditor } from "./TextEditor";