// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ColorPicker_color-picker__uxL-g {
  display: grid;
  grid-auto-flow: column;
  background: white;
  border: 1px solid #dddddd;
  padding: 5px;
}
@media (max-width: 600px) {
  .ColorPicker_color-picker__uxL-g {
    grid-template-rows: repeat(2, auto);
    max-width: 50vw;
    overflow-x: scroll;
  }
}
@media (min-width: 600px) {
  .ColorPicker_color-picker__uxL-g {
    grid-template-rows: repeat(4, auto);
  }
}

@media (max-width: 600px) {
  .ColorPicker_color-box__ivQpU {
    height: 2rem;
    width: 2rem;
    margin: 10px;
  }
}
@media (min-width: 600px) {
  .ColorPicker_color-box__ivQpU {
    height: 1rem;
    width: 1rem;
  }
  .ColorPicker_color-box__ivQpU:hover {
    height: 0.8rem;
    width: 0.8rem;
  }
}

.ColorPicker_color-box-selected__hCKU4 {
  height: 0.8rem;
  width: 0.8rem;
}

.ColorPicker_color-box-wrapper__sJ64b, .ColorPicker_color-box-wrapper-selected__NyWXg {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 2px;
}

.ColorPicker_color-box-wrapper-selected__NyWXg {
  border: 1px solid black;
}`, "",{"version":3,"sources":["webpack://./src/TextEditor/ColorPicker.module.scss"],"names":[],"mappings":"AAMA;EACE,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,yBAAA;EACA,YAAA;AALF;AAME;EANF;IAOI,mCAAA;IACA,eAAA;IACA,kBAAA;EAHF;AACF;AAIE;EAXF;IAYI,mCAAA;EADF;AACF;;AAKE;EADF;IAEI,YAtBmB;IAuBnB,WAvBmB;IAwBnB,YAAA;EADF;AACF;AAEE;EANF;IAOI,YA1BoB;IA2BpB,WA3BoB;EA4BtB;EAAE;IACE,cA5BgB;IA6BhB,aA7BgB;EA+BpB;AACF;;AAEA;EACE,cAnCoB;EAoCpB,aApCoB;AAqCtB;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,YAAA;AACF;;AAEA;EAEE,uBAAA;AAAF","sourcesContent":["@import \"../styles/constants.module.scss\";\n\n$mobile-box-dimension: 2rem;\n$desktop-box-dimension: 1rem;\n$focus-box-dimension: 0.8rem;\n\n.color-picker {\n  display: grid;\n  grid-auto-flow: column;\n  background: white;\n  border: 1px solid $light-gray;\n  padding: 5px;\n  @media(max-width: $mobile) {\n    grid-template-rows: repeat(2, auto);\n    max-width: 50vw;\n    overflow-x: scroll;\n  }\n  @media(min-width: $mobile) {\n    grid-template-rows: repeat(4, auto);\n  }\n}\n\n.color-box {\n  @media(max-width: $mobile) {\n    height: $mobile-box-dimension;\n    width: $mobile-box-dimension;\n    margin: 10px;\n  }\n  @media(min-width: $mobile) {\n    height: $desktop-box-dimension;\n    width: $desktop-box-dimension;\n    &:hover {\n      height: $focus-box-dimension;\n      width: $focus-box-dimension;\n    }\n  }\n}\n\n.color-box-selected {\n  height: $focus-box-dimension;\n  width: $focus-box-dimension;\n}\n\n.color-box-wrapper {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n  padding: 2px;\n}\n\n.color-box-wrapper-selected {\n  @extend .color-box-wrapper;\n  border: 1px solid black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"color-picker": `ColorPicker_color-picker__uxL-g`,
	"color-box": `ColorPicker_color-box__ivQpU`,
	"color-box-selected": `ColorPicker_color-box-selected__hCKU4`,
	"color-box-wrapper": `ColorPicker_color-box-wrapper__sJ64b`,
	"color-box-wrapper-selected": `ColorPicker_color-box-wrapper-selected__NyWXg`
};
export default ___CSS_LOADER_EXPORT___;
