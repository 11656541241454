import { useContext, createContext, FC } from 'react';
import { v4 } from 'uuid';
import * as D from "./documentOperations";
import EditorContextValue from "./editorContextValue";
import { colorStyles } from "./ColorPicker";
import { fontSizeStyles } from "./FontSizePicker";
import { fontStyles } from "./FontPicker";

export type TextContent = {
  fragmentId: string;
  content: string;
  componentType?: string;
};

export type StyleBoundary = {
  fragmentId: string;
  styleId: string;
  boundaryType: Boundary;
  category: string;
};

export type MathContent = {
  fragmentId: string;
  mathContent: string;
  componentType: string;
};

export type LinkComponent = {
  fragmentId: string;
  displayText: string;
  link: string;
}

export type BlankComponent = {
  fragmentId: string;
  blankContent: string;
  alternates: string[];
  options: string[];
};

export type SuppliedComponent = {
  fragmentId: string;
  suppliedContent: string;
  isMath: boolean;
};

export type BlankMathComponent = BlankComponent & {
  mathMarker: boolean;
}

export function toBlank(b: BlankMathComponent): BlankComponent {
  return b as BlankComponent;
}

export function toMath(b: BlankMathComponent): MathContent {
  return {
    fragmentId: b.fragmentId,
    mathContent: b.blankContent,
    componentType: 'MATH'
  };
}

export type StyleEnumeratedTextContent = {
  content: TextContent;
  styles: Set<string>;
};

export type EditorFragment = TextContent | StyleBoundary | MathContent | LinkComponent | BlankComponent | BlankMathComponent | SuppliedComponent;

export type TextualFragment = TextContent | BlankComponent;

export type AnyBlankComponent = BlankComponent | BlankMathComponent;

export type AnyMathComponent = MathContent | BlankMathComponent;

export function isMath(fragment: EditorFragment): fragment is MathContent {
  return (fragment as MathContent).mathContent !== undefined;
}

export function isAnyMath(fragment: EditorFragment): fragment is AnyMathComponent {
  return isMath(fragment) || isBlankMath(fragment);
}

export function isTextContent(fragment: EditorFragment): fragment is TextContent {
  return (fragment as TextContent).content !== undefined;
}

export function isStyleBoundary(fragment: EditorFragment): fragment is StyleBoundary {
  return (fragment as StyleBoundary).styleId !== undefined;
}

export function isLinkComponent(fragment: EditorFragment): fragment is LinkComponent {
  return (fragment as LinkComponent).link !== undefined;
}

export function isBlank(fragment: EditorFragment): fragment is BlankComponent {
  return (fragment as BlankComponent).blankContent !== undefined && (fragment as BlankMathComponent).mathMarker === undefined;
}

export function isTextual(fragment: EditorFragment): fragment is TextualFragment {
  return isTextContent(fragment) || isBlank(fragment);
}

export function isBlankMath(fragment: EditorFragment): fragment is BlankMathComponent {
  return (fragment as BlankMathComponent).mathMarker !== undefined;
}

export function isAnyBlank(fragment: EditorFragment): fragment is AnyBlankComponent {
  return (fragment as BlankComponent).blankContent !== undefined;
}

export function isSupplied(fragment: EditorFragment): fragment is SuppliedComponent {
  return (fragment as SuppliedComponent).suppliedContent !== undefined;
}

function isBoundaryForStyle(fragment: EditorFragment, styleId: string): boolean {
  if (isStyleBoundary(fragment)) {
    return fragment.styleId === styleId;
  }
  return false;
}

function isBoundaryStart(fragment: EditorFragment): boolean {
  return isBoundaryType(fragment, 'START');
}

function isBoundaryEnd(fragment: EditorFragment): boolean {
  return isBoundaryType(fragment, 'END');
}

function isBoundaryType(fragment: EditorFragment, boundaryType: Boundary): boolean {
  if (isStyleBoundary(fragment)) {
    return fragment.boundaryType === boundaryType;
  }
  return false;
}

function isBoundaryCategory(fragment: EditorFragment, category: string): boolean {
  return (fragment as StyleBoundary).category === category;
}

function isBoundaryCategoryStart(fragment: EditorFragment, category: string): boolean {
  return isBoundaryCategory(fragment, category) && isBoundaryStart(fragment);
}

function isBoundaryCategoryEnd(fragment: EditorFragment, category: string): boolean {
  return isBoundaryCategory(fragment, category) && isBoundaryEnd(fragment);
}

export function isSuppliedComponent(fragment: EditorFragment): fragment is SuppliedComponent {
  return (fragment as SuppliedComponent).suppliedContent !== undefined;
}

// export function isNewLine(fragment: EditorFragment): fragment is NewLine {
//   const asText = fragment as TextContent;
//   const asBoundary = fragment as StyleBoundary;
//   return asText.content === undefined && asBoundary.styleId === undefined;
// }

export function TextContent(componentType?: string) {
  return {
    fragmentId: v4(),
    content: "",
    componentType
  };
}

export function simpleTextContent(content: string): TextContent {
  return {
    fragmentId: v4(),
    content
  };
}

export function textualContent(content: string, blank: boolean): TextualFragment {
  if (blank) {
    return blankContent(content);
  } else {
    return simpleTextContent(content);
  }
}

export function simpleIdTextContent(content: string, id: string): TextContent {
  return {
    fragmentId: id,
    content
  }
}

export function emptyMathContent(componentType: string): MathContent {
  return {
    fragmentId: v4(),
    mathContent: "",
    componentType
  };
}

export function mathContent(content: string): MathContent {
  return {
    fragmentId: v4(),
    mathContent: content,
    componentType: "MATH"
  }
}

export function blankMath(math: MathContent): BlankMathComponent {
  return {
    mathMarker: true,
    fragmentId: math.fragmentId,
    blankContent: math.mathContent,
    alternates: [],
    options: []
  }
}

export function blankMathComponent(content: string): BlankMathComponent {
  return {
    mathMarker: true,
    fragmentId: v4(),
    blankContent: content,
    alternates: [],
    options: []
  }
}

export function linkContent(content: string): LinkComponent {
  return {
    fragmentId: v4(),
    link: content,
    displayText: content
  }
}

export function blankContent(content: string): BlankComponent {
  return {
    fragmentId: v4(),
    blankContent: content,
    alternates: [],
    options: []
  };
}

export function blankToMathBlank(blank: BlankComponent): BlankMathComponent {
  return {
    ...blank,
    mathMarker: true
  };
}

export function mathBlankToBlank(blank: BlankMathComponent): BlankComponent {
  return {
    fragmentId: blank.fragmentId,
    blankContent: blank.blankContent,
    alternates: blank.alternates,
    options: blank.options
  };
}

export function suppliedToBlank(supplied: SuppliedComponent): BlankComponent {
  return blankContent(supplied.suppliedContent);
}

export function suppliedToBlankMath(supplied: SuppliedComponent): BlankMathComponent {
  return blankMathComponent(supplied.suppliedContent);
}

export function toggleStyle(boundary: StyleBoundary, styles: Set<string>): Set<string> {
  if (boundary.boundaryType === 'START') {
    styles.add(boundary.styleId)
  } else if (boundary.boundaryType === 'END') {
    styles.delete(boundary.styleId);
  }
  return styles;
}

export function styledContent(styleId: string, category: string): EditorFragment[] {
  return [
    startStyleBoundary(styleId, category),
    TextContent(),
    endStyleBoundary(styleId, category)
  ];
}

export function styledContentInversion(styleId: string, category: string): EditorFragment[] {
  return [
    endStyleBoundary(styleId, category),
    TextContent(),
    startStyleBoundary(styleId, category)
  ];
}

export function endStyleBoundary(styleId: string, category: string): StyleBoundary {
  return StyleBoundary(styleId, category, 'END')
}

export function startStyleBoundary(styleId: string, category: string): StyleBoundary {
  return StyleBoundary(styleId, category, 'START');
}

function StyleBoundary(styleId: string, category: string, boundaryType: Boundary) {
  return {
    fragmentId: v4(),
    styleId,
    boundaryType,
    category
  }
}

export type EditorContent = {
  fragments: EditorFragment[];
  cursorPosition: number;
  styles: Map<string, StyleDescription>;
  mostRecent: number;
};

type IdentifiedBoundary = {
  styleId: string;
};

type StyleDescription = {
  styleId: string;
  style?: object;
  // component?: ComponentStyle;
  // recency: number;
};

type Boundary = 'START' | 'END' | 'ENTIRETY';

// type ComponentStyle = {
//   componentType: string;
//   metadata?: object;
// };

export type EditorLocality = {
  fragmentId: string;
  index: number;
};

export function EditorLocality(fragmentId: string, index?: number) {
  return {
    fragmentId,
    index: index || 0
  };
}

export function localityEquals(a: EditorLocality, b: EditorLocality): boolean {
  return a.fragmentId === b.fragmentId && a.index === b.index;
}

function incrementSelection(selection: SelectionDetermination, increment: number, shift: boolean): SelectionDetermination {
  const end = {
    ...selection.end,
    index: selection.end.index + increment
  };
  const anchor = shift ? selection.anchor : end;
  return { ...selection, end, anchor };
}

export type SelectionDetermination = {
  anchor: EditorLocality;
  end: EditorLocality;
  collapsed: boolean;
}

export function SelectionDetermination(startFragment: string, startIndex: number, endFragment?: string, endIndex?: number, startActive?: boolean) {
  const endf = endFragment || startFragment;
  const endi = endIndex || startIndex;
  const anchor = EditorLocality(startFragment, startIndex);
  const end = EditorLocality(endf, endi);
  const collapsed = localityEquals(anchor, end);
  return { anchor, end, collapsed };
}

export function selectionDeterminationString(s: SelectionDetermination): string {
  return `${s.anchor.fragmentId}|${s.anchor.index}|${s.end.fragmentId}|${s.end.index}|${s.collapsed}`;
}

export function selectionEquals(a: SelectionDetermination, b: SelectionDetermination): boolean {
  return localityEquals(a.anchor, b.anchor) && localityEquals(a.end, b.end) && a.collapsed === b.collapsed;
}

export function horizontalUpdate(selection: SelectionDetermination, right: boolean, shift: boolean): SelectionDetermination {
  if (shift && selection.collapsed) {
    if (right) {
      return {
        ...selection,
        collapsed: false
      }
    } else {
      return {
        ...selection,
        collapsed: false
      }
    }
  }
  if (right) {
    return incrementSelection(selection, 1, shift);
  } else {
    return incrementSelection(selection, -1, shift);
  }
}

function initialSelectionDetermination(firstFragmentId: string): SelectionDetermination {
  return SelectionDetermination(firstFragmentId, 0);
}

export type EditorState = {
  content: EditorContent;
  history: EditorContent[];
  active: boolean;
  editable: boolean;
  current: number;
  components: Map<string, FC>;
  metadataComponents: Map<string, MetadataType>;
  metadataEditor: MetadataContext | null;
  activeComponent?: string;
  newlyLoaded: boolean;
  selection: SelectionDetermination;
  anchorSideSelectedComponentId?: string;
  endSideSelectedComponentId?: string;
};

export type MetadataContext = {
  metadataType: MetadataType;
  styleId: string;
};

export function metadataEditorState(state: EditorState): object | null {
  if (!state.metadataEditor) {
    return null;
  } else {
    const styleDescriptor = currentContent(state).styles.get(state.metadataEditor.styleId);
    return null; //styleDescriptor?.component?.metadata || null;
  }
}

export function newContentFragment(): TextContent {
  return {
    fragmentId: v4(),
    content: ""
  };
}

export function appendContentFragment(content: EditorContent): EditorContent {
  return {
    ...content,
    fragments: content.fragments.concat([newContentFragment()])
  };
}

export type StyleSpecification = {
  styleId: string;
  style: Record<string, string>;
  category: string;
}

const SPECIFIED_STYLES: StyleSpecification[] = [
  // {
  //   styleId: 'MATH',
  //   component: {
  //     componentType: 'MATH'
  //   },
  //   recency: 0
  // },
  {
    styleId: 'BOLD',
    style: {
      "font-weight": "bold"
    },
    category: "BOLD"
  },
  {
    styleId: "ITALIC",
    style: {
      "font-style": "italic"
    },
    category: "ITALIC"
  },
  {
    styleId: "UNDERLINE",
    style: {
      "text-decoration": "underline"
    },
    category: "UNDERLINE"
  },
  {
    styleId: "STRIKETHROUGH",
    style: {
      "text-decoration": "line-through"
    },
    category: "STRIKETHROUGH"
  },
  {
    styleId: "BLANK",
    style: {
      "border": "1px solid black",
      "border-radius": "3px"
    },
    category: "BLANK"
  }
];

const STYLES: StyleSpecification[] = [
  ...SPECIFIED_STYLES,
  ...colorStyles("TEXT_COLOR"),
  ...colorStyles("HIGHLIGHT_COLOR"),
  ...fontSizeStyles,
  ...fontStyles
];

export function createEditorState(active: boolean, editable: boolean): EditorState {
  // const initialFragment = simpleIdTextContent("-------", "first");
  const initialFragment = simpleTextContent("");
  const initialFragments = [
    initialFragment,

    startStyleBoundary('UNDERLINE', 'UNDERLINE'),
    simpleTextContent("abc"),
    endStyleBoundary('UNDERLINE', 'UNDERLINE'),
    // simpleTextContent("def"),
    // startStyleBoundary('UNDERLINE', 'UNDERLINE'),
    // simpleTextContent("hik"),
    // endStyleBoundary('UNDERLINE', 'UNDERLINE'),
    simpleTextContent("lmn-------------"),
    // mathContent("\\oint x^2 dx"),
    // simpleTextContent(""),

    // startStyleBoundary('UNDERLINE', 'UNDERLINE'),
    // simpleIdTextContent("7777777777777777777777777777777777777777777777777777777777777777777777777777777777777777777777777777777777777777", "underline"),
    // endStyleBoundary('UNDERLINE', 'UNDERLINE'),
    // simpleIdTextContent("----------------------------- zorbleborgle abcdefghijklmnopqrstuvwxyz", "zorgle"),
    // startStyleBoundary("STRIKETHROUGH", 'STRIKETHROUGH'),
    // simpleIdTextContent(".........................", "dots"),
    // endStyleBoundary("STRIKETHROUGH", 'STRIKETHROUGH'),
    // simpleIdTextContent("\nzog\n\n-------------------------------", "zog"),
    // mathContent("\\begin{bmatrix} 1 & 2 \\\\ 3 & 4 \\end{bmatrix}"),
    // simpleIdTextContent("--------------------------------------------- log", "log"),
    // startStyleBoundary("BOLD", 'BOLD'),
    // simpleIdTextContent("curious customer crustaceans customize custard ", "and"),
    // endStyleBoundary("BOLD", 'BOLD'),
    simpleIdTextContent("^^^\n\n\n\n^^^^\n\n\n\n\n\n\n\n\n\n^^^\n\n^^\n^^\n^^^\n\n\n^^\n\n", "carrotbob")
  ]
  const initialContent = {
    fragments: initialFragments,
    cursorPosition: 0,
    styles: new Map<string, StyleDescription>(STYLES.map(style => [style.styleId, style])),
    mostRecent: 0
  };
  return {
    content: initialContent,
    history: [initialContent],
    active,
    editable,
    current: 0,
    components: new Map(),
    metadataEditor: null,
    metadataComponents: new Map(),
    newlyLoaded: true,
    selection: initialSelectionDetermination(initialFragment.fragmentId)
  }
}

export function currentContent(state: EditorState): EditorContent {
  if (state.current >= 0 && state.current < state.history.length) {
    return state.history[state.current];
  } else {
    return state.history[state.history.length - 1];
  }
}

export type BaseRegion = {
  fragmentId: string;
  styles: Set<string>;
}

export type MathRegion = BaseRegion & {
  mathContent: string;
  componentType: string;
};

export type ContentRegion = BaseRegion & {
  content: string;
  componentType?: string;
};

export type LinkRegion = BaseRegion & {
  displayText: string;
  link: string;
};

export type BlankRegion = BaseRegion & {
  blank: BlankComponent;
}

export type BlankMathRegion = BlankRegion & {
  mathMarker: boolean;
};

export type SuppliedRegion = BaseRegion & {
  isMath: boolean;
  suppliedContent: string;
};

export function toMathRegion(b: BlankMathRegion): MathRegion {
  return {
    fragmentId: b.fragmentId,
    styles: b.styles,
    mathContent: b.blank.blankContent,
    componentType: 'MATH'
  }
}

export type DiscreteStyle = MathRegion | ContentRegion | LinkRegion | BlankRegion | BlankMathRegion | SuppliedRegion;

export function isContentRegion(style: DiscreteStyle): style is ContentRegion {
  return (style as ContentRegion).content !== undefined;
}

export function isMathRegion(style: DiscreteStyle): style is MathRegion {
  return (style as MathRegion).mathContent !== undefined;
}

export function isLinkRegion(style: DiscreteStyle): style is LinkRegion {
  return (style as LinkRegion).link !== undefined;
}

export function isBlankRegion(style: DiscreteStyle): style is BlankRegion {
  return (style as BlankRegion).blank !== undefined && (style as BlankMathRegion).mathMarker === undefined;
}

export function isBlankMathRegion(style: DiscreteStyle): style is BlankMathRegion {
  return (style as BlankMathRegion).mathMarker !== undefined;
}

export function isSuppliedRegion(style: DiscreteStyle): style is SuppliedRegion {
  return (style as SuppliedRegion).suppliedContent !== undefined;
}

export function suppliedToBlankRegion(supplied: SuppliedRegion): BlankRegion {
  return {
    styles: supplied.styles,
    fragmentId: supplied.fragmentId,
    blank: blankContent(supplied.suppliedContent)
  };
}

export function suppliedToBlankMathRegion(supplied: SuppliedRegion): BlankMathRegion {
  return {
    mathMarker: true,
    ...suppliedToBlankRegion(supplied)
  };
}

export function discretizeStyles(content: EditorContent): DiscreteStyle[] {
  const fragments = content.fragments;
  const styles = content.styles;
  const activeStyles = new Set<string>();
  const discrete = [] as DiscreteStyle[];
  const discreteStack = [discrete];
  function currentDiscrete(): DiscreteStyle[] {
    return discreteStack[discreteStack.length - 1];
  }
  function removeLevel() {
    discreteStack.pop();
  }
  // function addComponentLevel(component: ComponentStyle, boundaryType: Boundary, fragmentId: string) {
  //   const stackLevel = [] as DiscreteStyle[];
  //   currentDiscrete().push({
  //     fragmentId,
  //     styles: activeStyles,
  //     contents: stackLevel
  //   });
  //   if (boundaryType === 'ENTIRETY') {
  //     discreteStack.push(stackLevel);
  //   }
  // }
  fragments.forEach(fragment => {
    if (isStyleBoundary(fragment)) {
      const boundary = fragment as StyleBoundary;
      const styleDescription = styles.get(boundary.styleId);
      // const component = styleDescription?.component;
      switch (boundary.boundaryType) {
        case 'START':
          activeStyles.add(boundary.styleId);
          // if (component) {
            // addComponentLevel(component, 'START', boundary.fragmentId);
          // }
          break;
        case 'END':
          activeStyles.delete(boundary.styleId);
          // if (component) {
          //   removeLevel();
          // }
          break;
        case 'ENTIRETY':
          // if (component) {
          //   addComponentLevel(component, 'ENTIRETY', boundary.fragmentId);
          // } else {
          //   currentDiscrete().push({
          //     fragmentId: boundary.fragmentId,
          //     styles: activeStyles,
          //     content: "",
          //   });
          // }
      }
    } else if (isTextContent(fragment)) {
      const content = fragment as TextContent;
      currentDiscrete().push({
        fragmentId: content.fragmentId,
        content: content.content,
        styles: new Set(activeStyles),
        componentType: fragment.componentType
      });
    } else if (isMath(fragment)) {
      const content = fragment as MathContent;
      currentDiscrete().push({
        fragmentId: content.fragmentId,
        mathContent: content.mathContent,
        styles: new Set(activeStyles),
        componentType: content.componentType
      });
    } else if (isLinkComponent(fragment)) {
      const content = fragment as LinkComponent;
      currentDiscrete().push({
        fragmentId: content.fragmentId,
        link: content.link,
        displayText: content.displayText,
        styles: new Set(activeStyles)
      });
    } else if (isBlank(fragment)) {
      currentDiscrete().push({
        fragmentId: fragment.fragmentId,
        blank: fragment,
        styles: new Set(activeStyles)
      });
    } else if (isBlankMath(fragment)) {
      const blank = fragment as BlankMathComponent;
      currentDiscrete().push({
        fragmentId: blank.fragmentId,
        blank: toBlank(blank),
        mathMarker: true,
        styles: new Set(activeStyles)
      });
    } else if (isSuppliedComponent(fragment)) {
      currentDiscrete().push({
        fragmentId: fragment.fragmentId,
        suppliedContent: fragment.suppliedContent,
        isMath: fragment.isMath,
        styles: new Set(activeStyles)
      })
    } else {
      // const newLine = fragment as NewLine;
      // currentDiscrete().push({
      //   fragmentId: newLine.fragmentId,
      //   styles: new Set(activeStyles)
      // });
    }
  });
  return discrete;
}



export const EditorContext = createContext<EditorContextValue | null>(null);

export function useEditorContext(): EditorContextValue {
  const editor = useContext(EditorContext);
  if (editor === undefined) {
    throw new Error("can't use editor context");
  } else if (editor === null) {
    throw new Error("editor context uninitialized");
  } else {
    return editor;
  }
}

export function createContentCache(content: EditorContent): Map<string, TextContent> {
  return content.fragments.reduce((accumulator, fragment) => {
    if (isTextContent(fragment)) {
      const content = fragment as TextContent;
      accumulator.set(content.fragmentId, content);
    }
    return accumulator;
  }, new Map<string, TextContent>());
}

export function currentContentCache(state: EditorState): Map<string, TextContent> {
  return createContentCache(currentContent(state));
}

type PlacementIdentifier = {
  offset: number;
  sectionId: string;
};

type ProtoStyleBoundary = {
  styleId: string;
};

function idFinder(id: string): (_: EditorFragment) => boolean {
  return (fragment: EditorFragment) => {
    return isTextContent(fragment) && fragment.fragmentId === id;
  };
};

function beforeElements<T>(array: T[], index: number): T[] {
  return array.slice(0, index);
}

function afterElements<T>(array: T[], index: number): T[] {
  return array.slice(index + 1, array.length);
}

function betweenElements<T>(array: T[], start: number, end: number): T[] {
  return array.slice(start + 1, end);
}

export type MetadataType = 'MATH' | 'LINK' | 'BLANK' | 'BLANK_MATH' | 'NONE';

export type RawState = {
  fragments: EditorFragment[];
  // styles: StyleDescription[];
  // mostRecent: number;
}

function stateToRaw(state: EditorState): RawState {
  const content = currentContent(state);
  return {
    fragments: content.fragments,
    // styles: Array.from(content.styles.values()),
    // mostRecent: content.mostRecent
  };
}

function rawToState(raw: RawState, active: boolean, editable: boolean): EditorState {
  const styleMap = new Map(STYLES.map(style => [style.styleId, style]));
  const baseContent = {
    fragments: raw.fragments,
    styles: styleMap,
    mostRecent: 0, //raw.mostRecent,
    cursorPosition: 0
  };
  return {
    content: baseContent,
    history: [baseContent],
    active,
    editable,
    current: 0,
    components: new Map(),
    metadataEditor: null,
    metadataComponents: new Map(),
    newlyLoaded: false,
    selection: initialSelectionDetermination(raw.fragments[0].fragmentId)
  }
}

export function initialRawState(): RawState {
  return {
    fragments: [simpleTextContent("")]
  }
}

export function rawStateFromString(s: string): RawState {
  return {
    fragments: [simpleTextContent(s)]
  }
}

export function initializeEditorState(
  prior: RawState | undefined, active: boolean, editable: boolean
): EditorState {
  return prior ? rawToState(prior, active, editable) : createEditorState(active, editable);
}

export type CharacterLocality = {
  bounding: DOMRect;
  encapsulating: Text;
  index: number;
  fragmentId: string;
  character: string;
}

export function nextCharacter(locality: CharacterLocality): CharacterLocality {
  return {
    ...locality,
    index: locality.index + 1
  };
}

export function fragmentIndex(fid: string, fragments: EditorFragment[]): number | null {
  for (let i = 0; i < fragments.length; i++) {
    if (fragments[i].fragmentId === fid) {
      return i;
    }
  }
  return null;
}

export function selectionIndexRange(selection: SelectionDetermination, fragments: EditorFragment[]): [number, number] {
  let anchorIndex = -1;
  let endIndex = -1;
  for (let i = 0; i < fragments.length; i++) {
    if (fragments[i].fragmentId === selection.anchor.fragmentId) {
      anchorIndex = i;
    }
    if (fragments[i].fragmentId === selection.end.fragmentId) {
      endIndex = i;
    }
  }
  return [Math.min(anchorIndex, endIndex), Math.max(anchorIndex, endIndex)];
}

export function entiretyStyled(styleId: string, selection: SelectionDetermination, fragments: EditorFragment[]): boolean {
  const anchorIndex = fragmentIndex(selection.anchor.fragmentId, fragments);
  const endIndex = fragmentIndex(selection.end.fragmentId, fragments);
  if (anchorIndex !== null && endIndex !== null) {
    let lastStart: number | null = null;
    for (let i = 0; i < fragments.length; i++) {
      const f = fragments[i];
      if (isBoundaryForStyle(f, styleId) && isBoundaryStart(f)) {
        lastStart = i;
      }
      if (isBoundaryForStyle(f, styleId) && isBoundaryEnd(f)) {
        if (lastStart != null && anchorIndex > lastStart && endIndex > lastStart && anchorIndex < i && endIndex < i) {
          return true;
        }
        lastStart = null;
      }
    }
  }
  return false;
}

export function clearStyleBoundaries(fragments: EditorFragment[], selection: SelectionDetermination, styleId: string): EditorFragment[] {
  let fs = [...fragments];
  const [startIndex, endIndex] = selectionIndexRange(selection, fragments);
  // const endId =
  let i = startIndex + 1;
  let targetIndex = endIndex;
  while (i < targetIndex) {
    if (isBoundaryForStyle(fs[i], styleId)) {
      fs.splice(i, 1);
      targetIndex--;
    } else {
      i++;
    }
  }
  return fs;
}

export function splitText(locality: EditorLocality): [string, string] | null {
  const text = D.fragmentContents(locality.fragmentId);
  if (text !== null) {
    return [text.substring(0, locality.index), text.substring(locality.index, text.length)];
  }
  return null;
}

export function doubleSplitText(anchorIndex: number, endIndex: number, fragmentId: string): [string, string, string] | null {
  const firstIndex = Math.min(anchorIndex, endIndex);
  const lastIndex = Math.max(anchorIndex, endIndex);
  const text = D.fragmentContents(fragmentId);
  if (text !== null) {
    return [text.substring(0, firstIndex), text.substring(firstIndex, lastIndex), text.substring(lastIndex, text.length)];
  }
  return null;
}

export type StyleInsertionResult = {
  fragments: EditorFragment[];
  selection: SelectionDetermination;
};

export function insertStyleSingleFragment(
  anchorIndex: number,
  endIndex: number,
  fragmentId: string,
  collapsed: boolean,
  styleId: string,
  category: string,
  fragmentStyleOn: boolean
): StyleInsertionResult | null {
  const texts = doubleSplitText(anchorIndex, endIndex, fragmentId)
  if (texts !== null) {
    const [t1, t2, t3] = texts;
    const startBoundary = startStyleBoundary(styleId, category);
    const endBoundary = endStyleBoundary(styleId, category);
    const firstBoundary = fragmentStyleOn ? endBoundary : startBoundary;
    const lastBoundary = fragmentStyleOn ? startBoundary : endBoundary;
    const centerFragment = simpleTextContent(t2)
    const fragments = [
      simpleTextContent(t1),
      firstBoundary,
      centerFragment,
      lastBoundary,
      simpleTextContent(t3)
    ];
    const anchorFirst = anchorIndex <= endIndex;
    const firstLocality = EditorLocality(centerFragment.fragmentId, 0);
    const lastLocality = EditorLocality(centerFragment.fragmentId, t2.length);
    const selection = {
      anchor: anchorFirst ? firstLocality : lastLocality,
      end: anchorFirst ? lastLocality : firstLocality,
      collapsed
    }
    return { fragments, selection };
  }
  return null;
}

export function anchorFirst(selection: SelectionDetermination, fragments: EditorFragment[]): boolean {
  for (let i = 0; i < fragments.length; i++) {
    const f = fragments[i];
    if (f.fragmentId === selection.anchor.fragmentId) {
      return true;
    } else if (f.fragmentId === selection.end.fragmentId) {
      return false;
    }
  }
  return false;
}

function fragmentStyled(fragments: EditorFragment[], fragmentId: string, styleId: string): boolean {
  let styleActive = false;
  for (let i = 0; i < fragments.length; i++) {
    if (isBoundaryForStyle(fragments[i], styleId)) {
      styleActive = isBoundaryStart(fragments[i]);
    }
    if (fragments[i].fragmentId === fragmentId) {
      return styleActive;
    }
  }
  return false;
}

export function insertStyleDemarcator(locality: EditorLocality, styleId: string, category: string, boundaryType: Boundary): EditorFragment[] | null {
  const texts = splitText(locality);
  if (texts !== null) {
    const [t1, t2] = texts;
    return [
      simpleTextContent(t1),
      StyleBoundary(styleId, category, boundaryType),
      simpleTextContent(t2)
    ];
  }
  return null;
}

export function interFragmentSelectionStyle(
  selection: SelectionDetermination, fragments: EditorFragment[], styleId: string, category: string
): StyleInsertionResult {
  if (entiretyStyled(styleId, selection, fragments)) {
    const fs = [...fragments];
    const afirst = anchorFirst(selection, fs);
    const first = afirst ? selection.anchor : selection.end;
    const last = afirst ? selection.end : selection.anchor;
    const [firstIndex, lastIndex] = selectionIndexRange(selection, fs);
    const firstDemarcator = insertStyleDemarcator(first, styleId, category, 'END');
    const lastDemarcator = insertStyleDemarcator(last, styleId, category, 'START');
    return handleDemarcators(firstDemarcator, lastDemarcator, firstIndex, lastIndex, afirst, fs, true, true, category) || {
      fragments: fs,
      selection
    };
  } else {
    const anchorStyled = fragmentStyled(fragments, selection.anchor.fragmentId, styleId);
    const endStyled = fragmentStyled(fragments, selection.end.fragmentId, styleId);
    const cleared = clearStyleBoundaries(fragments, selection, styleId);
    const [firstIndex, lastIndex] = selectionIndexRange(selection, cleared);
    const afirst = anchorFirst(selection, cleared);
    const first = afirst ? selection.anchor : selection.end;
    const last = afirst ? selection.end : selection.anchor;
    const firstStyled = afirst ? anchorStyled : endStyled;
    const lastStyled = afirst ? endStyled : anchorStyled;
    const firstDemarcator = insertStyleDemarcator(first, styleId, category, 'START');
    const lastDemarcator = insertStyleDemarcator(last, styleId, category, 'END');
    return handleDemarcators(firstDemarcator, lastDemarcator, firstIndex, lastIndex, afirst, cleared, lastStyled, firstStyled, category) || {
      fragments: cleared,
      selection
    };
  }
}

function handleDemarcators(
  firstDemarcator: EditorFragment[] | null,
  lastDemarcator: EditorFragment[] | null,
  firstIndex: number,
  lastIndex: number,
  afirst: boolean,
  cleared: EditorFragment[],
  lastStyled: boolean,
  firstStyled: boolean,
  category: string
): StyleInsertionResult | null {
  if (firstDemarcator && lastDemarcator) {
    const firstPost = firstDemarcator[2] as TextContent;
    const lastPre = lastDemarcator[0] as TextContent;
    const firstLocality = {
      fragmentId: firstPost.fragmentId,
      index: 0
    };
    const lastLocality = {
      fragmentId: lastPre.fragmentId,
      index: lastPre.content.length
    }
    if (lastDemarcator !== null && !lastStyled) {
      cleared.splice(lastIndex, 1, ...lastDemarcator);
    }
    if (firstDemarcator !== null && !firstStyled) {
      cleared.splice(firstIndex, 1, ...firstDemarcator);
    }
    const newSelection = {
      anchor: afirst ? firstLocality : lastLocality,
      end: afirst ? lastLocality : firstLocality,
      collapsed: false
    };
    const categoriesAccounted = resolveCategoryConflicts(cleared, category, firstIndex + 1, lastIndex + 3);
    return {
      fragments: categoriesAccounted,
      selection: newSelection
    };
  }
  return null;
}


export function exciseFragmentsBetween(fragments: EditorFragment[], startIndex: number, endIndex: number): EditorFragment[] {
  let startBoundaries = new Map<string, number>();
  let fs: (EditorFragment | null)[] = [...fragments];
  for (let i = startIndex + 1; i < endIndex; i++) {
    const f = fragments[i];
    if (isStyleBoundary(f)) {
      if (isBoundaryStart(f)) {
        startBoundaries.set(f.styleId, i);
      } else if (isBoundaryEnd(f)) {
        const startIndex = startBoundaries.get(f.styleId);
        if (startIndex) {
          fs[i] = null;
          fs[startIndex] = null;
        }
      }
    } else {
      fs[i] = null;
    }
  }
  return fs.filter(item => item !== null) as EditorFragment[];
}

function resolveCategoryConflicts(
  fragments: EditorFragment[], category: string, firstIndex: number, lastIndex: number
): EditorFragment[] {
  let categoryMatchStarts = new Map<string, number>();
  let fs: (EditorFragment | null)[] = [...fragments];
  let prependEnd: StyleBoundary | null = null;
  let appendStart: StyleBoundary | null = null;
  for (let i = firstIndex + 1; i < lastIndex; i++) {
    const f = fragments[i];
    if (isBoundaryCategoryEnd(f, category)) {
      const sb = f as StyleBoundary;
      const startIndex = categoryMatchStarts.get(sb.styleId);
      if (startIndex !== undefined) {
        fs[startIndex] = null;
        fs[i] = null;
        appendStart = null;
      } else {
        prependEnd = sb;
      }
    }
    if (isBoundaryCategoryStart(f, category)) {
      const sb = f as StyleBoundary
      categoryMatchStarts.set(sb.styleId, i);
      appendStart = sb;
      fs[i] = null;
    }
  }
  fs.splice(firstIndex, 0, prependEnd);
  fs.splice(lastIndex + 1, 0, appendStart);
  return fs.filter(item => item !== null) as EditorFragment[];
}

export function fragmentBetwixt(fragmentId: string, fragments: EditorFragment[], selection: SelectionDetermination): boolean {
  if (fragmentId === selection.anchor.fragmentId || fragmentId === selection.end.fragmentId) {
    return true;
  }
  const [firstIndex, lastIndex] = selectionIndexRange(selection, fragments);
  for (let i = firstIndex; i < lastIndex; i++) {
    if (fragments[i].fragmentId === fragmentId) {
      return true;
    }
  }
  return false;
}

export type CopyPayload = {
  prependStyles: StyleBoundary[];
  appendStyles: StyleBoundary[];
  fragments: EditorFragment[];
  text: string;
}

export function isPayload(payload: any): payload is CopyPayload {
  return (payload as CopyPayload).prependStyles !== undefined;
}

export function payloadFromString(text: string): CopyPayload {
  return {
    prependStyles: [],
    appendStyles: [],
    fragments: [simpleTextContent(text)],
    text
  }
}

export function selectionContents(selection: SelectionDetermination, fragments: EditorFragment[]): CopyPayload {
  const [firstIndex, lastIndex] = selectionIndexRange(selection, fragments);
  const afirst = anchorFirst(selection, fragments);
  const first = afirst ? selection.anchor : selection.end;
  const last = afirst ? selection.end : selection.anchor;
  let boundaryStarts = new Set<string>();
  let boundaryEnds = new Set<string>();
  let prependStarts = new Set<string>();
  let appendEnds = new Set<string>();
  let includedFragments: EditorFragment[] = []
  const categories = new Map<string, string>();
  for (let i = 0; i <= firstIndex; i++) {
    const f = fragments[i];
    const id = f.fragmentId;
    if (isStyleBoundary(f)) {
      if (isBoundaryStart(f)) {
        prependStarts.add(f.styleId);
        appendEnds.add(f.styleId);
        categories.set(id, f.category);
      } else {
        appendEnds.delete(f.styleId);
        prependStarts.delete(f.styleId);
      }
    }
  }
  if (firstIndex === lastIndex) {
    const fragmentText = D.fragmentContents(first.fragmentId);
    if (fragmentText) {
      const cut = fragmentText.substring(first.index, last.index);
      includedFragments = [simpleTextContent(cut)];
    }
  } else {
    for (let i = firstIndex; i <= lastIndex; i++) {
      const f = fragments[i];
      if (isStyleBoundary(f)) {
        const id = f.styleId;
        categories.set(id, f.category);
        if (isBoundaryStart(f)) {
          boundaryStarts.add(id);
          appendEnds.add(id);
        } else if (isBoundaryEnd(f)) {
          appendEnds.delete(id);
        }
      }
    }
    const firstFragment = fragments[firstIndex];
    const lastFragment = fragments[lastIndex];
    const firstFragmentText = D.fragmentContents(firstFragment.fragmentId) || "";
    const lastFragmentText = D.fragmentContents(lastFragment.fragmentId) || "";
    const firstSelectedFragment = textualContent(firstFragmentText.substring(first.index), isBlank(firstFragment));
    const lastSelectedFragment = textualContent(lastFragmentText.substring(0, last.index), isBlank(lastFragment));
    const fullyIncludedFragments = D.refreshedFragments(fragments.slice(firstIndex + 1, lastIndex));
    includedFragments = [firstSelectedFragment, ...fullyIncludedFragments, lastSelectedFragment];
  }
  const prependStyles = [...prependStarts].map(styleId => startStyleBoundary(styleId, categories.get(styleId) || ""));
  const appendStyles = [...appendEnds].map(styleId => endStyleBoundary(styleId, categories.get(styleId) || ""));
  const text = fragmentListStringRepresentation(includedFragments);
  return { prependStyles, appendStyles, fragments: includedFragments, text };
}

export type SelectionEdgeStyles = {
  start: Set<string>;
  end: Set<string>;
  categories: Map<string, string>;
}

export function edgeStyles(fragments: EditorFragment[], selection: SelectionDetermination): SelectionEdgeStyles {
  const [firstIndex, lastIndex] = selectionIndexRange(selection, fragments);
  let start = new Set<string>();
  let end = new Set<string>();
  let categories = new Map<string, string>();
  for (let i = 0; i < firstIndex; i++) {
    const f = fragments[i];

    if (isStyleBoundary(f)) {
      const id = f.styleId;
      categories.set(id, f.category);
      if (isBoundaryStart(f)) {
        start.add(id);
        end.add(id);
      } else {
        start.delete(id);
        end.delete(id);
      }
    }
  }
  for (let i = firstIndex; i < lastIndex; i++) {
    const f = fragments[i];
    if (isStyleBoundary(f)) {
      const id = f.styleId;
      if (isBoundaryStart(f)) {
        end.add(id);
      } else {
        end.delete(id);
      }
    }
  }
  return { start, end, categories };
}

function pasteStartStyleBoundaries(
  copyStartBoundaries: StyleBoundary[], selectionStartBoundaries: Set<string>, selectionCategories: Map<string, string>
): StyleBoundary[] {
  const copyStartSet = new Set(copyStartBoundaries.map(boundary => boundary.styleId));
  const starts = copyStartBoundaries.filter(boundary => !selectionStartBoundaries.has(boundary.styleId));
  const ends = [...selectionStartBoundaries]
    .filter(boundary => !copyStartSet.has(boundary))
    .map(boundary => endStyleBoundary(boundary, selectionCategories.get(boundary)!));
  return [
    ...ends,
    ...starts
  ];
}

function pasteEndStyleBoundaries(
  copyEndBoundaries: StyleBoundary[], selectionEndBoundaries: Set<string>, selectionCategories: Map<string, string>
): StyleBoundary[] {
  const copyEndSet = new Set(copyEndBoundaries.map(boundary => boundary.styleId));
  const ends = copyEndBoundaries.filter(boundary => !selectionEndBoundaries.has(boundary.styleId));
  const starts = [...selectionEndBoundaries]
    .filter(boundary => !copyEndSet.has(boundary))
    .map(boundary => startStyleBoundary(boundary, selectionCategories.get(boundary)!))
  return [
    ...ends,
    ...starts
  ];
}

export type EdgePasteStyles = {
  preBoundaries: EditorFragment[];
  postBoundaries: EditorFragment[];
};

export function edgeStylesForPaste(
  fragments: EditorFragment[], selection: SelectionDetermination, copyPayload: CopyPayload
): EdgePasteStyles {
  const edges = edgeStyles(fragments, selection);
  const preBoundaries = pasteStartStyleBoundaries(copyPayload.prependStyles, edges.start, edges.categories);
  const postBoundaries = pasteEndStyleBoundaries(copyPayload.appendStyles, edges.end, edges.categories);
  return { preBoundaries, postBoundaries };
}

export function pasteStyled(
  fragments: EditorFragment[], selection: SelectionDetermination, copyPayload: CopyPayload, edges: EdgePasteStyles
): [EditorFragment[], SelectionDetermination] {
  const fs = [...fragments];
  const { fragmentId, index } = selection.end;
  const [firstIndex, lastIndex] = selectionIndexRange(selection, fragments);
  const fragmentText = D.fragmentContents(fragmentId);

  if (fragmentText !== null) {
    const beforeText = fragmentText.substring(0, index);
    const afterText = fragmentText.substring(index);
    const beforeFragment = simpleTextContent(beforeText);
    const afterFragment = simpleTextContent(afterText);
    const locality = EditorLocality(afterFragment.fragmentId, 0);
    
    const pastedFragments = [beforeFragment, ...edges.preBoundaries, ...replaceIds(copyPayload.fragments), ...edges.postBoundaries, afterFragment];
    fs.splice(lastIndex, 1, ...pastedFragments);
    const [compacted, compactedLocality] = compactAdjacentText(fs, locality)
    const selection = { anchor: compactedLocality, end: compactedLocality, collapsed: false };
    return [compacted, selection];
  }
  throw new Error("pasteStyled text not found")
}

export function compactAdjacentText(fragments: EditorFragment[], locality: EditorLocality): [EditorFragment[], EditorLocality] {
  let activeText: TextContent | null = null;
  let compacted: EditorFragment[] = [];
  let newLocality = locality;
  for (let i = 0; i < fragments.length; i++) {
    const f = fragments[i];
    if (isTextContent(f)) {
      if (activeText === null) {
        activeText = f;
        compacted.push(f);
      } else {
        if (locality.fragmentId === f.fragmentId) {
          newLocality = {
            fragmentId: activeText.fragmentId,
            index: activeText.content.length + locality.index
          };
        }
        activeText.content += f.content;
        
      }
    } else {
      compacted.push(f);
      activeText = null;
    }
  }
  return [compacted, newLocality];
}

export function stringRepresentation(state: RawState): string {
  return fragmentListStringRepresentation(state.fragments);
}

function replaceIds(fragments: EditorFragment[]): EditorFragment[] {
  return fragments.map(f => ({
    ...f,
    fragmentId: v4()
  }));
}

export function fragmentListStringRepresentation(fragments: EditorFragment[]): string {
  return fragments.map(fragment => {
    if (isTextContent(fragment)) {
      return fragment.content;
    } else if (isMath(fragment)) {
      return fragment.mathContent;
    } else if (isLinkComponent(fragment)) {
      return fragment.link;
    } else if (isBlank(fragment)) {
      return fragment.blankContent; //D.fragmentContents(fragment.fragmentId);
    } else {
      return "";
    }
  }).join("");
}

export function equals(a: RawState, b: RawState): boolean {
  return stringRepresentation(a) === stringRepresentation(b);
}

// export function fragmentEquals(a: EditorFragment, b: EditorFragment): boolean {
//   if (isTextContent(a) && isTextContent(b)) {
//     return a.content === b.content;
//   } else if (isAnyBlank(a) && isAnyBlank(b)) {
//     return a.blankContent === b.blankContent;
//   } else if (is)
// }

export function fromString(s: string): RawState {
  return {
    fragments: [simpleTextContent(s)]
  };
}

export function nonEmpty(state: RawState): boolean {
  return stringRepresentation(state).length > 0;
}

export function nonEmptyBlank(blank: AnyBlankComponent): boolean {
  return blank.blankContent.length > 0;
}

export function undoSufficientDifference(a: RawState, b: RawState): boolean {
  return false;
}

export function deBlank(fragmentId: string, fragments: EditorFragment[], locality: EditorLocality): [EditorFragment[], EditorLocality] {
  const refreshed = D.refreshedFragments(fragments);
  const index = refreshed.findIndex(f => f.fragmentId === fragmentId);
  if (index) {
    const fragment = refreshed[index];
    if (isBlank(fragment)) {
      refreshed.splice(index, 1, simpleTextContent(D.fragmentContents(fragmentId) || ""));
      return compactAdjacentText(refreshed, locality);
    }
  }
  return [refreshed, locality];
}

export function allInABlank(fragments: EditorFragment[]): boolean {
  return fragments.length === 1 && isBlank(fragments[0]);
}

export function rehydrate(c: RawState): RawState {
  const newC = {
    ...c,
    fragments: c.fragments.map(f => ({
      ...f,
      fragmentId: v4()
    }))
  }
  return newC;
}

export function includesMath(r: RawState): boolean {
  return !!r.fragments.find(f => isAnyMath(f));
}

export function documentNonConflictCopy(r: RawState, prefix: string): RawState {
  return {
    ...r,
    fragments: r.fragments.map(f => ({
      ...f,
      fragmentId: `${prefix}:${f.fragmentId}`
    }))
  };
}

export function documentNonConflictRestore(r: RawState): RawState {
  return {
    ...r,
    fragments: r.fragments.map(f => ({
      ...f,
      fragmentId: f.fragmentId.substring(f.fragmentId.indexOf(":") + 1)
    }))
  };
}