import React, { useState, ReactNode, useRef, useEffect, Ref, memo } from 'react';
import { MathMetadataEditor, MathDescriptor, MathDisplay } from "./MathDisplay";
import styles from "./TextEditor.module.scss";
import useFocus from "../focus";
import useClosable from "../closable";
import ColorPicker, { Color, supportedColors, colorId, ColorEffect } from "./ColorPicker";
import { BiLink } from "react-icons/bi";
import { useGlossary as g } from "../language";
import { LinkMetadataEditor } from "./EditorLink";
import SymbolPicker from "./SymbolPicker";
import LanguagePicker from "./LanguagePicker";
import * as F from "./featureFilter";
import * as S from "./editorState";
import EditorInternals from "./EditorInternals";
import EditorContextValue from "./editorContextValue";
import FontSizePicker, { fontSizeStyleId } from "./FontSizePicker";
import FontPicker, { fontStyleId } from "./FontPicker";
import { MathJax } from "better-react-mathjax";
import * as D from "./documentOperations";
import BlankMetadataEditor from "./BlankMetadataEditor";
import { ErrorBoundary } from "react-error-boundary";

export type EditState = 'editable' | 'uneditable' | 'blanks-only' | 'selectable';

export type FocusRequest = {
  request: boolean;
  indicateFocus: () => void;
}

type TextEditorProps = {
  featureFilter?: F.GeneralFeatureFilter;
  value?: S.RawState;
  onChange: (_: S.RawState) => void;
  editable?: EditState;
  reference?: Ref<HTMLTextAreaElement>;
  additionalClasses?: string;
  blankMapper?: Map<string, ReactNode>;
  dropEvent?: MouseEvent;
  dropPayload?: S.RawState;
  droppable?: boolean;
  draggable?: boolean;
  dragging?: boolean;
  externalResponsive?: boolean;
  requestFocus?: FocusRequest;
  draggableReplacement?: boolean;
};

export default function TextEditor(props: TextEditorProps) {
  const [editorState, setEditorState] = useState<S.EditorState>(S.initializeEditorState(props.value, true, true));
  const ref = useRef<HTMLDivElement>(null);
  const featureFilter = props.featureFilter || true;
  const editable: EditState = props.editable === undefined ? 'editable' : props.editable;
  const droppable = props.droppable === undefined ? false : props.droppable;
  const draggable = props.draggable === undefined ? false : props.draggable;
  const dragging = props.dragging === undefined ? false : props.dragging;
  const focusEditor = () => {
    if (ref && ref.current) {
      // ref!.current!.focus();
    }
  }
  const [metadataType, setMetadataType] = useState<S.MetadataType>('NONE');
  const contextValue = new EditorContextValue(
    editorState,
    (s: S.EditorState, refresh: boolean) => {
      setEditorState(s);
      const official = refresh ? D.refreshedFragments(s.content.fragments) : s.content.fragments;
      props.onChange({
        fragments: official
      });
    },
    S.currentContentCache(editorState),
    setMetadataType
  );
  const noBorder = (editable === 'uneditable' || editable === 'selectable') && !props.draggable;
  const wrapperClass = styles[noBorder ? 'uneditable-component-wrapper' : 'component-wrapper'];
  const className = `${wrapperClass} ${props.additionalClasses || ""}`;
  const blanksOnly = editable === 'blanks-only';
  useEffect(() => {
    if (props.dropEvent && props.dropPayload) {
      contextValue.executeDrop(props.dropEvent, props.dropPayload.fragments);
    }
  }, [props.dropEvent, props.dropPayload]);
  useEffect(() => {
    if (props.externalResponsive && props.value && !S.equals(props.value, editorState.content)) {
      setEditorState({
        ...editorState,
        content: {
          ...editorState.content,
          fragments: props.value.fragments
        }
      });
    }
  }, [props.value]);
  const showBar = editable !== 'uneditable' && editable !== 'selectable';
  const internalsWrapperClass = styles[editable === 'selectable' ? 'selectable-editor-wrapper' : 'editor-wrapper'];
  return (
    <div className={className}>
      <S.EditorContext.Provider value={contextValue}>
        {props.featureFilter !== false && showBar && (
          <ButtonBar features={featureFilter} blanksOnly={blanksOnly}/>
        )}
        <div className={internalsWrapperClass} onClick={focusEditor}>
          <EditorInternals
            reference={ref}
            editable={editable}
            blankMapper={props.blankMapper}
            dragging={dragging}
            draggable={draggable}
            draggableReplacement={props.draggableReplacement}
            requestFocus={props.requestFocus}
          />
        </div>
        <MetadataEditor metadataType={metadataType} editable={editable}/>
      </S.EditorContext.Provider>
    </div>
  );
}

function TextEditorFallback() {
  return <div>not good</div>;
}

type TextDisplayProps = {
  value: S.RawState;
  additionalClasses?: string;
  blankMapper?: Map<string, ReactNode>;
  droppable?: boolean;
  draggable?: boolean;
  draggableReplacement?: boolean;
  dragging?: boolean;
  selectable?: boolean;
}

export const TextDisplay = memo(function TD(props: TextDisplayProps) {
  return (
    <TextEditor
      value={props.value}
      onChange={() => {}}
      editable={props.selectable ? 'selectable' : 'uneditable'}
      featureFilter={true}
      additionalClasses={props.additionalClasses}
      blankMapper={props.blankMapper}
      droppable={props.droppable}
      draggable={props.draggable}
      draggableReplacement={props.draggableReplacement}
      dragging={props.dragging}
    />
  )
});

type StyleButtonProps = {
  children: ReactNode;
  onClick?: (_: any) => void;
  active: boolean;
  extraClass?: string;
}

function StyleButton(props: StyleButtonProps) {
  const click = props.onClick ? props.onClick : () => {};
  const inherentClass = props.active ? styles['active-style-button'] : styles['style-button'];
  const className = `${inherentClass} ${props.extraClass || ""}`;
  return (
    <button
      className={className}
      onClick={click}
      onMouseDown={event => event.preventDefault()}
    >
      {props.children}
    </button>
  )
}

type BarSectionProps = {
  children: ReactNode;
  extraClass?: string;
}

function BarSection(props: BarSectionProps) {
  const style = `${styles['button-bar-section']} ${props.extraClass || ""}`
  return (
    <div className={style}>
      {props.children}
    </div>
  )
}

type TextStyleSectionProps = {
  features: F.DecorationFeatureFilter;
};

function TextStyleSection(props: TextStyleSectionProps) {
  const editor = S.useEditorContext();
  const activeStyles = editor.activeStyles();
  const click = (style: string) => {
    // editor.applyStyle(style);
    editor.selectionStyleToggle(style, style);
    // if (active(style)) {
    //   editor.removeStyleAtCursor(style);
    // } else {
    //   editor.insertStyleAtCursor(style);
    // }
  }
  const active = (style: string) => {
    return activeStyles.has(style);
  }
  const [fontSizeVisible, setFontSizeVisible] = useState(false);
  const [fontVisible, setFontVisible] = useState(false);
  return (
    <BarSection>
      {F.coalesceFeature(props.features, 'bold') && (
        <StyleButton onClick={() => click("BOLD")} active={active("BOLD")}>
          <b>{g('bold')}</b>
        </StyleButton>
      )}
      {F.coalesceFeature(props.features, 'italic') && (
        <StyleButton onClick={() => click("ITALIC")} active={active("ITALIC")}>
          <em>{g('italic')}</em>
        </StyleButton>
      )}
      {F.coalesceFeature(props.features, 'underline') && (
        <StyleButton onClick={() => click("UNDERLINE")} active={active("UNDERLINE")}>
          <span className={styles['underline-button']}>{g('underline')}</span>
        </StyleButton>
      )}
      {F.coalesceFeature(props.features, 'strikethrough') && (
        <StyleButton onClick={() => click("STRIKETHROUGH")} active={active("STRIKETHROUGH")}>
          <span className={styles['strikethrough-button']}>{g('strikethrough')}</span>
        </StyleButton>
      )}
      <StyleButton onClick={() => setFontSizeVisible(true)} active={fontSizeVisible} extraClass={styles['poppable-section']}>
        <span>
          <span className={styles['small-text']}>A</span>
          <span className={styles['large-text']}>A</span>
        </span>
        {fontSizeVisible ? (
          <FontSizePicker
            close={() => setFontSizeVisible(false)}
            onChange={n => editor.selectionStyleToggle(fontSizeStyleId(n), "FONT_SIZE")}
          />
        ) : null}
      </StyleButton>
      <StyleButton onClick={() => setFontVisible(true)} active={fontVisible} extraClass={styles['poppable-section']}>
        <span>Font</span>
        {fontVisible ? (
          <FontPicker
            close={() => setFontVisible(false)}
            onChange={font => editor.selectionStyleToggle(fontStyleId(font), "FONT")}
          />
        ) : null}
      </StyleButton>
    </BarSection>
  );
}

type ColorPopupVisibility = 'text' | 'highlight' | 'none';

type ColorSectionProps = {
  features: F.ColorFeatureFilter;
};

function ColorSection(props: ColorSectionProps) {
  const poppable = styles['poppable-section'];
  const letterText = g('exampleLetter');
  const [textVisible, setTextVisible] = useState(false);
  const [highlightVisible, setHighlightVisible] = useState(false);
  return (
    <BarSection>
      {F.coalesceFeature(props.features, 'text') && (
        <StyleButton
          active={textVisible}
          onClick={() => setTextVisible(true)}
          extraClass={poppable}
        >
          <span className={styles['text-color-button']}>{letterText}</span>
          <ColorPopup
            visible={textVisible}
            close={() => setTextVisible(false)}
            effect={"TEXT_COLOR"}
          />
        </StyleButton>
      )}
      {F.coalesceFeature(props.features, 'highlight') && (
        <StyleButton
          active={highlightVisible}
          onClick={() => setHighlightVisible(true)}
          extraClass={poppable}
        >
          <span className={styles['highlight-button']}>{letterText}</span>
          <ColorPopup
            visible={highlightVisible}
            close={() => setHighlightVisible(false)}
            effect={'HIGHLIGHT_COLOR'}
          />
        </StyleButton>
      )}
    </BarSection>
  )
}

function colorEffectModifier(effect: ColorEffect): string {
  return effect.toUpperCase();
}

function colorEffectStyleName(effect: ColorEffect, colorName: string): string {
  return `${colorName}-${colorEffectModifier(effect)}`
}

function styleNameToColor(effect: ColorEffect, raw: string): string {
  return raw.replaceAll(colorEffectModifier(effect), "").replaceAll("-", "");
}

type ColorPopupProps = {
  visible: boolean;
  close: () => void;
  effect: ColorEffect;
};

function ColorPopup(props: ColorPopupProps) {
  const editor = S.useEditorContext();
  const ref = useRef<HTMLDivElement>(null);
  const style = styles[props.visible ? 'color-popup' : 'hidden-color-popup'];
  const id = `color-picker-${props.effect}`;
  const [selected, setSelected] = useState<string | undefined>(undefined);
  useClosable(ref, () => props.close());
  const onChange = (color: Color) => {
    editor.selectionStyleToggle(colorId(color, props.effect), props.effect)
  };
  return (
    <div
      id={id}
      ref={ref}
      className={style}
      onClick={event => event.stopPropagation()}
      onMouseDown={event => event.preventDefault()}
    >
      <ColorPicker selected={selected} onChange={onChange}/>
    </div>
  )
}

type LinkSectionProps = {
  features: F.InformationFeatureFilter;
};

function LinkSection(props: LinkSectionProps) {
  const editor = S.useEditorContext();
  const createLink = () => {
    editor.createLink();
  };
  const active = false;
  return (
    <BarSection>
      {F.coalesceFeature(props.features, 'link') && (
        <StyleButton active={active} onClick={createLink}>
          <BiLink/>
        </StyleButton>
      )}
    </BarSection>
  )
}

type SymbolButtonProps = {
  blanksOnly: boolean;
};

function SymbolButton(props: SymbolButtonProps) {
  const [visible, setVisible] = useState(false);
  const onButtonClick = () => setVisible(!visible);
  const ref = useRef(null);
  useClosable(ref, () => setVisible(false));
  return (
    <StyleButton
      onClick={onButtonClick}
      active={false}
      extraClass={styles['symbol-dropdown-wrapper']}
    >
      {"\u00a3"}
      {visible ? (
        <div className={styles['symbol-dropdown']} ref={ref}>
          <SymbolPicker blanksOnly={props.blanksOnly}/>
        </div>
      ) : null}
    </StyleButton>
  );
}

function MathButton() {
  const editor = S.useEditorContext();
  const onClick = () => {
    // editor.setMetadataType('MATH');
    // const mf = S.TextContent('MATH');
    // editor.appendActiveFragment(mf);
    editor.initializeMathContent();
  }
  const active = false;
  return (
    <StyleButton onClick={onClick} active={active}>
      {"\u03a3"}
    </StyleButton>
  );
}

function CodeButton() {
  const [visible, setVisible] = useState(false);
  const onButtonClick = () => setVisible(!visible);
  const ref = useRef(null);
  useClosable(ref, () => setVisible(false));
  return (
    <StyleButton
      onClick={onButtonClick}
      active={false}
      extraClass={styles['code-dropdown-wrapper']}
    >
      <code>{"{}"}</code>
      {visible ? (
        <div className={styles['code-dropdown']} ref={ref}>
          <LanguagePicker close={() => setVisible(false)}/>
        </div>
      ) : null}
    </StyleButton>
  )
}

type SymbolicSectionProps = {
  features: F.SymbolicFeatureFilter;
  blanksOnly: boolean;
}

function SymbolicSection(props: SymbolicSectionProps) {
  return (
    <BarSection>
      {F.coalesceFeature(props.features, 'symbol') && <SymbolButton blanksOnly={props.blanksOnly}/>}
      {F.coalesceFeature(props.features, 'math') && <MathButton/>}
      {F.coalesceFeature(props.features, 'code') && <CodeButton/>}
    </BarSection>
  );
}

function FillInTheBlankSection() {
  const editor = S.useEditorContext();
  return (
    <BarSection>
      <StyleButton active={false} onClick={() => editor.initializeBlank()}>
        <span className={styles['blank-button']}>?</span>
      </StyleButton>
    </BarSection>
  )
}

type ButtonBarProps = {
  features: F.GeneralFeatureFilter;
  blanksOnly: boolean;
};

function ButtonBar(props: ButtonBarProps) {
  const decoration = F.featureSection(props.features, 'decoration') as F.DecorationFeatureFilter;
  const color = F.featureSection(props.features, 'color') as F.ColorFeatureFilter;
  const information = F.featureSection(props.features, 'information') as F.InformationFeatureFilter;
  const symbolic = F.featureSection(props.features, 'symbolic') as F.SymbolicFeatureFilter;
  const blanks = F.featureSection(props.features, 'blanks') as boolean;
  return (
    <div className={styles['button-bar']}>
      <div className={styles['main-button-bar']}>
        {blanks && <FillInTheBlankSection/>}
        {decoration && <TextStyleSection features={decoration}/>}
        {color && <ColorSection features={color}/>}
        {information && <LinkSection features={information}/>}
        {symbolic && <SymbolicSection features={symbolic} blanksOnly={props.blanksOnly}/>}
      </div>
    </div>
  )
}

type MetadataEditorProps = {
  metadataType: S.MetadataType;
  editable: EditState;
}

function MetadataEditor(props: MetadataEditorProps) {
  switch (props.metadataType) {
    case 'LINK': return <LinkMetadataEditor/>;
    case 'MATH': return <MathMetadataEditor/>
    case 'BLANK': return <BlankMetadataEditor isMath={false} editable={props.editable}/>;
    case 'BLANK_MATH': return <BlankMetadataEditor isMath={true} editable={props.editable}/>;
    case 'NONE': return null;
    default: return null;
  }
}
