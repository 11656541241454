import { Content, nonEmpty } from "../TextEditor";
import { QuizQuestion } from "./quiz";
import * as P from "./prompt";
import * as QS from "../QuestionSettings";
import * as E from "../TextEditor";
import {
  SelectableAnswer, WriteInAnswer, functionalityByType, functionalityByResponse, OrderingAnswer
} from "../questionTypes";

export type MultipleChoiceResponse = {
  selection: string | null;
};

export type MultiSelectResponse = {
  selections: Set<string>;
};

export type WriteInResponse = {
  answers: WriteInAnswer[];
};

export type TrueFalseResponse = {
  option: boolean | null;
};

export type FillInTheBlankResponse = {
  body: E.Content;
};

export type OrderingResponse = {
  order: OrderingAnswer[]
}

export function writeInInputs(prompt: P.WriteInPrompt, settings: QS.WriteInModel): number {
  return settings.requiredCount === null ? prompt.inputs : settings.requiredCount;
}

export type Response = MultipleChoiceResponse |
                       MultiSelectResponse |
                       WriteInResponse |
                       TrueFalseResponse |
                       FillInTheBlankResponse |
                       OrderingResponse;

export function isMultipleChoiceResponse(response: Response): response is MultipleChoiceResponse {
  return (response as MultipleChoiceResponse).selection !== undefined;
}

export function isMultiSelectResponse(response: Response): response is MultiSelectResponse {
  return (response as MultiSelectResponse).selections !== undefined;
}

export function isWriteInResponse(response: Response): response is WriteInResponse {
  return (response as WriteInResponse).answers !== undefined;
}

export function isTrueFalseResponse(response: Response): response is TrueFalseResponse {
  return (response as TrueFalseResponse).option !== undefined;
}

export function isFillInTheBlankResponse(response: Response): response is FillInTheBlankResponse {
  return (response as FillInTheBlankResponse).body !== undefined;
}

export function isOrderingResponse(response: Response): response is OrderingResponse {
  return (response as OrderingResponse).order !== undefined;
}

export function initialMultipleChoiceResponse(): MultipleChoiceResponse {
  return {
    selection: null
  };
}

export function initialMultiSelectResponse(): MultiSelectResponse {
  return {
    selections: new Set()
  };
}

export function initialWriteInResponse(prompt: P.WriteInPrompt, settings: QS.WriteInModel): WriteInResponse {
  return {
    answers: new Array(writeInInputs(prompt, settings)).fill(null).map((_, index) => {
      return {
        content: E.constructContent(),
        position: index
      };
    })
  };
}

export function initialTrueFalseResponse(): TrueFalseResponse {
  return {
    option: null
  };
}

export function initialFillInTheBlankResponse(prompt: P.FillInTheBlankPrompt): FillInTheBlankResponse {
  return {
    body: prompt.body
  };
}

export function initialOrderingResponse(prompt: P.OrderingPrompt): OrderingResponse {
  return {
    order: prompt.order
  }
}

export function writeInInitialResponse(question: QuizQuestion): Response {
  return initialWriteInResponse(question.body as P.WriteInPrompt, question.settings as QS.WriteInModel);
}

export function fillInTheBlankInitialResponse(question: QuizQuestion): Response {
  return initialFillInTheBlankResponse(question.body as P.FillInTheBlankPrompt);
}

export function orderingInitialResponse(question: QuizQuestion): OrderingResponse {
  return initialOrderingResponse(question.body as P.OrderingPrompt);
}

export function initializeResponses(questions: QuizQuestion[]): Map<number, Response> {
  return questions.reduce((responses, question) => {
    responses.set(question.index, functionalityByType(question.questionType).initialResponse(question));
    return responses;
  }, new Map<number, Response>());
}

export function initializeResponsesWithPriorProgress(
  questions: QuizQuestion[],
  prior: Map<number, Response>
): Map<number, Response> {
  const basic = initializeResponses(questions);
  return questions.reduce((responses, question) => {
    if (prior.has(question.index)) {
      responses.set(question.index, prior.get(question.index)!);
    }
    return responses;
  }, basic);
}

export function responsesNotAttempted(responses: Map<number, Response>): number[] {
  return Array.from(responses.entries()).filter(pair => {
    const index = pair[0];
    const response = pair[1];
    return functionalityByResponse(response).responseNotAttempted(response);
  })
  .map(pair => pair[0]);
}

export function multipleChoiceNotAttempted(response: Response): boolean {
  if (isMultipleChoiceResponse(response)) {
    return response.selection === null;
  } else {
    return false;
  }
}

export function trueFalseNotAttempted(response: Response): boolean {
  if (isTrueFalseResponse(response)) {
    return response.option === null;
  } else {
    return false;
  }
}

export function writeInNotAttempted(response: Response): boolean {
  if (isWriteInResponse(response)) {
    return response.answers.reduce((accumulator, answer) => {
      return accumulator || !nonEmpty(answer.content);
    }, false as boolean);
  } else {
    return false;
  }
}

export function fillInTheBlankNotAttempted(response: Response): boolean {
  if (isFillInTheBlankResponse(response)) {
    return response.body.fragments.reduce((accumulator, fragment) => {
      if (E.isAnyBlank(fragment)) {
        return accumulator || !E.nonEmptyBlank(fragment);
      } else {
        return accumulator;
      }
    }, false as boolean);
  } else {
    return false;
  }
}

export function multiSelectNotAttempted(response: Response): boolean {
  if (isMultiSelectResponse(response)) {
    return response.selections.size === 0;
  } else {
    return false;
  }
}
