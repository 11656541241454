import {
  Ref, RefObject, useEffect, useState, useRef, ReactNode, ReactElement
} from "react";
import { v4 } from "uuid";

type MouseEventEffect = (_: MouseEvent) => void;

export type Shift = {
  x: number;
  y: number;
};

export function initialShift(): Shift {
  return {
    x: 0,
    y: 0
  };
}

export function onDragTemplate(
  shifts: Shift,
  wrapperRef: RefObject<HTMLDivElement>,
  dragRef: RefObject<HTMLDivElement>,
  dragging: boolean,
  setDraggedYCenter: (_: number) => void
): MouseEventEffect {
  return (event: MouseEvent) => {
    if (event && dragging && wrapperRef?.current && dragRef?.current) {
      const dragRect = dragRef!.current!.getBoundingClientRect();
      const element_width = dragRect.width;
      const element_height = dragRect.height;
      const x = event.clientX;
      const y = event.clientY;
      const element_x = `${x - element_width / 2}px`;
      const element_y = `${y - element_height / 2}px`;
      wrapperRef!.current!.style.left = element_x;
      wrapperRef!.current!.style.top = element_y;
      const rect = wrapperRef!.current!.getBoundingClientRect();
      const y_center = rect.y + rect.height / 2;
      setDraggedYCenter(y_center);
    }
  }
}

export function onGrabTemplate(
  wrapperRef: RefObject<HTMLDivElement>,
  dragRef: RefObject<HTMLDivElement>,
  width: number,
  setShifts: (_: Shift) => void,
  setDragged: () => void,
  setBlankAreaHeight: (_: number) => void,
  withinDragElement: (_: MouseEvent) => boolean,
  setWidth: (_: number) => void
): MouseEventEffect {
  const wrapperRect = wrapperRef!.current!.getBoundingClientRect();
  const dragRect = dragRef!.current!.getBoundingClientRect();
  const elementx = wrapperRect.x;
  const elementy = wrapperRect.y;
  const height = wrapperRect.height;
  return (event: MouseEvent) => {
    if (event && withinDragElement(event)) {
      setWidth(Math.max(width, wrapperRect.width));
      wrapperRef!.current!.style.position = 'fixed';
      const eventx = event.clientX;
      const eventy = event.clientY;
      const shiftx = eventx - elementx;
      const shifty = eventy - elementy;
      const x = eventx - dragRect.width / 2;
      const y = eventy - dragRect.height / 2;
      setShifts({x: shiftx, y: shifty});
      wrapperRef!.current!.style.left = `${x}px`; //`${eventx - shiftx}px`;
      wrapperRef!.current!.style.top = `${y}px`; //`${eventy - shifty}px`;
      setDragged();
      setBlankAreaHeight(wrapperRect.height);
    }
  }
}

export function withinDragElementTemplate(dragRef: RefObject<HTMLDivElement>): ((_: MouseEvent) => boolean) {
  return (event: MouseEvent) => {
    return Boolean(event?.target && dragRef?.current?.contains(event.target as Node));
  };
}

export function mouseUpTemplate(
  wrapperRef: RefObject<HTMLDivElement>,
  dragRef: RefObject<HTMLDivElement>,
  setShifts: (_: Shift) => void,
  yieldDragged: DragYielder,
  dragging: boolean
): MouseEventEffect {
  return (event: MouseEvent) => {
    if (dragging && event) {
      yieldDragged(event, wrapperRef);
      setShifts(initialShift())
      if (wrapperRef?.current) {
        wrapperRef.current.style.position = 'static';
        wrapperRef.current.style.left = "inherit";
        wrapperRef.current.style.top = "inherit";
      }
      if (dragRef?.current) {
        dragRef.current.style.left = "inherit";
        dragRef.current.style.top = "inherit";
      }
    }
  } 
}

export function useDragEffect(
  dragging: boolean,
  yieldDragged: DragYielder,
  setShifts: (_: Shift) => void,
  wrapperRef: RefObject<HTMLDivElement>,
  setWidth: (_: number) => void,
  setBlankAreaHeight: (_: number) => void,
  setDragged: () => void,
  setDraggedYCenter: (_: number) => void,
  width: number,
  shifts: Shift,
  dragRef: RefObject<HTMLDivElement>
) {
  const [onDrag, setOnDrag] = useState<MouseEventEffect | null>(null);
  const [onGrab, setOnGrab] = useState<MouseEventEffect | null>(null);
  const [mouseUp, setMouseUp] = useState<MouseEventEffect | null>(null);
  useEffect(() => {
    const withinDragElement = withinDragElementTemplate(dragRef);
    clearDefaultDragging(wrapperRef);
    clearDefaultDragging(dragRef);
    const _onGrab = onGrabTemplate(wrapperRef, dragRef, width, setShifts, setDragged, setBlankAreaHeight, withinDragElement, setWidth);
    const _mouseUp = mouseUpTemplate(wrapperRef, dragRef, setShifts, yieldDragged, dragging);
    const _onDrag = onDragTemplate(shifts, wrapperRef, dragRef, dragging, setDraggedYCenter);
    updateListener(_onGrab, onGrab, "mousedown", setOnGrab);
    updateListener(_onDrag, onDrag, "mousemove", setOnDrag);
    updateListener(_mouseUp, mouseUp, "mouseup", setMouseUp);
  }, [dragging]);
}

function updateListener(
  listener: MouseEventEffect,
  oldListener: MouseEventEffect | null,
  key: string,
  setter: (_: MouseEventEffect | null) => void
) {
  if (oldListener !== null) {
    document.removeEventListener(key, oldListener as EventListener)
  }
  document.addEventListener(key, listener as EventListener);
  setter(listener);
}

function clearDefaultDragging(reference: RefObject<HTMLElement> | null) {
  if (reference?.current) {
    reference.current.ondrag = () => {
      return false;
    };
    reference.current.ondragstart = () => {
      return false;
    };
  }
}

type ElementDeterminator = (
  className: string,
  wrapperRef: RefObject<HTMLDivElement>,
  style: Record<string, string|undefined>,
  dragRef: RefObject<HTMLDivElement>,
  dragClass: string
) => ReactElement;

type ElementPlacer = (
  wrapperRef: RefObject<HTMLDivElement>
) => void;

type DragYielder = (
  event: MouseEvent,
  wrapperRef: RefObject<HTMLDivElement>
) => void;

export type ExternalDraggableItemProps = {
  dragging: boolean,
  setDragged: () => void;
  yieldDragged: DragYielder;
  setBlankAreaHeight: (_: number) => void;
  draggedYCenter: number | null;
  setDraggedYCenter: (_: number) => void;
  extraClasses?: string;
  dragClass: string;
  wrapperClass: string;
}

type DraggableItemProps = ExternalDraggableItemProps & {
  element: ElementDeterminator;
  place: ElementPlacer;
};

export function DraggableItem(props: DraggableItemProps) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const dragRef = useRef<HTMLDivElement>(null);
  const [shifts, setShifts] = useState<Shift>(initialShift());
  const [width, setWidth] = useState<number>(0);
  const className = `${props.extraClasses || ""} ${props.wrapperClass}`;
  const style = props.dragging ? {
    width: `${width}px`
  } : {};
  useDragEffect(
    props.dragging,
    props.yieldDragged,
    setShifts,
    wrapperRef,
    setWidth,
    props.setBlankAreaHeight,
    props.setDragged,
    props.setDraggedYCenter,
    width,
    shifts,
    dragRef
  );
  useEffect(() => {
    props.place(wrapperRef);
  }, [props.draggedYCenter]);
  return props.element(className, wrapperRef, style, dragRef, props.dragClass);
}