import * as S from "./editorState";
import * as D from "./documentOperations";
import { v4 } from "uuid";
import * as K from "keycode";

export default class EditorContextValue {

  editorState: S.EditorState;
  setEditorState: (_: S.EditorState, __: boolean) => void;
  contentCache: Map<string, S.TextContent>;
  setMetadataType: (_: S.MetadataType) => void;
  editorId: string;

  constructor(
    editorState: S.EditorState,
    setEditorState: (_: S.EditorState, __: boolean) => void,
    contentCache: Map<string, S.TextContent>,
    setMetadataType: (_: S.MetadataType) => void
  ) {
    this.editorState = editorState;
    this.setEditorState = setEditorState;
    this.contentCache = contentCache;
    this.setMetadataType = setMetadataType;
    this.editorId = v4();
  }

  id(): string {
    return this.editorId;
  }

  updateEditorState(newState: S.EditorState, refresh?: boolean) {
    this.setEditorState({ ...newState }, refresh === undefined ? true : refresh);
  }

  updateSelection(newSelection: S.SelectionDetermination) {
    this.updateEditorState({
      ...this.editorState,
      selection: newSelection
    })
  }

  updateSelectionWithDocument(selection: S.SelectionDetermination) {
    this.setEditorState({
      ...this.editorState,
      selection
    }, true);
    // D.setSelection(selection);
    D.setFutureSelection(selection);
  }

  setAnchor(x: number, y: number) {
    const locality = this.destinationCharacterFromCoordinates(x, y);
    if (locality) {
      const clicked = this.fragments().find(f => f.fragmentId === locality.fragmentId);
      const textClicked = !clicked || S.isTextContent(clicked);
      const selection = S.SelectionDetermination(locality.fragmentId, locality.index);
      this.updateSelectionWithDocument(selection);
      if (this.activeComponentId() !== undefined && textClicked) {
        this.setActiveComponent(undefined);
      }
    } else {
      const first = this.firstTextFragment();
      if (first) {
        const locality = { fragmentId: first.fragmentId, index: 0 };
        const selection = { anchor: locality, end: locality, collapsed: true };
        this.updateSelectionWithDocument(selection);
      }
    }
  }

  appendFragments(fragments: S.EditorFragment[]) {
    this.updateEditorState({
      ...this.editorState,
      content: {
        ...this.editorState.content,
        fragments: this.editorState.content.fragments.concat(fragments)
      }
    });
  }

  activeFragmentId(): string | undefined {
    return this.editorState.selection.end.fragmentId;
  }

  activeFragment(): S.EditorFragment | null {
    return this.editorState.content.fragments.find(fragment => {
      return this.activeFragmentId() === fragment.fragmentId
    }) || null;
  }

  activeFragmentIndex(): number | null {
    const fid = this.activeFragmentId();
    const fs = this.fragments();
    if (fid === null) {
      return null;
    }
    let index = 0;
    while (index < fs.length && !(fs[index].fragmentId === fid)) {
      index++;
    }
    return index;
  }

  activeComponentIndex(): number | null {
    const id = this.activeComponentId();
    if (id) {
      return this.fragments().findIndex(f => f.fragmentId === id);
    }
    return null;
  }

  fragments(): S.EditorFragment[] {
    return this.editorState.content.fragments;
  }

  fragmentById(id: string): S.EditorFragment | null {
    return this.fragments().find(f => f.fragmentId === id) || null;
  }

  activeComponentFragment(): S.EditorFragment | null {
    const componentId = this.activeComponentId();
    return componentId !== undefined ? this.fragmentById(componentId) : null;
  }

  nextFragment(): S.EditorFragment | null {
    return this.adjacentFragment(1);
  }

  previousFragment(): S.EditorFragment | null {
    return this.adjacentFragment(-1);
  }

  adjacentFragment(direction: number): S.EditorFragment | null {
    const activeIndex = this.activeFragmentIndex();
    if (activeIndex === null) {
      return null;
    } else {
      for (let i = activeIndex + direction; i < this.fragments().length && i >= 0; i += direction) {
        if (S.isTextual(this.fragments()[i])) {
          return this.fragments()[i]
        }
      }
      return null;
    }
  }

  horizontalArrow(key: number, shift: boolean) {
    if (key === K.LEFT_ARROW) {
      if (D.startOffset()) {
        this.movePrevious(shift);
      } else {
        this.horizontalUpdateInFragmentCursorPosition(false, shift);
      }
    } else if (key === K.RIGHT_ARROW) {
      if (D.endOffset()) {
        this.moveNext(shift);
      } else {
        this.horizontalUpdateInFragmentCursorPosition(true, shift);
      }
      return false;
    }
  }

  horizontalUpdateInFragmentCursorPosition(right: boolean, shift: boolean) {
    const index = D.fragmentCursorIndex();
    const fragmentId = this.activeFragmentId();
    const newSelection = S.horizontalUpdate(this.editorState.selection, right, shift);
    this.updateSelectionWithDocument(newSelection);
  }

  componentSpecification(selection: S.SelectionDetermination): [string | undefined, S.MetadataType] {
    if (selection.anchor.fragmentId === selection.end.fragmentId) {
      const fragment = this.fragmentById(selection.anchor.fragmentId);
      if (fragment) {
        if (S.isBlank(fragment)) {
          return [fragment.fragmentId, 'BLANK'];
        }
      }
    }
    return [undefined, 'NONE'];
  }

  movePrevious(shift: boolean) {
    const previous = this.previousFragment();
    if (previous) {
      const length = D.fragmentTextLength(previous.fragmentId);
      const end = S.EditorLocality(previous.fragmentId, Math.max(0, length - 1));
      const selectionDetermination = shift ? {
        ...this.editorState.selection,
        end,
        collapsed: false
      } : {
        end,
        anchor: end,
        collapsed: true
      };
      const [activeComponent, metadataType] = this.componentSpecification(selectionDetermination);
      this.setEditorState({
        ...this.editorState,
        activeComponent,
        selection: selectionDetermination
      }, true);
      this.setMetadataType(metadataType);
      D.setFutureSelection(selectionDetermination);
    }
  }

  moveNext(shift: boolean) {
    const next = this.nextFragment();
    if (next) {
      const anchor = this.selection().anchor;
      const length = D.fragmentTextLength(next.fragmentId);
      const end = S.EditorLocality(next.fragmentId, Math.min(length, 1));
      const selection = shift ? {
        anchor, end, collapsed: false
      } : {
        anchor: end, end, collapsed: true
      };
      const [activeComponent, metadataType] = this.componentSpecification(selection);
      this.setEditorState({
        ...this.editorState,
        activeComponent,
        selection
      }, true);
      this.setMetadataType(metadataType);
      D.setFutureSelection(selection);
    }
  }

  selection(): S.SelectionDetermination {
    return this.editorState.selection;
  }

  activeStyles(): Set<string> {
    const styles = new Set<string>();
    const fragments = this.editorState.content.fragments;
    for (let i = 0; i < fragments.length; i++) {
      const fragment = fragments[i];
      if (S.isStyleBoundary(fragment)) {
        if (fragment.boundaryType === 'END') {
          styles.delete(fragment.styleId);
        } else if (fragment.boundaryType === 'START') {
          styles.add(fragment.styleId);
        }
      }
      if (fragment.fragmentId === this.activeFragmentId()) {
        break;
      }
    }
    return styles;
  }

  updateForId(id: string, value: string) {
    const fragments = this.editorState.content.fragments;
    for (let i = 0; i < fragments.length; i++) {
      if (fragments[i].fragmentId === id && S.isTextContent(fragments[i])) {
        (fragments[i] as S.TextContent).content = value;
      }
    }
    this.updateEditorState({
      ...this.editorState,
      content: {
        ...this.editorState.content,
        fragments
      }
    });
  }

  appendActiveFragment(fragment: S.EditorFragment) {
    this.updateEditorState({
      ...this.editorState,
      content: {
        ...this.editorState.content,
        fragments: this.editorState.content.fragments.concat([fragment])
      },
    });
  }

  replaceFragments(fragments: S.EditorFragment[], refresh?: boolean) {
    this.updateEditorState({
      ...this.editorState,
      content: {
        ...this.editorState.content,
        fragments
      },
    }, refresh);
  }

  resetFragmentsAndSelection(fragments: S.EditorFragment[], selection: S.SelectionDetermination, refresh?: boolean) {
    this.updateEditorState({
      ...this.editorState,
      content: {
        ...this.editorState.content,
        fragments
      },
      selection
    }, refresh);
    D.setFutureSelection(selection);
  }

  insertFragmentsAtCursor(fragments: S.EditorFragment[], focusIndex: number) {
    const texts = D.cursorSplitText();
    if (texts && focusIndex < fragments.length) {
      const focusId = fragments[focusIndex].fragmentId;
      const [priorText, subsequentText] = texts;
      const priorFragment = S.simpleTextContent(priorText);
      const subsequentFragment = S.simpleTextContent(subsequentText);
      const editorFragments = this.expandSelection(priorFragment, fragments, subsequentFragment);
      this.replaceFragments(editorFragments);
    }
  }

  expandSelection(
    priorFragment: S.EditorFragment,
    newFragments: S.EditorFragment[],
    subsequentFragment: S.EditorFragment
  ): S.EditorFragment[] {
    const replacement = [priorFragment].concat(newFragments).concat([subsequentFragment])
    if (this.fragments().length === 1) {
      return replacement;
    }
    return this.editorState.content.fragments.flatMap(fragment => {
      if (D.fragmentIsSelection(fragment.fragmentId)) {
        return replacement;
      } else {
        return [fragment];
      }
    });
  }

  setActiveFragmentNonFocus(x: number, y: number) {
    const locality = this.destinationCharacterFromCoordinates(x, y);
    if (locality) {
      const fragment = this.fragmentById(locality.fragmentId);
      const isBlank = fragment && S.isBlank(fragment);
      const activeComponent = isBlank ? locality.fragmentId : undefined;
      const metadataType = isBlank ? 'BLANK' : 'NONE';
      const selection = S.SelectionDetermination(locality.fragmentId, locality.index);
      const newState = {
        ...this.editorState,
        activeComponent,
        selection
      };
      this.setEditorState({ ...newState }, true);
      this.setMetadataType(metadataType);
    }
  }

  setActiveFragment(activeComponent: string, shift: boolean, end: boolean) {
    // this.setActiveFragmentNonFocus(activeComponent);
    D.focusCorrespondingNode(activeComponent, end);
  }

  focus() {
    const firstId = this.firstText()?.fragmentId;
    if (firstId) {
      this.setActiveFragment(firstId, false, false);
    }
  }

  firstText(): S.EditorFragment | null {
    return this.fragments().find(fragment => S.isTextContent(fragment)) || null;
  }

  selectionAnchorId(): string | null {
    return this.selection().anchor.fragmentId;
  }

  selectionEndId(): string | null {
    return this.selection().end.fragmentId;
  }

  *iterateSelectedFragments(): Generator<S.EditorFragment> {
    let inSelection = false;
    let firstSelectionEndId: string | null = null;
    const aid = this.selectionAnchorId();
    const eid = this.selectionEndId();
    for (let i = 0; i < this.fragments().length; i++) {
      const id = this.fragments()[i].fragmentId;
      if (eid === id || aid === id) {
        if (firstSelectionEndId === null) {
          firstSelectionEndId = id;
        } else if (firstSelectionEndId !== id) {
          firstSelectionEndId = null;
        }
      }
      if (firstSelectionEndId !== null) {
        yield this.fragments()[i];
      }
    }
  }

  heterogenousStyleSelected(styleId: string): boolean {
    for (const fragment of this.iterateSelectedFragments()) {
      if (S.isStyleBoundary(fragment)) {
        if (fragment.styleId === styleId) {
          return true;
        }
      }
    }
    return false
  }

  *iterateTextFragmentsWithStyle(): Generator<S.StyleEnumeratedTextContent> {
    let styles = new Set<string>();
    for (const fragment of this.iterateFragments()) {
      if (S.isStyleBoundary(fragment)) {
        S.toggleStyle(fragment, styles);
      } else if (S.isTextContent(fragment)) {
        yield {
          content: fragment,
          styles: new Set(styles)
        }
      }
    }
  }

  selectionStyleUniformlyActive(styleId: string): boolean {
    const selectedFragments = new Set<string>();
    for (const fragment of this.iterateSelectedFragments()) {
      selectedFragments.add(fragment.fragmentId);
    }
    for (const styledText of this.iterateTextFragmentsWithStyle()) {
      if (selectedFragments.has(styledText.content.fragmentId) && !styledText.styles.has(styleId)) {
        return false
      }
    }
    return true;
  }

  activeElement(): Element | null {
    const id = this.activeFragmentId();
    return id ? (document.getElementById(id) || null) : null;
  }

  destinationUpArrow(): string | null {
    return this.destinationArrow(this.fragments().length - 1, -1, -1, (candidate_y: number, from_y: number) => {
      return candidate_y < from_y;
    });
  }

  destinationDownArrow(): string | null {
    return this.destinationArrow(0, 1, this.fragments().length, (candidate_y: number, from_y: number) => {
      return candidate_y > from_y;
    });
  }

  cursorX(): number | null {
    const selectionX = D.endSelectionCursorCoordinates(this.selection())?.x;
    if (selectionX === 0) {
      const previous = this.previousFragment();
      const bb = previous && D.boundingBox(previous.fragmentId);
      return bb ? bb.x + bb.width : null;
    } else {
      return selectionX || null;
    }
  }

  cursorY(): number | null {
    const selectionY = D.endSelectionCursorCoordinates(this.selection())?.y;
    if (selectionY === 0) {
      throw new Error("cursor y invalid");
    } else {
      return selectionY || null;
    }
  }

  destinationArrow(
    startIndex: number,
    increment: number,
    endIndex: number,
    yComparator: (y1: number, y2: number) => boolean
  ): string | null {
    const fragmentId = this.activeFragmentId();
    if (!fragmentId) {
      return null;
    }
    const activeBox = D.boundingBox(fragmentId);
    const cursorX = this.cursorX();
    const cursorY = this.cursorY();
    let farthestRightId: string | null = null;
    let farthestRightX: number | null = null;
    if (activeBox && cursorX && cursorY) {
      const lineHeight = D.fragmentLineHeightPixels(fragmentId);
      if (lineHeight) {
        const newY = cursorY + increment * lineHeight!;
        const targetElement = document.elementFromPoint(cursorX, newY);
        if (targetElement && targetElement.id && this.validFragment(targetElement.id)) {
          return targetElement.id;
        }
      }

      for (let i = startIndex; i !== endIndex; i += increment) {
        const fragment = this.fragments()[i];
        if (S.isTextContent(fragment)) {
          const fragmentBox = D.boundingBox(fragment.fragmentId);
          if (fragmentBox) {
            const directional = yComparator(fragmentBox.y, activeBox.y);
            const rightExtremity = fragmentBox.x + fragmentBox.width;
            const surroundingX = (fragmentBox.x <= cursorX) && (rightExtremity >= cursorX);
            if (directional && surroundingX) {
              return fragment.fragmentId;
            } else if (directional && (farthestRightX === null || rightExtremity > farthestRightX)) {
              farthestRightId = fragment.fragmentId;
              farthestRightX = rightExtremity;
            }
          }
        }
      }
    }
    return farthestRightId;
  }

  validFragment(id: string): boolean {
    return !!this.fragments().find(f => f.fragmentId === id);
  }

  *iterateForwardsFromActive(): Generator<S.TextualFragment> {
    yield* this.iterateFromActive(0, this.fragments().length, 1);
  }

  *iterateBackwardsFromActive(): Generator<S.TextualFragment> {
    yield* this.iterateFromActive(this.fragments().length - 1, -1, -1);
  }

  *iterateFromActive(start: number, end: number, increment: number): Generator<S.TextualFragment> {
    let reachedCurrent = false;
    for (let i = start; i != end; i += increment) {
      const fragment = this.fragments()[i];
      reachedCurrent = reachedCurrent || fragment.fragmentId === this.activeFragmentId();
      if (reachedCurrent && S.isTextual(fragment)) {
        yield fragment;
      }
    }
  }

  *iterateCharacterBackwardsFromCursor(): Generator<S.CharacterLocality> {
    yield* this.iterateCharacterFromCursor(
      this.iterateBackwardsFromActive(),
      fragment => D.fragmentTextLength(fragment.fragmentId) - 1,
      (_) => -1,
      -1,
      false
    );
  }

  *iterateCharacterForwardsFromCursor(): Generator<S.CharacterLocality> {
    yield* this.iterateCharacterFromCursor(
      this.iterateForwardsFromActive(),
      (_) => 0,
      fragment => D.fragmentTextLength(fragment.fragmentId),
      1,
      true
    );
  }

  *iterateCharacterFromCursor(
    fragmentGenerator: Generator<S.TextualFragment>,
    startIndex: (_: S.TextualFragment) => number,
    endIndex: (_: S.TextualFragment) => number,
    increment: number,
    movingForward: boolean
  ): Generator<S.CharacterLocality> {
    const cursorX = this.cursorX();
    const cursorY = this.cursorY();
    if (cursorX === null || cursorY === null) {
      return;
    }
    let cursorReached = false;
    for (let fragment of fragmentGenerator) {
      const start = startIndex(fragment);
      const end = endIndex(fragment);
      for (let i = startIndex(fragment); i != endIndex(fragment); i += increment) {
        const locality = D.characterGeometry(fragment.fragmentId, i);
        if (locality) {
          const past = D.characterPastCursor(locality.bounding, this.selection());
          cursorReached = cursorReached || past;
          if (cursorReached) {
            yield locality;
          }
        }
      }
    }
  }

  downArrowDestination(): S.CharacterLocality | null {
    return this.verticalArrowDestination(this.iterateCharacterForwardsFromCursor(), true, (current, previous) => {
      return (current.bounding.x <= (previous?.bounding.x || 0)) || false;
    })
  }

  upArrowDestination(): S.CharacterLocality | null {
    return this.verticalArrowDestination(this.iterateCharacterBackwardsFromCursor(), false, (current, previous) => {
      const value = (current.bounding.x >= (previous?.bounding.x || Number.MAX_SAFE_INTEGER)) || false;
      return value;
    })
  }

  verticalArrow(key: number, shift: boolean) {
    const upArrow = key === K.UP_ARROW;
    const destinationContext = upArrow ? this.upArrowDestination() : this.downArrowDestination();
    if (destinationContext !== null) {
      const end = S.EditorLocality(destinationContext.fragmentId, destinationContext.index);
      const anchor = shift ? this.selection().anchor : end;
      const selection: S.SelectionDetermination = { anchor, end, collapsed: !shift };
      const [activeComponent, metadataType] = this.componentSpecification(selection);
      this.setEditorState({
        ...this.editorState,
        selection,
      }, true);
      D.setFutureSelection(selection);
    } else {
      this.focusSnap(upArrow, shift);
    }
  }

  verticalArrowDestination(
    characterGenerator: Generator<S.CharacterLocality>,
    downArrow: boolean,
    snapCondition: (_: S.CharacterLocality, __: S.CharacterLocality | null) => boolean
  ): S.CharacterLocality | null {
    const cursorx = this.cursorX();
    if (!cursorx) {
      return null;
    }
    let snappedx: boolean = false;
    let previous: S.CharacterLocality | null = null;
    let firstSnap: S.CharacterLocality | null = null;
    for (let locality of characterGenerator) {
      const snap = snapCondition(locality, previous);
      if (snap && firstSnap === null) {
        firstSnap = locality;
      }
      if (snappedx) {
        if (snap) {
          if (downArrow) {
            return previous;
          } else {
            return firstSnap;
          }
        }
      }
      snappedx = snappedx || snap;
      if (snappedx) {
        const bounding = locality.bounding;
        const leftx = bounding.x;
        const rightx = bounding.x + bounding.width;
        if (leftx <= cursorx && cursorx <= rightx) {
          const leftdist = Math.abs(leftx - cursorx);
          const rightdist = Math.abs(rightx - cursorx);

          if (leftdist <= rightdist) {
            return locality;
          } else {
            return {
              ...locality,
              index: locality.index + 1
            }
          }
        }
      }
      previous = locality;
    }
    return null;
  }

  focusSnap(upArrow: boolean, shift: boolean) {
    if (upArrow) {
      this.focusStart(shift);
    } else {
      this.focusEnd(shift);
    }
  }

  focusEnd(shift: boolean) {
    const fragment = this.lastTextFragment();
    if (fragment) {
      // D.focusCorrespondingNodePositionally(fragment.fragmentId, 'end');
      // this.updateSelectionWithDocument(S.SelectionDetermin)
      const index = D.fragmentTextLength(fragment.fragmentId)
      const end = { fragmentId: fragment.fragmentId, index };
      const anchor = shift ? this.selectionAnchor() : end;
      const selection = { end, anchor, collapsed: !shift };
      this.updateSelectionWithDocument(selection);
    }
  }

  focusStart(shift: boolean) {
    const fragment = this.firstTextFragment();
    if (fragment) {
      const end = { fragmentId: fragment.fragmentId, index: 0 };
      const anchor = shift ? this.selectionAnchor() : end;
      const selection = { end, anchor, collapsed: !shift };
      this.updateSelectionWithDocument(selection);
      // D.focusCorrespondingNodePositionally(fragment.fragmentId, 'start');
    }
  }

  selectionAnchor(): S.EditorLocality {
    return this.editorState.selection.anchor;
  }

  selectionEnd(): S.EditorLocality {
    return this.editorState.selection.end;
  }

  lastTextFragment(): S.TextContent | null {
    return this.peripheralFragment(this.fragments().length - 1, -1, -1);
  }

  firstTextFragment(): S.TextContent | null {
    return this.peripheralFragment(0, this.fragments().length, 1);
  }

  peripheralFragment(startIndex: number, endIndex: number, increment: number): S.TextContent | null {
    for (let i = startIndex; i != endIndex; i += increment) {
      const fragment = this.fragments()[i];
      if (S.isTextContent(fragment)) {
        return fragment;
      }
    }
    return null;
  }

  setCurrentFragmentText(text: string) {
    const activeFragment = this.activeFragmentId();
    if (activeFragment) {
      D.setFragmentText(activeFragment, text);
    }
  }

  selectAll() {
    const last = this.lastTextFragment();
    const first = this.firstTextFragment();
    if (last && first) {
      const anchor = S.EditorLocality(first.fragmentId, 0);
      const end = S.EditorLocality(last.fragmentId, D.fragmentTextLength(last.fragmentId));
      const selection = { anchor, end, collapsed: false };
      this.updateSelectionWithDocument(selection);
    }
  }

  *iterateFragments(): Generator<S.EditorFragment> {
    for (let i = 0; i < this.fragments().length; i++) {
      yield this.fragments()[i];
    }
  }

  destinationCharacterFromCoordinates(cursorX: number, cursorY: number): S.CharacterLocality | null {
    let yIntersect: S.CharacterLocality | null = null;
    for (const fragment of this.iterateFragments()) {
      if (S.isAnyMath(fragment)) {
        continue;
      }
      const fragmentId = fragment.fragmentId;
      const bb = D.boundingBox(fragmentId);
      if (bb) {
        if (D.intersectBox(bb, cursorX, cursorY)) {
          for (const character of D.iterateFragmentText(fragmentId)) {
            if (D.intersectBox(character.bounding, cursorX, cursorY)) {
              const leftDiff = cursorX - character.bounding.left;
              const rightDiff = character.bounding.right - cursorX;
              if (leftDiff < rightDiff) {
                return character;
              } else {
                return S.nextCharacter(character)
              }
            }
          }
          if (S.isBlank(fragment)) {
            return D.emptyBlankGeometry(fragmentId);
          }
        }
        if (D.yIntersect(bb, cursorY)) {
          for (const character of D.iterateFragmentText(fragmentId)) {
            if (D.yIntersect(character.bounding, cursorY)) {
              yIntersect = S.nextCharacter(character)
            }
          }
        }
      }
    }
    return yIntersect;
  }

  // firstCharacter(): S.CharacterLocality | null {
  //   const first = this.firstTextFragment();
  //   if (first) {
  //     return {
        
  //     }
  //   }
  // }

  dragSelect(latentAnchor: S.CharacterLocality, x: number, y: number) {
    const dc = this.destinationCharacterFromCoordinates(x, y);
    if (dc) {
      const anchor = S.EditorLocality(latentAnchor.fragmentId, latentAnchor.index);
      const end = S.EditorLocality(dc.fragmentId, dc.index);
      const selection = { anchor, end, collapsed: false };
      this.updateSelectionWithDocument(selection);
    }
  }

  anchorSplitText(): [string, string] | null {
    return S.splitText(this.selection().anchor);
  }

  endSplitText(): [string, string] | null {
    return S.splitText(this.selection().end);
  }

  anchorIndex(): number | null {
    return S.fragmentIndex(this.selection().anchor.fragmentId, this.fragments());
  }

  endIndex(): number | null {
    return S.fragmentIndex(this.selection().end.fragmentId, this.fragments());
  }

  selectionStyleToggle(styleId: string, category: string) {
    const fs = [...this.fragments()];
    const anchorIndex = this.anchorIndex();
    const endIndex = this.endIndex();
    const entiretyStyled = S.entiretyStyled(styleId, this.selection(), this.fragments());
    if (anchorIndex === null || endIndex === null) {
      return;
    }
    if (anchorIndex === endIndex) {
      const insertionResult = S.insertStyleSingleFragment(
        this.selection().anchor.index,
        this.selection().end.index,
        this.selection().anchor.fragmentId,
        this.selection().collapsed,
        styleId,
        category,
        entiretyStyled
      );
      if (insertionResult !== null) {
        fs.splice(anchorIndex, 1, ...insertionResult.fragments);
        this.resetFragmentsAndSelection(fs, insertionResult.selection, false);
      }
    } else {
      const cleared = S.interFragmentSelectionStyle(this.selection(), this.fragments(), styleId, category);
      this.resetFragmentsAndSelection(cleared.fragments, cleared.selection, false);
    }
  }

  deleteSelection(blanksOnly: boolean, character?: string) {
    const outcome = this.deleteSelectionResult(blanksOnly, character);
    if (outcome !== null) {
      const [selection, fragments] = outcome;
      if (blanksOnly) {
        if (fragments && !S.allInABlank(fragments)) {
          return;
        }
        if (this.selection().collapsed && this.selection().end.index === 0) {
          return;
        }
      }
      if (fragments === null) {
        this.updateSelectionWithDocument(selection);
      } else {
        this.resetFragmentsAndSelection(fragments, selection);
      }
    }
  }

  deleteSelectionResult(blanksOnly: boolean, character?: string): null | [S.SelectionDetermination, S.EditorFragment[] | null] {
    const c = character || "";
    let reachedPrevious: boolean = false;
    if (this.selection().collapsed) {
      let { fragmentId, index } = this.selection().end;
      if (index === 0) {
        const previous = this.previousFragment();
        if (!previous) {
          return null;
        } else {
          reachedPrevious = true;
        }
        fragmentId = previous.fragmentId;
        index = D.fragmentTextLength(fragmentId);
      }
      const text = D.fragmentContents(fragmentId);
      if (text) {
        const edited = `${text.slice(0, index - 1)}${text.slice(index)}`;
        if (!(blanksOnly && reachedPrevious)) {
          D.setFragmentText(fragmentId, edited);
        }
        const newLocality = { fragmentId, index: index - 1 };
        const selection = { end: newLocality, anchor: newLocality, collapsed: true };
        return [selection, null];
        // this.updateSelectionWithDocument(selection);
      }
    } else {
      const ai = this.anchorIndex();
      const ei = this.endIndex();
      if (ai === null || ei === null) {
        return null;
      }
      if (ai === ei) {
        const { fragmentId, index: a_text_index } = this.selection().anchor;
        const { index: e_text_index } = this.selection().end;
        const firstIndex = Math.min(a_text_index, e_text_index);
        const lastIndex = Math.max(a_text_index, e_text_index);
        const text = D.fragmentContents(fragmentId);
        if (text) {
          const edited = `${text.slice(0, firstIndex)}${c}${text.slice(lastIndex)}`;
          D.setFragmentText(fragmentId, edited);
          const newLocality = { fragmentId, index: firstIndex + c.length };
          const selection = { end: newLocality, anchor: newLocality, collapsed: true };
          return [selection, null];
          // this.updateSelectionWithDocument(selection);
        }
      } else {
        const { fragmentId: anchorFragmentId, index: anchorTextIndex } = this.selection().anchor;
        const { fragmentId: endFragmentId, index: endTextIndex } = this.selection().end;
        const anchorFirst = S.anchorFirst(this.selection(), this.fragments());
        const firstFragmentIndex = anchorFirst ? ai : ei;
        const lastFragmentIndex = anchorFirst ? ei : ai;
        const firstTextIndex = anchorFirst ? anchorTextIndex : endTextIndex;
        const lastTextIndex = anchorFirst ? endTextIndex : anchorTextIndex;
        const firstFragmentId = anchorFirst ? anchorFragmentId : endFragmentId;
        const lastFragmentId = anchorFirst ? endFragmentId : anchorFragmentId;
        const firstText = D.fragmentContents(firstFragmentId);
        const lastText = D.fragmentContents(lastFragmentId);
        if (firstText && lastText) {
          const firstEditedText = `${firstText.slice(0, firstTextIndex)}${c}`;
          const lastEditedText = lastText.slice(lastTextIndex, lastText.length);
          D.setFragmentText(firstFragmentId, firstEditedText);
          D.setFragmentText(lastFragmentId, lastEditedText);
          const fragments = S.exciseFragmentsBetween(this.fragments(), firstFragmentIndex, lastFragmentIndex);
          const newLocality = { fragmentId: firstFragmentId, index: firstTextIndex + c.length };
          const selection = { end: newLocality, anchor: newLocality, collapsed: true };
          return [selection, fragments];
          // this.resetFragmentsAndSelection(fragments, selection);
        }
      }
    }
    return null;
  }

  insertCharacter(blanksOnly: boolean, c: string) {
    if (!this.selection().collapsed) {
      this.deleteSelection(blanksOnly, c);
    } else {
      const { fragmentId, index } = this.selection().end;
      const text = D.fragmentContents(fragmentId);
      if (text !== null) {
        const edited = `${text.substring(0, index)}${c}${text.substring(index, text.length)}`;
        D.setFragmentText(fragmentId, edited);
        const newLocality = { fragmentId, index: index + 1 };
        const selection = { end: newLocality, anchor: newLocality, collapsed: true };
        this.updateSelectionWithDocument(selection)
      }
    }
  }

  fragmentIndex(fragmentId: string): number | undefined {
    return this.fragments().findIndex(f => f.fragmentId === fragmentId);
  }

  initializeMathContent() {
    const active = this.activeComponent();
    const index = this.activeComponentIndex();
    if (active && index !== null) {
      const fragments = this.fragments();
      if (S.isMath(active)) {
        const fragment = S.simpleTextContent(active.mathContent);
        fragments.splice(index, 1, fragment);
        const [compacted, locality] = S.compactAdjacentText(fragments, this.selection().end);
        this.replaceFragments(compacted);
        this.setMetadataType('NONE');
      } else if (S.isBlankMath(active)) {
        const fragment = S.mathBlankToBlank(active);
        fragments.splice(index, 1, fragment);
        this.replaceFragments(fragments);
        this.setMetadataType('BLANK');
      }
    } else {
      this.initializeComponent('MATH', s => S.mathContent(s));
    }
  }

  createLink() {
    this.initializeComponent('LINK', s => S.linkContent(s));
  }

  initializeBlank() {
    const activeFragment = this.activeFragment();
    const activeComponent = this.activeComponent();
    if (activeComponent && S.isBlankMath(activeComponent)) {
      const index = this.fragmentIndex(activeComponent.fragmentId);
      if (index) {
        const deblanked = S.mathContent(activeComponent.blankContent);
        const fragments = this.fragments();
        fragments.splice(index, 1, deblanked);
        this.replaceFragments(fragments);
        this.setActiveComponent(deblanked.fragmentId, 'MATH')
      }
    } else if (activeComponent && S.isMath(activeComponent)) {
        const index = this.activeComponentIndex();
        const fragments = this.fragments();
        if (index && S.isMath(fragments[index])) {
          const newFragment = S.blankMath(fragments[index] as S.MathContent);
          fragments.splice(index, 1, newFragment);
          this.updateEditorState({
            ...this.editorState,
            content: {
              ...this.editorState.content,
              fragments,
            },
            activeComponent: newFragment.fragmentId
          })
          this.setMetadataType('BLANK_MATH')
        }
    } else if (activeComponent && S.isBlank(activeComponent)) {
      const [newFragments, locality] = S.deBlank(activeComponent.fragmentId, this.fragments(), this.selection().end);
      this.resetFragmentsAndSelection(newFragments, { anchor: locality, end: locality, collapsed: true });
    } else {
      this.initializeComponent('BLANK', s => S.blankContent(s));
    }
  }

  initializeComponent(metadataType: S.MetadataType, construct: (_: string) => S.EditorFragment) {
    if (this.anchorIndex() === this.endIndex()) {
      const { fragmentId, index: endTextIndex } = this.selection().end;
      const { index: anchorTextIndex } = this.selection().anchor;
      const endIndex = this.endIndex();
      const text = D.fragmentContents(fragmentId);
      if (text !== null && endIndex !== null) {
        const firstTextIndex = Math.min(endTextIndex, anchorTextIndex);
        const lastTextIndex = Math.max(endTextIndex, anchorTextIndex);
        const first = text.substring(0, firstTextIndex);
        const componentText = text.substring(firstTextIndex, lastTextIndex);
        const second = text.substring(lastTextIndex);
        const firstFragment = S.simpleTextContent(first);
        const secondFragment = S.simpleTextContent(second);
        const component = construct(componentText);
        const fragments = [...this.fragments()];
        fragments.splice(endIndex, 1, firstFragment, component, secondFragment);
        this.setMetadataType(metadataType);
        let selection = this.selection();
        if (metadataType === 'BLANK') {
          const blank = component as S.BlankComponent;
          const locality = { fragmentId: blank.fragmentId, index: blank.blankContent.length };
          selection = { anchor: locality, end: locality, collapsed: true };
        }
        this.updateEditorState({
          ...this.editorState,
          activeComponent: component.fragmentId,
          content: {
            ...this.editorState.content,
            fragments
          },
          selection
        }, false)
        D.setFutureSelection(selection);
      }
    }
  }

  activeComponentId(): string | undefined {
    return this.editorState.activeComponent;
  }

  activeComponent(): S.EditorFragment | undefined {
    return this.fragments().find(f => f.fragmentId === this.activeComponentId());
  }

  updateMathContents(newContents: string) {
    this.updateComponent(f => S.isMath(f), (fragments: S.EditorFragment[], index: number) => {
      (fragments[index] as S.MathContent).mathContent = newContents;
    })
  }

  updateBlank(b: S.BlankComponent, isMath: boolean) {
    this.updateComponent(f => isMath ? S.isBlankMath(f) : S.isBlank(f), (fragments: S.EditorFragment[], index: number) => {
      fragments[index] = isMath ? S.blankToMathBlank(b) : b;
    });
  }

  updateLinkDisplayText(newContents: string) {
    this.updateComponent(f => S.isLinkComponent(f), (fragments, index) => {
      (fragments[index] as S.LinkComponent).displayText = newContents;
    });
  }

  updateLink(newContents: string) {
    this.updateComponent(f => S.isLinkComponent(f), (fragments, index) => {
      (fragments[index] as S.LinkComponent).link = newContents;
    });
  }

  updateComponent(match: (_: S.EditorFragment) => boolean, update: (_: S.EditorFragment[], __: number) => void) {
    const active = this.activeComponentId();
    if (active) {
      const fragments = [...this.fragments()];
      const index = fragments.findIndex(fragment => fragment.fragmentId === active);
      if (index && match(fragments[index])) {
        update(fragments, index);
        this.updateEditorState({
          ...this.editorState,
          content: {
            ...this.editorState.content,
            fragments
          }
        })
      }
    }
  }

  setActiveComponent(id: string | undefined, mt?: S.MetadataType) {
    this.updateEditorState({
      ...this.editorState,
      activeComponent: id
    });
    this.setMetadataType(mt || 'NONE');
  }

  activeMathContents(): string | undefined {
    return this.activeComponentContents(f => S.isMath(f), f => (f as S.MathContent).mathContent);
  }

  activeBlank(): S.BlankComponent | null {
    if (this.activeComponentId()) {
      const fragment = this.activeComponentFragment();
      if (fragment) {
        if (S.isBlank(fragment)) {
          return fragment;
        } else if (S.isBlankMath(fragment)) {
          return S.toBlank(fragment);
        }
      }
    }
    return null;
  }

  activeLinkDisplayText(): string | undefined {
    return this.activeComponentContents(f => S.isLinkComponent(f), f => (f as S.LinkComponent).displayText);
  }

  activeLink(): string | undefined {
    return this.activeComponentContents(f => S.isLinkComponent(f), f => (f as S.LinkComponent).link);
  }

  activeComponentContents(
    match: (_: S.EditorFragment) => boolean,
    result: (_: S.EditorFragment) => string
  ): string | undefined {
    const component = this.fragments().find(fragment => fragment.fragmentId === this.activeComponentId());
    if (component && match(component)) {
      return result(component);
    }
    return undefined;
  }

  async executePaste(blanksOnly: boolean) {
    const payload = await D.readClipboard();
    if (S.isPayload(payload)) {
      const edgeStyles = S.edgeStylesForPaste(this.fragments(), this.selection(), payload);
      const outcome = this.selection().collapsed ? null : this.deleteSelectionResult(blanksOnly);
      let fragments = this.fragments();
      let selection = this.selection();
      if (outcome) {
        const [s, fs] = outcome;
        selection = s;
        if (fs) {
          fragments = fs;
        }
      }
      const [pasteResult, newSelection] = S.pasteStyled(fragments, selection, payload, edgeStyles)

      this.resetFragmentsAndSelection(pasteResult, newSelection);
    }
  }

  executeCut(blanksOnly: boolean) {
    if (!this.selection().collapsed) {
      this.executeCopy();
      this.deleteSelection(blanksOnly);
    }
  }

  async executeCopy() {
    if (!this.selection().collapsed) {
      const payload = S.selectionContents(this.selection(), this.fragments());
      const clipboardItem = new ClipboardItem({
        "text/html": new Blob([JSON.stringify(payload)], { type: "text/html" }),
        "text/plain": new Blob([payload.text], {type: "text/plain" })
      });
      await navigator.clipboard.write([clipboardItem]);
    }
  }

  selectComponent() {}

  componentSelected(componentId: string): boolean {
    return componentId === this.activeComponentId() || S.fragmentBetwixt(componentId, this.fragments(), this.selection());
  }

  setFragmentContents(id: string, contents: string, refresh?: boolean) {
    const fragments = this.fragments();
    const fragmentIndex = this.fragmentIndex(id);
    if (fragmentIndex && S.isAnyBlank(fragments[fragmentIndex])) {
      const old = fragments[fragmentIndex];
      const fragment = {
        ...old,
        blankContent: contents
      };
      fragments.splice(fragmentIndex, 1, fragment);
      this.replaceFragments(fragments, refresh)
    }
  }

  executeDrop(event: MouseEvent, payload: S.EditorFragment[]) {
    const mx = event.clientX;
    const my = event.clientY;
    const fragments = [...this.fragments()];
    for (let geometry of D.iterateBlankGeometry(this.fragments())) {
      const { fragment, rectangle, index } = geometry;
      const content = S.fragmentListStringRepresentation(payload);
      if (D.intersectBox(rectangle, mx, my)) {
        let blank: S.EditorFragment | null = null;
        if (S.isBlank(fragment)) {
          blank = {
            ...fragment,
            blankContent: content
          };
        } else if (S.isBlankMath(fragment)) {
          blank = {
            ...fragment,
            blankContent: content,
            mathMarker: true
          }
        }
        if (blank) {
          fragments.splice(index, 1, blank);
          this.replaceFragments(fragments, false);
          return;
        }
      }
    }
  }

  focusNextBlank() {
    const fid = this.activeFragmentId();
    const cid = this.activeComponentId();
    const fs = this.fragments();
    let activeMet = false;
    let firstBlank: S.BlankComponent | null = null;
    const setBlankFocus = (fragmentId: string) => {
      const textLength = D.fragmentTextLength(fragmentId);
      const locality = S.EditorLocality(fragmentId, textLength);
      this.updateSelectionWithDocument({ anchor: locality, end: locality, collapsed: true });
    }
    for (let i = 0; i < fs.length; i++) {
      const f = fs[i];
      if (S.isBlank(f) && firstBlank === null) {
        firstBlank = f;
      }
      if (f.fragmentId === fid) {
        activeMet = true;
        continue;
      }
      if (activeMet) {
        if (S.isBlank(f)) {
          setBlankFocus(f.fragmentId);
          return; 
        } else if (S.isBlankMath(f)) {}
      }
    }
    if (firstBlank) {
      setBlankFocus(firstBlank.fragmentId);
    }
  }
}
