import React, { ChangeEvent, useRef, ReactNode, useState } from 'react';
import styles from "./TextEditor.module.scss";
import useClosable from "../closable";
import { useGlossary as g } from "../language";
import { useEditorContext } from "./editorState";

type EditorLinkProps = {
  target: string;
  display: string;
}

function EditorLink(props: EditorLinkProps) {
  return (
    <span
      className={styles['outer-link']}
      onClick={event => {}}
    >
      <a href={props.target} className={styles['editor-anchor']}>
        {props.display}
      </a>
    </span>
  );
}

export function LinkMetadataEditor() {
  const editor = useEditorContext();
  // const changeDisplayText = (event: ChangeEvent<HTMLInputElement>) => {};
  // const c
  const ref = useRef<HTMLDivElement>(null);
  useClosable(ref, () => {});
  const [displayText, setDisplayText] = useState(editor.activeLinkDisplayText() || "");
  const [link, setLink] = useState(editor.activeLink() || "");
  const rowClass = styles['link-editor-row'];
  return (
    <div className={styles['link-editor']} ref={ref}>
      <div className={styles['link-editor-grid']}>
        <label>{"Display Text" /*g('linkEditPrompt')*/}</label>
        <input
          type="text"
          className={styles['link-editor-input']}
          onChange={event => {
            const value = event.target.value;
            setDisplayText(value);
            editor.updateLinkDisplayText(value);
          }}
          value={displayText}
        />
        <label>URL</label>
        <input
          type="text"
          className={styles['link-editor-input']}
          onChange={event => {
            const value = event.target.value;
            setLink(value);
            editor.updateLink(value);
          }}
          value={link}
        />
      </div>
      <a href={promoteUrl(link)} target="_blank">{g('testLink')}</a>
    </div>
  );
}

function promoteUrl(url: string): string {
  if (url.startsWith("https://") || url.startsWith("http://")) {
    return url;
  } else {
    return `https://${url}`;
  }
}
