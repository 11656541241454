import React from 'react';
import * as Q from "questionTypes";
import * as C from "./bodyDisplayComponents";
import { AnswerLabel } from "labels";
import * as E from "TextEditor";

export type QuestionPromptDisplayProps = {
  question: Q.QuestionBody;
  additionalClasses?: string;
  singleLine?: boolean;
}

export type QuestionBodyDisplayProps = QuestionPromptDisplayProps & {
  labelType: AnswerLabel;
}

export function WriteInDisplay(props: QuestionBodyDisplayProps) {
  const body = props.question as Q.WriteIn;
  const answers = body.answers.map(answer => (
    <C.WriteInAnswerDisplay answer={answer} labelType={props.labelType || null} key={E.stringRepresentation(answer.content)}/>
  ));
  return (
    <C.QuestionAnswerOutline
      question={body.question}
      answerSlot={<>{answers}</>}
      additionalClasses={props.additionalClasses}
    />
  );
}

export function MultipleChoiceDisplay(props: QuestionBodyDisplayProps) {
  const body = props.question as Q.MultipleChoice;
  return (
    <C.QuestionAnswerOutline
      question={body.question}
      answerSlot={
        <C.MultipleChoiceOutlineDisplay
          correctSlot={<C.SelectableAnswerDisplay answer={body.correctAnswer}/>}
          otherSlot={<>{body.otherAnswers.map(answer => <C.SelectableAnswerDisplay answer={answer} key={E.stringRepresentation(answer.content)}/>)}</>}
        />
      }
      additionalClasses={props.additionalClasses}
    />
  );
}

export function MultiSelectDisplay(props: QuestionBodyDisplayProps) {
  const body = props.question as Q.MultiSelect;
  const correctSlot = (
    <>{body.correctAnswers.map(answer => <C.SelectableAnswerDisplay answer={answer} key={E.stringRepresentation(answer.content)}/>)}</>
  );
  const otherSlot = (
    <>
      {body.otherAnswers.map(answer => (
        <C.SelectableAnswerDisplay answer={answer} key={E.stringRepresentation(answer.content)}/>
      ))}
    </>
  );
  return (
    <C.QuestionAnswerOutline
      question={body.question}
      answerSlot={
        <C.MultiSelectOutlineDisplay
          correctSlot={correctSlot}
          otherSlot={otherSlot}
        />
      }
      additionalClasses={props.additionalClasses}
    />
  );
}

// blankResolver={spec => (
//   <C.SuppliedElementForm content={spec.blank.answer} singleLine={singleLine}/>
// )}

export function FillInTheBlankDisplay(props: QuestionBodyDisplayProps) {
  const singleLine = props.singleLine || false;
  const body = props.question as Q.FillInTheBlank;
  return (
    <C.FillInTheBlankOutlineDisplay
      fitbPrompt={body}
      additionalClasses={props.additionalClasses}
      singleLine={singleLine}
    />
  )
}

export function TrueFalseDisplay(props: QuestionBodyDisplayProps) {
  const body = props.question as Q.TrueFalse;
  return (
    <C.QuestionAnswerOutline
      question={body.question}
      answerSlot={
        <C.TrueFalseRow
          onClick={() => {}}
          variant={'correct'}
          falseSelected={!body.validity}
          trueSelected={body.validity}
        />}
      additionalClasses={props.additionalClasses}
    />
  );
}

export function OrderingDisplay(props: QuestionBodyDisplayProps) {
  const body = props.question as Q.Ordering;
  return (
    <C.QuestionAnswerOutline
      question={body.question}
      answerSlot={
        <C.OrderingDisplayBody
          order={body.order}
          inactivePartitions={body.inactivePartitions}
          labelType={props.labelType}
        />
      }
    />
  )
}
