import React from 'react';
import styles from "./widgets.module.scss";
import { HiOutlinePlus } from "react-icons/hi";

type AddButtonProps = {
  onClick: () => void;
  extraClasses?: string;
}

export function AddButton(props: AddButtonProps) {
  const className = `${styles['add-button']} ${props.extraClasses || ""}`;
  return (
    <button className={className} onClick={props.onClick}>
      {/*<HiOutlinePlus/>*/}+
    </button>
  );
}
