import React, { ChangeEvent, useRef, useState, useEffect } from 'react';
import styles from "./TextEditor.module.scss";
import { useGlossary as g } from "../language";
import Toggle from "commonComponents/Toggle";
import useFocus from "../focus";
import useClosable from "../closable";
import { MathJax } from 'better-react-mathjax';
import * as S from "./editorState";

type Notation = "latex" | "ascii";

export type MathDescriptor = {
  notation: Notation;
  source: string;
  block: boolean;
}

function encodeForNotation(descriptor: MathDescriptor): string {
  switch (descriptor.notation) {
    case 'ascii': return `\`${descriptor.source}\``;
    case 'latex': return `\(${descriptor.source}\)`;
  }
}

type MathDisplayProps = {
  descriptor: MathDescriptor;
}

export function MathDisplay(props: MathDisplayProps) {
  const wrapperClass = styles[false ? 'selected-math-wrapper' : 'math-wrapper'];
  const blockClass = styles[props.descriptor.block ? 'block-math' : ''];
  const completeClass = `${wrapperClass} ${blockClass}`;
  const onClick = () => {}
  return (
    <span className={wrapperClass} onClick={onClick}>
      <MathJax inline={true}>{encodeForNotation(props.descriptor)}</MathJax>
    </span>
  );
}

function switchNotation(notation: Notation): Notation {
  if (notation === 'latex') {
    return 'ascii';
  } else {
    return 'latex';
  }
}

export function MathMetadataEditor() {
  const editor = S.useEditorContext();
  const [text, setText] = useState(editor.activeMathContents() || "");
  const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    editor.updateMathContents(value);
    setText(value);
  }
  useEffect(() => {
    setText(editor.activeMathContents() || "");
  }, [editor.activeComponentId()])
  const onBlockChange = () => {}
  const ref = useRef(null);
  const outerRef = useRef(null);
  useFocus(ref);
  useClosable(outerRef, () => {});
  return (
    <div className={styles['math-metadata-editor']} ref={outerRef}>
      <textarea
        ref={ref}
        className={styles['math-input']}
        rows={1}
        value={text}
        onChange={onChange}
      />
    </div>
  );
}

type BlockCheckboxProps = {
  block: boolean;
  onClick: () => void;
};

function BlockCheckbox(props: BlockCheckboxProps) {
  return (
    <span>
      <input type="checkbox" checked={props.block}/>
      <label>{g('mathBlockLabel')}</label>
    </span>
  );
}

type NotationOptionProps = {
  notation: Notation;
  descriptor: MathDescriptor;
}

function NotationOption(props: NotationOptionProps) {
  const checked = props.descriptor.notation === props.notation;
  return (
    <div className={styles['notation-option']}>
      <input type="radio" checked={checked} onChange={() => {}}/>
      <label>{g(props.notation)}</label>
    </div>
  );
}

type NotationOptionsProps = {
  descriptor: MathDescriptor;
}

function NotationOptions(props: NotationOptionsProps) {
  const onToggle = () => {}
  return (
    <div className={styles['notation-options']} onClick={onToggle}>
      <NotationOption notation={'latex'} descriptor={props.descriptor}/>
      <NotationOption notation={'ascii'} descriptor={props.descriptor}/>
    </div>
  );
}
