import { StyleSpecification } from "./editorState";
import styles from "./TextEditor.module.scss";
import useClosable from "closable";
import { useRef, Ref } from "react";

type FontSizePickerProps = {
  close: () => void;
  onChange: (_: number) => void;
};

export default function FontSizePicker(props: FontSizePickerProps) {
  const ref = useRef<HTMLDivElement>(null);
  useClosable(ref, props.close);
  return (
    <div className={styles['font-size-picker']} ref={ref}>
      {fontSizes.map(size => <div className={styles['font-size']} onClick={() => props.onChange(size)}>{size}</div>)}
    </div>
  )
}

const fontSizes = [7, 8, 9, 10, 12, 14, 16, 18, 20, 24, 30, 36, 48, 72, 96];

export const fontSizeStyles: StyleSpecification[] = fontSizes.map(size => ({
  styleId: fontSizeStyleId(size),
  category: "FONT_SIZE",
  style: {
    "font-size": `${size / 12}rem`
  }
}));

export function fontSizeStyleId(fontSize: number): string {
  return `FONT_SIZE_${fontSize}`;
}
