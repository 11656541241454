import React from 'react';
import styles from "./ColorPicker.module.scss";
import { StyleSpecification } from "./editorState";

export type Color = {
  name: string;
  value: string;
};

function color(name: string, value: string): Color {
  return { name, value };
}

export function colorId(color: Color, effect: ColorEffect): string {
  return `${effect}-${color.value}`;
}

export const supportedColors = [
  color("RED", "#ff0000"),
  color("ORANGE", "#ffa500"),
  color("GOLD", "#ffd700"),
  color("YELLOW", "#ffff00"),
  color("LIME", "#00ff00"),
  color("GREEN", "#008000"),
  color("CYAN", "#00ffff"),
  color("BLUE", "#0000ff"),
  color("DARKBLUE", "#00008b"),
  color("PURPLE", "#800080"),
  color("PINK", "#ff1493"),
  color("BROWN", "#a52a2a"),
  color("WHITE", "#ffffff"),
  color("LIGHTGRAY", "#d3d3d3"),
  color("SILVER", "#c0c0c0"),
  color("GRAY", "#808080"),
  color("BLACK", "#000000"),
];

export type ColorEffect = "TEXT_COLOR" | "HIGHLIGHT_COLOR"

export function colorStyles(effect: ColorEffect): StyleSpecification[] {
  return supportedColors.map(color => ({
    styleId: colorId(color, effect),
    style: colorCSSStyle(effect, color),
    category: effect
  }));
}

function colorCSSStyle(effect: ColorEffect, color: Color): Record<string, string> {
  switch (effect) {
    case "TEXT_COLOR": return {
      color: color.value
    };
    case "HIGHLIGHT_COLOR": return {
      "background-color": color.value
    };
  }
}

type ColorPickerProps = {
  selected?: string;
  onChange: (_: Color) => void;
}

export default function ColorPicker(props: ColorPickerProps) {
  return (
    <div className={styles['color-picker']}>
      {supportedColors.map(color => {
        const selected = color.name === props.selected;
        return (
          <ColorBox
            color={color}
            selected={selected}
            key={color.name}
            onClick={props.onChange}
          />
        );
      })}
    </div>
  )
}

type ColorBoxProps = {
  color: Color;
  selected: boolean;
  onClick: (_: Color) => void;
}

function ColorBox(props: ColorBoxProps) {
  const style = {
    background: props.color.value
  };
  const className = styles[props.selected ? "color-box-selected" : "color-box"];
  const wrapperClassName = styles[props.selected ? "color-box-wrapper-selected" : "color-box-wrapper"];
  return (
    <div className={wrapperClassName} onClick={() => props.onClick(props.color)}>
      <div className={className} style={style}></div>
    </div>
  )
}
