import React, { useRef } from 'react';
import { Hint, Hints } from "./hintsModel";
import * as E from "../TextEditor";
import { AddButton, MinusButton } from "commonComponents/buttons";
import styles from "./QuestionHints.module.scss";
import HintInfo from "./HintInfo";
import useFocus from "../focus";
import { g } from "language";
import AbsenceIndicator from "commonComponents/AbsenceIndicator";

type QuestionHintsProps = {
  hints: Hints;
  setHints: (_: Hints) => void;
}

export default function QuestionHints(props: QuestionHintsProps) {
  const update = () => {
    props.setHints({
      ...props.hints
    });
  }
  const addHint = () => {
    props.hints.hints.push({
      text: E.constructContent(),
      position: props.hints.hints.length
    });
    update();
  };
  const removeHint = (index: number) => {
    props.hints.hints.splice(index, 1);
    update();
  };
  const updateHint = (content: E.Content, index: number) => {
    props.hints.hints.splice(index, 1, {
      position: index,
      text: content
    });
    update();
  };
  return (
    <div>
      <div className={styles['label-wrapper']}>
        <label>{g('addHintsLabel')}</label>
        <HintInfo/>
      </div>
      {props.hints.hints.map((hint, index) => (
        <HintRow
          index={index}
          hint={hint}
          update={content => updateHint(content, index)}
          remove={() => removeHint(index)}
          key={index}
        />
      ))}
      <AddButton onClick={addHint}/>
    </div>
  )
}

type HintRowProps = {
  index: number;
  hint: Hint;
  update: (_: E.Content) => void;
  remove: () => void;
}

function HintRow(props: HintRowProps) {
  const ref = useRef(null);
  useFocus(ref);
  return (
    <>
      <div>{props.index + 1}.</div>
      <div className={styles['hint-row']}>
        <E.Editor
          value={props.hint.text}
          onChange={props.update}
          additionalClasses={styles['hint-text']}
          reference={ref}
        />
        <MinusButton onClick={props.remove}/>
      </div>
    </>
  )
}

type HintsDisplayProps = {
  hints: Hints;
}

export function HintsDisplay(props: HintsDisplayProps) {
  const hints = props.hints.hints;
  return hints.length > 0 ? (
    <div className={styles['hint-list']}>
      {hints.map(hint => (
        <HintDisplayRow hint={hint} key={E.stringRepresentation(hint.text)}/>
      ))}
    </div>
  ) : <AbsenceIndicator glossaryKey={'hintsAbsent'}/>;
}

type HintDisplayRowProps = {
  hint: Hint;
  additionalClasses?: string;
}

function HintDisplayRow(props: HintDisplayRowProps) {
  const className = `${styles['hint-display-row']} ${props.additionalClasses || ""}`;
  return (
    <div className={className}>
      <span className={styles['hint-display-index']}>{props.hint.position + 1}.</span>
      <E.TextDisplay value={props.hint.text}/>
    </div>
  );
}

type HintsRevealedProps = {
  hints: Hints;
  revealed: number;
};

export function HintsRevealed(props: HintsRevealedProps) {
  const hints = props.hints.hints;
  const revealedLabel = g('hintRevealedLabel');
  return hints.length > 0 ? (
    <div className={styles['hint-list']}>
      {hints.map((hint, index) => {
        const revealed = index < props.revealed;
        return (
          <>
            {revealed ? <label className={styles['revealed-label']}>{revealedLabel}</label> : null}
            <HintDisplayRow
              hint={hint}
              key={E.stringRepresentation(hint.text)}
              additionalClasses={styles[revealed ? 'revealed-hint' : "unrevealed-hint"]}
            />
          </>
        );

      })}
    </div>
  ) : null;
}
