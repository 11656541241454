import React, { useState, useEffect } from 'react';
import styles from './App.module.scss';
import { BrowserRouter } from 'react-router-dom';
import NavBar from './NavBar';
import {
  UserContext, User, UserContextValue, UserContextState
} from "./user";
import { ongoingSession, retrieveGlossary, activeQuiz } from "./network";
import { Glossary, LanguageContext } from "./language";
import MainContent from "./MainContent";
import H from "highlight.js";
import { QuizContext } from "./quizContext";
import { MathJaxContext, MathJax } from "better-react-mathjax";

function App() {
  const [userState, setUserState] = useState<UserContextState | null>(null);
  const [glossary, setGlossary] = useState<Glossary | null>(null);
  const userContextValue = {
    user: userState,
    setUser: setUserState
  };
  const [quizId, setQuizId] = useState<string | null>(null);
  const quizContextValue = {
    quizId: quizId,
    setQuizId: setQuizId
  };
  useEffect(() => {
    (async () => {
      const userValue = await ongoingSession();
      if (userValue) {
        setUserState(new UserContextState({ user: userValue }));
      }
    })();
  }, []);
  useEffect(() => {
    (async () => {
      const language = navigator.language.toLowerCase();
      setGlossary(await retrieveGlossary(language));
    })();
  }, [setGlossary]);
  useEffect(() => {
    (async() => {
      if (userContextValue.user) {
        const active = await activeQuiz();
        if (active.quizId) {
          setQuizId(active.quizId);
        }
      }
    })();
  }, [userContextValue.user]);
  document.addEventListener("DOMContentLoaded", () => H.highlightAll());
  return (
    <div className={styles['global']}>
      <MathJaxContext>
        <UserContext.Provider value={userContextValue}>
          <QuizContext.Provider value={quizContextValue}>
            <LanguageContext.Provider value={glossary}>
              <BrowserRouter>
                <NavBar/>
                <MainContent/>                
              </BrowserRouter>
            </LanguageContext.Provider>
          </QuizContext.Provider>
        </UserContext.Provider>
      </MathJaxContext>
    </div>
  );
}

export default App;
