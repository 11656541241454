import { StyleSpecification } from "./editorState";
import styles from "./TextEditor.module.scss";
import { useRef } from "react";
import useClosable from "closable";

type FontPickerProps = {
  onChange: (_: string) => void;
  close: () => void;
}

export default function FontPicker(props: FontPickerProps) {
  const ref = useRef<HTMLDivElement>(null);
  useClosable(ref, props.close);
  return (
    <div className={styles['font-picker']} ref={ref}>
      {supportedFonts.map(font => (
        <div
          className={styles['font']}
          style={{ fontFamily: font }}
          onClick={() => props.onChange(font)}
        >{font}</div>
      ))}
    </div>
  );
}

const supportedFonts = [
  "Times New Roman",
  "Fogle Hunter",
  "Old English Five",
  "Zapfino"
];

export const fontStyles: StyleSpecification[] = supportedFonts.map(font => ({
  styleId: fontStyleId(font),
  category: "FONT",
  style: {
    "font-family": font
  }
}));

export function fontStyleId(font: string): string {
  return `FONT_${font}`;
}
