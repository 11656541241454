import { AnswerLabel } from "labels";
import { NumberPredicate } from "commonComponents/NumberPredicateSelector";

export type BaseModel = {
  imageAfter: boolean;
};

export type TextEntryModel = {
  ignoreWhitespace: boolean;
  ignoreCapitalization: boolean;
  ignorePunctuation: boolean;
  errorMargin: number;
};

export type LabelModel = {
  labelType: AnswerLabel | null;
};

export type RequiredCountModel = {
  requiredCount: number | null;
}

export type WriteInModel = BaseModel & TextEntryModel & LabelModel & RequiredCountModel & {
  ordered: boolean;
};

export type FillInTheBlankModel = BaseModel & TextEntryModel & {
  randomize: boolean;
  showAnswers: boolean;
};

export type TrueFalseModel = BaseModel;

export type ChoiceModel = BaseModel & LabelModel;

export type MultipleChoiceModel = ChoiceModel;

export type MultiSelectModel = ChoiceModel;

export type OrderingModel = BaseModel & LabelModel & RequiredCountModel;

export type QuestionSettingsModel =
  WriteInModel |
  FillInTheBlankModel |
  TrueFalseModel |
  MultipleChoiceModel |
  MultiSelectModel;

export type MaximalQuestionSettingsModel =
  WriteInModel &
  FillInTheBlankModel &
  TrueFalseModel &
  MultipleChoiceModel &
  MultiSelectModel;

export type SettingsSearchModel = {
  requiredCount: boolean;
  labelType: AnswerLabel[] | 'all';
  errorMargin: NumberPredicate;
} & Omit<MaximalQuestionSettingsModel, 'requiredCount' | 'errorMargin' | 'labelType'>;

export type Setting = keyof MaximalQuestionSettingsModel;

export const SETTINGS_FORM_ORDER: Setting[] = [
  'ignoreWhitespace', 'ignoreCapitalization', 'ignorePunctuation', 'labelType', 'ordered', 'errorMargin',
  'requiredCount', 'randomize', 'showAnswers', 'imageAfter'
];

export type SettingsValue = MaximalQuestionSettingsModel[Setting];

export function hasLabelType(q: object): q is LabelModel {
  return (q as LabelModel).labelType !== undefined;
}

export const ANSWER_INFLUENCING_SETTINGS: readonly Setting[] = [
  'ignoreWhitespace',
  'ignoreCapitalization',
  'ignorePunctuation',
  'ordered',
  'errorMargin'
] as const;

export type AnswerInfluencingSetting = typeof ANSWER_INFLUENCING_SETTINGS[number];

export function isWriteInSettings(settings: QuestionSettingsModel): settings is WriteInModel {
  return (settings as WriteInModel).requiredCount !== undefined;
}

export function isFillInTheBlankSettings(settings: QuestionSettingsModel): settings is FillInTheBlankModel {
  return (settings as FillInTheBlankModel).randomize !== undefined;
}

export function isTextualSettings(settings: QuestionSettingsModel): settings is (WriteInModel | FillInTheBlankModel) {
  return isWriteInSettings(settings) || isFillInTheBlankSettings(settings);
}
