import React, { useState, useEffect, ReactNode } from 'react';
import * as Q from "../questionTypes";
import styles from "./NewQuestionPage.module.scss";
import { retrieveLatestQuestionSettings, error, success } from "../network";
import { useUser } from "../user";
import { Tag } from "../tags";
import * as S from "../QuestionSettings";
import { uploadPictures, persistNewQuestion } from "../network";
import { SubmissionResult, QuestionSubmissionIndicator } from "../SubmissionResult";
import * as E from "../TextEditor";
import QuestionForm, { InvalidityIndicator } from "QuestionForm";
import * as F from "QuestionForm";
import { LabeledFile, picturesForUpload } from "../pictures";
import { Hints, initialHints } from "../QuestionHints";
import LowerBar from "LowerBar";
import { g } from "language";
import LoadingIndicator from "commonComponents/LoadingIndicator";

export default function NewQuestionPage() {
  const state = Q.useVariadicQuestionState();
  return (
    <NewQuestionPageForm state={state}/>
  );
}

type NewQuestionPageFormProps = {
  state: Q.VariadicQuestionState;
}

function NewQuestionPageForm(props: NewQuestionPageFormProps) {
  const userContext = useUser();
  const [selectedTagIds, setSelectedTagIds] = useState<string[]>(F.defaultTagIds());
  const [newTags, setNewTags] = useState<Tag[]>([]);
  const [settings, setSettings] = useState<S.MaximalQuestionSettingsModel | null>(F.defaultSettings());
  const [notes, setNotes] = useState(F.defaultNotes());
  const [files, setFiles] = useState<LabeledFile[]>(F.defaultFiles());
  const [answerFiles, setAnswerFiles] = useState<LabeledFile[]>(F.defaultFiles());
  const [submissionState, setSubmissionState] = useState<SubmissionResult>('none');
  const [questionType, setQuestionType] = useState<Q.QuestionType>(F.defaultQuestionType());
  const [hints, setHints] = useState<Hints>(F.defaultHints());
  const [previousQuestionId, setPreviousQuestionId] = useState<string | null>(null);
  const addNewTag = (tag: Tag) => {
    setSelectedTagIds(selectedTagIds.concat([tag.tagId]));
    setNewTags(newTags.concat([tag]));
  };
  const setQuestionTypeAndBody = (qt: Q.QuestionType) => {
    Q.updateVariadicQuestionState(questionType, qt, props.state);
    setQuestionType(qt);
  };
  useEffect(() => {
    (async function() {
      if (userContext.user) {
        const defaults = await retrieveLatestQuestionSettings(userContext.user.userId());
        if (success(defaults) && !!defaults) {
          setSettings(S.expandSettingsState(defaults.settings));
          setSelectedTagIds(defaults.selectedTagIds);
          setQuestionType(defaults.questionType);
        } else {
          setSettings(S.defaultMaximalSettingsState());
          setSelectedTagIds([]);
          setQuestionType('write-in');
        }
      }
    })();
  }, [userContext.user.userId()]);
  const resetOnSubmission = () => {
    props.state.setters.setWriteIn(Q.initialWriteIn());
    props.state.setters.setMultipleChoice(Q.initialMultipleChoice());
    props.state.setters.setFillInTheBlank(Q.initialFillInTheBlank());
    props.state.setters.setTrueFalse(Q.initialTrueFalse());
    props.state.setters.setMultiSelect(Q.initialMultiSelect());
    props.state.setters.setOrdering(Q.initialOrdering());
    setFiles([]);
    setNotes(E.constructContent());
    setHints(initialHints());
    setAnswerFiles([]);
  }
  const body = questionType ? Q.functionalityByType(questionType).resolveVariadicQuestionBody(props.state.state) : null;
  const finalSettings = questionType && settings ? S.clampedSettingsModel(questionType, settings) : null;
  const answerCount = questionType ? Q.functionalityByType(questionType).answerCount(props.state) : null;
  return (
    <div className={styles['new-question-page']}>
      <QuestionSubmissionIndicator
        result={submissionState}
        submittedQuestion={previousQuestionId}
        close={() => setSubmissionState('none')}
      />
      <h1>{g('newQuestionHeader')}</h1>
      {(settings && selectedTagIds && questionType && body && finalSettings) ? (
        <>
          <QuestionForm
            questionType={questionType}
            setQuestionType={setQuestionTypeAndBody}
            body={props.state}
            hints={hints}
            setHints={setHints}
            files={files}
            setFiles={setFiles}
            selectedTagIds={selectedTagIds}
            setSelectedTagIds={setSelectedTagIds}
            addNewTag={addNewTag}
            settings={settings}
            setSettings={setSettings}
            answerCount={answerCount}
            notes={notes}
            setNotes={setNotes}
            answerFiles={answerFiles}
            setAnswerFiles={setAnswerFiles}
          />
          <NewQuestionBar>
            <CreateButton
              questionType={questionType}
              body={body}
              settings={finalSettings}
              tagIds={selectedTagIds}
              newTags={newTags}
              pictures={files}
              answerPictures={answerFiles}
              notes={notes}
              hints={hints}
              setSubmissionState={setSubmissionState}
              resetOnSubmission={resetOnSubmission}
              setPreviousQuestionId={setPreviousQuestionId}
            />
          </NewQuestionBar>
        </>
      ) : <LoadingIndicator/>}
    </div>
  );
}

type NewQuestionBarProps = {
  children: ReactNode;
}

function NewQuestionBar(props: NewQuestionBarProps) {
  return (
    <LowerBar fullPage={true}>
      <div className={styles['inner-bar']}>
        {props.children}
      </div>
    </LowerBar>
  )
}

type CreateButtonProps = {
  questionType: Q.QuestionType;
  body: Q.QuestionBody;
  settings: S.QuestionSettingsModel;
  tagIds: string[];
  newTags: Tag[];
  pictures: LabeledFile[];
  answerPictures: LabeledFile[];
  notes: E.Content;
  hints: Hints;
  setSubmissionState: (_: SubmissionResult) => void;
  resetOnSubmission: () => void;
  setPreviousQuestionId: (_: string | null) => void;
}

function CreateButton(props: CreateButtonProps) {
  const invalidityClassification = Q.functionalityByType(props.questionType).invalidVariant(props.body);
  const enabled = invalidityClassification === 'valid';
  const buttonClass = enabled ? styles['create-button'] : styles['disabled-button'];
  const userContext = useUser();
  const picturesMerged = picturesForUpload(props.questionType, props.body, props.pictures, props.answerPictures);
  const submitQuestion = () => {
    if (userContext.user) {
      const userId = userContext.user.userId();
      return persistNewQuestion({
        userId,
        newTags: props.newTags,
        question: {
          questionType: props.questionType,
          body: props.body,
          settings: props.settings,
          tagIds: props.tagIds,
          notes: props.notes,
          images: picturesMerged.handles,
          hints: props.hints
        }
      })
    } else {
      return Promise.resolve({ status: 403 });
    }
  };
  const submitImages = () => {
    return uploadPictures(picturesMerged.files);
  };
  const onClick = () => {
    if (enabled) {      
      Promise.all([submitQuestion(), submitImages()])
      .then(([questionSubmission, imageSubmission]) => {
        console.log("image submission", imageSubmission)
        props.resetOnSubmission();
        if (error(questionSubmission) || imageSubmission >= 300) {
          props.setSubmissionState('failure');
        } else if (success(questionSubmission)) {
          props.resetOnSubmission();
          props.setSubmissionState('success');
          props.setPreviousQuestionId(questionSubmission.questionId);
          
        }
      })
      .catch(error => {
        console.log(error);
        props.setSubmissionState('failure');
      })
    }
  }
  return (
    <div className={styles['create-button-wrapper']}>
      <InvalidityIndicator body={props.body} questionType={props.questionType}/>
      <button className={buttonClass} onClick={onClick}>{g('newQuestionButtonText')}</button>
    </div>
  );
}
