import { Content } from "../TextEditor";
import { FillInTheBlank, SelectableAnswer, OrderingAnswer } from "../questionTypes";

export type InputPrompt = {
  question: Content;
};

export type MultipleInputPrompt = InputPrompt & {
  inputs: number;
};

export type SelectionPrompt = InputPrompt & {
  answers: SelectableAnswer[];
};

export type OrderingPrompt = InputPrompt & {
  order: OrderingAnswer[];
}

export type FillInTheBlankPrompt = {
  body: Content;
  distractors: Content[] | null;
};

export type MultipleChoicePrompt = SelectionPrompt;

export type MultiSelectPrompt = SelectionPrompt;

export type WriteInPrompt = MultipleInputPrompt;

export type TrueFalsePrompt = InputPrompt;

export type QuestionPrompt = InputPrompt | SelectionPrompt | FillInTheBlankPrompt | OrderingPrompt; 

export function isFillInTheBlankPrompt(q: QuestionPrompt): q is FillInTheBlankPrompt {
  return (q as FillInTheBlankPrompt).body !== undefined;
}