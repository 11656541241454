import styles from "./TextEditor.module.scss";
import * as S from "./editorState";
import { useGlossary as g } from "language";
import { MathJax } from "better-react-mathjax";
import { EditState } from "./TextEditor";

type BlankMetadataEditorProps = {
  isMath: boolean;
  editable: EditState;
}

export default function BlankMetadataEditor(props: BlankMetadataEditorProps) {
  const editor = S.useEditorContext();
  const blank = editor.activeBlank();
  if (!props.isMath && props.editable === 'blanks-only') {
    return null;
  }
  if (props.editable === 'uneditable') {
    return null;
  }
  if (blank) {
    return (
      <div className={styles['blank-editor']}>
        {props.isMath && (
          <input
            className={styles['blank-editor-text']}
            value={blank.blankContent}
            onChange={event => editor.updateBlank({
              ...blank,
              blankContent: event.target.value
            }, props.isMath)}
          />
        )}
        {props.editable === 'editable' ? (
          <>
            <ListEditor listKey={'alternates'} isMath={props.isMath}/>
            <ListEditor listKey={'options'} isMath={props.isMath}/>
          </>
        ) : null}
      </div>
    );
  }
  return null;
}

type ListEditorProps = {
  listKey: 'options' | 'alternates';
  isMath: boolean;
}

function ListEditor(props: ListEditorProps) {
  const editor = S.useEditorContext();
  const blank = editor.activeBlank();
  const header = g(`blankEditor_${props.listKey}`);
  if (blank) {
    const update = (entries: string[]) => {
      editor.updateBlank({
        ...blank,
        [props.listKey]: entries
      }, props.isMath);
    }
    const entries = blank[props.listKey];
    const setter = (value: string, index: number) => {
      entries.splice(index, 1, value);
      update(entries);
    }
    return (
      <div className={styles['blank-list-editor']}>
        <div className={styles['blank-subheader-row']}>
          <div className={styles['blank-editor-subheader']}>{header}</div>
          <div
            className={styles['add-button']}
            onClick={() => {
              entries.push("");
              update(entries);
            }}
          >+</div>
        </div>
        {entries.map((entry, index) => (
          <div className={styles['blank-entry-row']}>
            <input
              className={styles['blank-editor-text']}
              value={entry}
              onChange={event => setter(event.target.value, index)}
            />
            {props.isMath && (
              <div className={styles['blank-math-display']}>
                <MathJax inline={true} onClick={() => {}}>{`\\(${entry}\\)`}</MathJax>
              </div>
            )}
            <div
              className={styles['minus-button']}
              onClick={() => {
                entries.splice(index, 1);
                update(entries);
              }}
            >-</div>
            
          </div>
        ))}
      </div>
    );
  }
  return null;
}