import React, { useState, useEffect } from 'react';
import * as Q from "questionTypes";
import FormSection from "FormSection";
import QuestionNotes from "QuestionNotes";
import { PictureUploader, LabeledFile, labeledFileListUndoSufficientDifference } from "pictures";
import QuestionHints, { Hints, hintUndoSufficientDifference } from "QuestionHints";
import { useGlossary as g } from "language";
import { TagSelector, Tag, tagListUndoSufficientDifference } from "tags";
import QuestionSettings, { MaximalQuestionSettingsModel, settingsUndoSufficientDifference } from "QuestionSettings";
import { Content, undoSufficientDifference } from "TextEditor";
import styles from "./QuestionForm.module.scss";
import {
  UndoHistory, useUndoHistory, setStateAndRegister, UndoHistorySummary
} from "undo";
import LocalPersistence from "localPersistence";
import * as D from "./formDefaults";
import { MathJax } from "better-react-mathjax";
import { FocusRequest } from "TextEditor";

const QUESTION_FORM_KEY = 'question-form-progress';

type QuestionFormFlatProps = {
  questionType: Q.QuestionType;
  hints: Hints;
  files: LabeledFile[];
  selectedTagIds: string[];
  settings: MaximalQuestionSettingsModel;
  answerCount: number | null;
  notes: Content;
  answerFiles: LabeledFile[];
};

type UndoHistorySnapshot = QuestionFormFlatProps & {
  body: Q.VariadicQuestion;
};

type QuestionFormSetters = {
  setQuestionType: (_: Q.QuestionType) => void;
  setHints: (_: Hints) => void;
  setFiles: (_: LabeledFile[]) => void;
  setSelectedTagIds: (_: string[]) => void;
  setSettings: (_: MaximalQuestionSettingsModel) => void;
  setNotes: (_: Content) => void;
  setAnswerFiles: (_: LabeledFile[]) => void;
};

type QuestionFormProps = QuestionFormFlatProps & QuestionFormSetters & {
  body: Q.VariadicQuestionState;
  addNewTag: (_: Tag) => void;
  requestFocus?: FocusRequest;
};

export default function QuestionForm(props: QuestionFormProps) {
  const [requestFocus, setRequestFocus] = useState(true);
  const focusRequest = {
    request: requestFocus,
    indicateFocus: () => setRequestFocus(false)
  };
  const variadicSetters = props.body.setters;
  const undoHistorySufficientDifference = (last: UndoHistorySnapshot, change: Partial<UndoHistorySnapshot>) => {
    return [
      !!change.questionType && last.questionType !== change.questionType,
      !!change.hints && hintUndoSufficientDifference(last.hints, change.hints),
      !!change.files && labeledFileListUndoSufficientDifference(last.files, change.files),
      !!change.selectedTagIds && tagListUndoSufficientDifference(last.selectedTagIds, change.selectedTagIds),
      !!change.settings && settingsUndoSufficientDifference(last.settings, change.settings),
      !!change.notes && undoSufficientDifference(last.notes, change.notes),
      !!change.answerFiles && labeledFileListUndoSufficientDifference(last.answerFiles, change.answerFiles)
    ].some(x => x);
  };
  const undoHistoryStateHook = (current: UndoHistorySnapshot) => {
    props.setQuestionType(current.questionType);
    props.setHints(current.hints);
    props.setFiles(current.files);
    props.setSelectedTagIds(current.selectedTagIds);
    props.setNotes(current.notes);
    props.setAnswerFiles(current.answerFiles);
    props.setSettings(current.settings);
    Q.updateVariadicQuestionState(props.questionType, current.questionType, {
      state: current.body,
      setters: variadicSetters,
      switchbacks: props.body.switchbacks,
      setSwitchbacks: props.body.setSwitchbacks
    });
  };
  const [persistence] = useState<LocalPersistence<UndoHistorySummary<UndoHistorySnapshot>>>(
    new LocalPersistence(QUESTION_FORM_KEY)
  );
  const undoChangeCallback = (uHistory: UndoHistory<UndoHistorySnapshot>) => {
    persistence.set(uHistory.summary());
  };
  const [undoHistory, setUndoHistory] = useState<UndoHistory<UndoHistorySnapshot>>(
    new UndoHistory({
      baseState: historySnapshotFromProps(props),
      sufficientDifference: undoHistorySufficientDifference,
      stateHook: undoHistoryStateHook,
      changeCallback: undoChangeCallback
    })
  );

  useEffect(() => {
    const prior = persistence.get();
    if (prior) {
      setUndoHistory(new UndoHistory({
        previous: undoHistory,
        savedHistory: prior
      }));
    }
  }, []);
  const setters = undoEnabledSetters(props as QuestionFormSetters, undoHistory);
  const setAnswerFiles = (files: LabeledFile[], updateAnswer: () => void) => {
    const allFiles = props.answerFiles.concat(files);
    const fileMap = new Map<string, LabeledFile>();
    allFiles.forEach(file => fileMap.set(file.id, file));
    setters.setAnswerFiles(Array.from(fileMap.values()));
    updateAnswer();
  };
  const clear = () => {
    setters.setQuestionType(D.defaultQuestionType());
    setters.setSelectedTagIds(D.defaultTagIds());
    setters.setSettings(D.defaultSettings());
    setters.setNotes(D.defaultNotes());
    setters.setHints(D.defaultHints());
    setters.setFiles(D.defaultFiles());
    setters.setAnswerFiles(D.defaultFiles());
  };
  useUndoHistory(undoHistory);
  return (
    <>
      <Q.QuestionTypeSelector
        questionType={props.questionType}
        onSelect={setters.setQuestionType}
      />
      <FormSection title={g('questionBodySectionHeader')}>
        {Q.functionalityByType(props.questionType).form({
          type: props.questionType,
          state: props.body,
          labelType: props.settings.labelType,
          answerFiles: props.answerFiles,
          setAnswerFiles: setAnswerFiles,
          requestFocus: focusRequest
        })}
      </FormSection>
      <FormSection title={g('hintsSectionHeader')}>
        <QuestionHints hints={props.hints} setHints={setters.setHints}/>
      </FormSection>
      <FormSection title={g('pictureSectionHeader')}>
        <PictureUploader files={props.files} setFiles={setters.setFiles}/>
      </FormSection>
      <FormSection title={g('tagSectionHeader')}>
        <TagSelector
          selectedTagIds={props.selectedTagIds}
          setSelectedTagIds={setters.setSelectedTagIds}
          addNewTag={props.addNewTag}
        />
      </FormSection>
      <FormSection title={g('settingsSectionHeader')}>
        <QuestionSettings
          questionType={props.questionType}
          settings={props.settings}
          setSettings={setters.setSettings}
          answerCount={props.answerCount}
        />
      </FormSection>
      <FormSection title={g('notesSectionHeader')}>
        <QuestionNotes text={props.notes} setText={setters.setNotes}/>
      </FormSection>
      <ClearButton onClick={clear}/>
    </>
  );
}

function historySnapshotFromProps(props: QuestionFormProps): UndoHistorySnapshot {
  return {
    questionType: props.questionType,
    hints: props.hints,
    files: props.files,
    selectedTagIds: props.selectedTagIds,
    settings: props.settings,
    answerCount: props.answerCount,
    notes: props.notes,
    answerFiles: props.answerFiles,
    body: props.body.state
  };
}

type InvalidityIndicatorProps = {
  body: Q.QuestionBody;
  questionType: Q.QuestionType;
}

export function InvalidityIndicator(props: InvalidityIndicatorProps) {
  const invalidityClassification = Q.functionalityByType(props.questionType).invalidVariant(props.body);
  return (
    <div className={styles['invalidity-message']}>
      {g(Q.invalidityMessage(invalidityClassification))}
    </div>
  )
}

function undoEnabledSetters(
  setters: QuestionFormSetters, history: UndoHistory<UndoHistorySnapshot>
): QuestionFormSetters {
  return {
    setQuestionType: setStateAndRegister(history, 'questionType', setters.setQuestionType),
    setHints: setStateAndRegister(history, 'hints', setters.setHints),
    setFiles: setStateAndRegister(history, 'files', setters.setFiles),
    setSelectedTagIds: setStateAndRegister(history, 'selectedTagIds', setters.setSelectedTagIds),
    setNotes: setStateAndRegister(history, 'notes', setters.setNotes),
    setAnswerFiles: setStateAndRegister(history, 'answerFiles', setters.setAnswerFiles),
    setSettings: setStateAndRegister(history, 'settings', setters.setSettings)
  }
}

type ClearButtonProps = {
  onClick: () => void;
}

function ClearButton(props: ClearButtonProps) {
  return (
    <div className={styles['clear-button-wrapper']}>
      <button
        className={styles['clear-button']}
        onClick={event => props.onClick()}
      >{g('clearButton')}</button>
    </div>
  );
}
