import * as Z from "../quiz";
import { saveQuizProgress } from "../network";

const SERVER_RETRY_INTERVAL_MILLISECONDS = 20 * 1000;
const SERVER_RATE_LIMIT_INTERVAL_MILLISECONDS = 10 * 1000;
const QUIZ_PROGRESS_KEY = "quiz_progress";

function getLocalProgress(quizId: string): Z.QuizProgressEnvelope | null {
  const local = localStorage.getItem(QUIZ_PROGRESS_KEY);
  if (local) {
    const progress: Z.QuizProgressEnvelope = JSON.parse(local);
    if (progress.quizId === quizId) {
      return progress;
    } else {
      return null;
    }
  } else {
    return null;
  }
}

function setLocalProgress(progress: Z.QuizProgressEnvelope) {
  localStorage.setItem(QUIZ_PROGRESS_KEY, JSON.stringify(progress));
}

export function resolveProgress(
  quizId: string,
  fromNetwork: Z.QuizProgressEnvelope | null
): Z.QuizProgressEnvelope | null {
  if (fromNetwork) {
    return resolveLatestProgress(fromNetwork);
  } else {
    const local = getLocalProgress(quizId);
    if (local) {
      return local;
    } else {
      return null;
    }
  }
}

export function resolveLatestProgress(fromNetwork: Z.QuizProgressEnvelope): Z.QuizProgressEnvelope {
  const quizId = fromNetwork.quizId;
  const fromLocal = getLocalProgress(quizId);
  if (fromLocal) {
    const networkEffectiveTime = new Date(fromNetwork.effectiveTime);
    const localEffectiveTime = new Date(fromLocal.effectiveTime);
    if (localEffectiveTime > networkEffectiveTime) {
      return fromLocal;
    }
  }
  return fromNetwork;
}

export type SaveQuiz = (_: Map<number, Z.Response>, __: Set<string>, ___: Map<number, number>) => void;

export function saveOnUpdate(
  userId: string,
  quizId: string,
  questions: Z.QuizQuestion[],
  indicateServerSave: () => void
): SaveQuiz {
  let latestDate = new Date();
  let needsUpdate = false;
  const saveOnServer = (progress: Z.QuizProgressEnvelope) => {
    saveQuizProgress(progress);
    indicateServerSave();
    // if (socket) {
    //   indicateServerSave();
      // send(socket, 'quiz-progress', userId, progress);
    // }
  }
  setInterval(() => {
    if (needsUpdate) {
      const local = getLocalProgress(quizId);
      if (local) {
        saveOnServer(local);
        needsUpdate = false;
      }
    }
  }, SERVER_RETRY_INTERVAL_MILLISECONDS);
  return (responses, flagged, hints) => {
    const now = new Date();
    const progress = Z.quizProgress(quizId, responses, flagged, hints, questions);
    setLocalProgress(progress);
    if (sufficientTimeDifferential(latestDate, now)) {
      saveOnServer(progress);
      latestDate = now;
      needsUpdate = false;
    } else {
      needsUpdate = true;
    }
  }
}

function sufficientTimeDifferential(latest: Date, now: Date): boolean {
  return now.valueOf() - latest.valueOf() >= SERVER_RATE_LIMIT_INTERVAL_MILLISECONDS;
}

export function manualSave(
  userId: string,
  quizId: string,
  responses: Map<number, Z.Response>,
  flagged: Set<string>,
  hints: Map<number, number>,
  questions: Z.QuizQuestion[]
) {
  const progress = Z.quizProgress(quizId, responses, flagged, hints, questions);
  setLocalProgress(progress);
  saveQuizProgress(progress);
  // send(socket, 'quiz-progress', userId, progress);
}
