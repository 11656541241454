import React, { useState, useRef } from 'react';
import H from "highlight.js";
import styles from "./TextEditor.module.scss";
import useFocus from "../focus";

type CodeEntityData = {
  language: string;
};

type LanguagePickerProps = {
  close: () => void;
}

export default function LanguagePicker(props: LanguagePickerProps) {
  const [filterText, setFilterText] = useState("");
  const addLanguageEntity = (language: string) => {};
  const filtered = filterText === "" ? H.listLanguages() : (
    H.listLanguages().filter(language => {
      const name = H.getLanguage(language)!.name;
      const languageKey = language.toLowerCase();
      const nameKey = name!.toLowerCase();
      const filterKey = filterText.toLowerCase();
      return languageKey.indexOf(filterKey) >= 0 || nameKey.indexOf(filterKey) >= 0;
    })
  );
  return (
    <div className={styles['language-picker']}>
      <LanguageFilter text={filterText} setText={setFilterText}/>
      {filtered.map(language => (
        <LanguageRow languageKey={language} key={language} onClick={addLanguageEntity}/>
      ))}
    </div>
  );
}

type LanguageRowProps = {
  languageKey: string;
  onClick: (_: string) => void;
}

function LanguageRow(props: LanguageRowProps) {
  const language = H.getLanguage(props.languageKey);
  return !!language ? (
    <div
      className={styles['language-row']}
      onClick={event => {
        event.stopPropagation();
        props.onClick(props.languageKey);
      }}
    >
      {language.name}
    </div>
  ) : null;
}

type LanguageFilterProps = {
  text: string;
  setText: (_: string) => void;
}

function LanguageFilter(props: LanguageFilterProps) {
  const ref = useRef(null);
  useFocus(ref);
  return (
    <div
      className={styles['language-filter-row']}
      onClick={event => event.stopPropagation()}
    >
      <input
        ref={ref}
        className={styles['language-filter']}
        type="text"
        value={props.text}
        onChange={event => props.setText(event.target.value)}
      />
    </div>
  )
}

type CodeBlockProps = {
  language: string;
  code: string;
}

function CodeBlock(props: CodeBlockProps) {
  return (
    <pre className={styles['code-wrapper']}>
      <code className={props.language}>
        {props.code}
      </code>
    </pre>
  );
}
