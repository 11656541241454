export type GeneralFeatureFilter = boolean | FeatureFilter;
export type FeatureFilter = {
  blanks?: boolean;
  decoration?: DecorationFeatureFilter;
  color?: ColorFeatureFilter;
  information?: InformationFeatureFilter;
  symbolic?: SymbolicFeatureFilter;
}

type SectionKey = keyof FeatureFilter;
type FeatureSection = DecorationFeatureFilter |
                      ColorFeatureFilter |
                      InformationFeatureFilter |
                      SymbolicFeatureFilter;


export type DecorationFeatureFilter = boolean | {
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  strikethrough?: boolean;
};

export type ColorFeatureFilter = boolean | {
  text?: boolean;
  highlight?: boolean;
};

export type InformationFeatureFilter = boolean | {
  link?: boolean;
};

export type SymbolicFeatureFilter = boolean | {
  math?: boolean;
  symbol?: boolean;
  code?: boolean;
};

export function featureSection(filter: GeneralFeatureFilter, key: keyof FeatureFilter): FeatureSection {
  if (filter === false) {
    return false as FeatureSection;
  } else if (filter === true) {
    return true as FeatureSection;
  } else {
    const features = filter as FeatureFilter;
    if (features[key] === undefined) {
      return false as FeatureSection;
    } else {
      return features[key] as FeatureSection;
    }
  }
}

export function coalesceFeature<T extends FeatureSection>(filter: T, key: string): boolean {
  const k = key as keyof T;
  if (typeof filter === 'boolean') {
    return filter;
  } else if (filter[k] === undefined) {
    return false;
  } else {
    return !!filter[k];
  }
}

export const writeInAnswerFeatures: FeatureFilter = {
  symbolic: {
    math: true,
    symbol: true
  }
};

export const selectableAnswerFeatures: FeatureFilter = {
  decoration: true,
  color: true,
  symbolic: true
};

export const fillInTheBlankFeatures: FeatureFilter = {
  blanks: true,
  decoration: true,
  color: true,
  symbolic: true
};

export const genericQuestionPromptFeatures = {
  decoration: true,
  color: true,
  information: true,
  symbolic: true
};

export const fillInTheBlankQuizFeatures = {
  symbolic: {
    symbol: true
  }
};

export const notesFeatures = {
  blanks: false,
  decoration: true,
  color: true,
  symbolic: true,
  information: true
}