import React, { useRef, Ref, useEffect, useState, ReactNode, MouseEvent as RME, memo } from 'react';
import * as S from "./editorState";
import styles from "./TextEditor.module.scss";
import useFocus from "../focus";
import { MathJax } from "better-react-mathjax";
import * as D from "./documentOperations";
import * as K from "../keycode";
import EditorContextValue from "./editorContextValue";
import { EditState, FocusRequest } from "./TextEditor";
import { shuffle } from "utils";
import { ErrorBoundary } from "react-error-boundary";
import useClosable from "closable";

type EditorInternalsProps = {
  reference: Ref<HTMLDivElement>;
  editable: EditState;
  blankMapper?: Map<string, ReactNode>;
  dragging: boolean;
  draggable: boolean;
  requestFocus?: FocusRequest;
  draggableReplacement?: boolean;
}

type EditorMouseEvent = RME<HTMLDivElement, MouseEvent>;

export default function EditorInternals(props: EditorInternalsProps) {
  const editor = S.useEditorContext();
  const sections = S.discretizeStyles(editor.editorState.content);
  const onFocus = () => {}
  const [latentAnchor, setLatentAnchor] = useState<S.CharacterLocality | null>(null);
  const [dragging, setDragging] = useState(false);
  const mouseUp = (event: EditorMouseEvent) => {
    if (!props.draggable) {
      setLatentAnchor(null);
      setDragging(false);
      event.stopPropagation();
      event.preventDefault();
      return false;
    }
    return true;
  };
  const mouseDown = (event: EditorMouseEvent) => {
    if (!props.draggable) {
      setDragging(true);
      const destination = editor.destinationCharacterFromCoordinates(event.clientX, event.clientY);
      setLatentAnchor(destination);
      editor.setAnchor(event.clientX, event.clientY);
      event.stopPropagation();
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  };
  const mouseMove = (event: EditorMouseEvent) => {
    if (!props.draggable) {
      if (latentAnchor) {
        editor.dragSelect(latentAnchor, event.clientX, event.clientY);
      }
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
    return true;
  };
  const mainClass = styles[internalsMainClass(props.editable)];
  const firstTextId = props.requestFocus?.request && editor.firstText()?.fragmentId;
  const indicateFocus = props.requestFocus?.indicateFocus || (() => {});
  return (
    <div
      draggable="false"
      className={mainClass}
      ref={props.reference}
      onFocus={onFocus}
      id={editor.id()}
      onMouseMove={event => mouseMove(event)}
      onMouseDown={event => mouseDown(event)}
      onMouseUp={event => mouseUp(event)}
    >
      {sections.map(section => (
        <EditorRegion
          section={section}
          key={section.fragmentId}
          mouseMove={mouseMove}
          mouseDown={mouseDown}
          mouseUp={mouseUp}
          dragging={dragging}
          externalDragging={props.dragging}
          editable={props.editable}
          blankMapper={props.blankMapper}
          draggable={props.draggable}
          requestFocus={section.fragmentId === firstTextId}
          indicateFocus={indicateFocus}
          draggableReplacement={!!props.draggableReplacement}
        />
      ))}
    </div>
  );
}

function internalsMainClass(editable: EditState): string {
  if (editable === 'uneditable') {
    return 'uneditable-editor-main';
  } else if (editable === 'selectable') {
    return 'selectable-editor-main';
  }
  return 'editor-main';
}

function editorMainLeft(id: string): number | null {
  return document.getElementById(id)?.getBoundingClientRect().x || null;
}

type MouseEventProps = {
  mouseMove: (_: EditorMouseEvent) => boolean;
  mouseDown: (_: EditorMouseEvent) => boolean;
  mouseUp: (_: EditorMouseEvent) => boolean;
  dragging: boolean;
  editable: EditState;
}

type EditorRegionProps = {
  section: S.DiscreteStyle;
  blankMapper?: Map<string, ReactNode>;
  dragging: boolean;
  draggable: boolean;
  draggableReplacement: boolean;
  requestFocus: boolean;
  indicateFocus: () => void;
  externalDragging: boolean;
} & MouseEventProps;

function EditorRegion(props: EditorRegionProps) {
  const blankReplacement = props.blankMapper?.get(props.section.fragmentId);
  if (blankReplacement) {
    return <>{blankReplacement}</>;
  }
  if (S.isSuppliedRegion(props.section)) {
    return (
      <SuppliedRegion
        section={props.section}
        mouseMove={props.mouseMove}
        mouseUp={props.mouseUp}
        mouseDown={props.mouseDown}
        dragging={false}
        editable={'uneditable'}
      />
    );
  }
  if (S.isBlankMathRegion(props.section)) {
    return (
      <BlankMathRegion
        region={props.section}
        mouseMove={props.mouseMove}
        mouseUp={props.mouseUp}
        mouseDown={props.mouseDown}
        dragging={props.dragging}
        editable={props.editable}
        draggable={props.draggable}
        supplied={false}
      />
    )
  }
  if (S.isMathRegion(props.section)) {
    const componentRegion = props.section as S.MathRegion;
    return (
      <MathRegion
        region={componentRegion}
        mouseMove={props.mouseMove}
        mouseUp={props.mouseUp}
        mouseDown={props.mouseDown}
        dragging={props.dragging}
        editable={props.editable}
        metadataTarget={"MATH"}
        draggable={props.draggable}
        blanksOnly={props.editable === 'blanks-only'}
      />
    );
  } else if (S.isLinkRegion(props.section)) {
    const linkRegion = props.section as S.LinkRegion;
    return (
      <LinkComponent
        region={linkRegion}
        editable={props.editable === 'editable'}
      />
    );
  } else {
    const styledRegion = props.section as S.ContentRegion;
    return (
      <StyledRegion
        region={styledRegion}
        mouseMove={props.mouseMove}
        mouseUp={props.mouseUp}
        mouseDown={props.mouseDown}
        dragging={props.dragging}
        draggable={props.draggable}
        editable={props.editable}
        requestFocus={props.requestFocus}
        indicateFocus={props.indicateFocus}
        draggableReplacement={props.draggableReplacement}
        externalDragging={props.externalDragging}
      />
    );
  }
}

type StyleAndRecency = {
  style: object;
};

function agglomeratedStyles(styles: Set<string>, content: S.EditorContent): object {
  const simpleStyles = Array.from(styles).filter(key => {
    const description = content.styles.get(key);
    return !!(description && description.style);
  })
  .map(key => {
    const description = content.styles.get(key);
    return {
      style: description!.style,
    }
  })
  let effectiveStyle = {};
  simpleStyles.forEach(style => {
    effectiveStyle = {
      ...effectiveStyle,
      ...style.style
    }
  });
  return effectiveStyle;
}

type StyledRegionProps = {
  region: S.ContentRegion | S.BlankRegion;
  draggable: boolean;
  draggableReplacement: boolean;
  dragging: boolean;
  requestFocus: boolean;
  indicateFocus: () => void;
  externalDragging: boolean;
} & MouseEventProps;

function StyledRegion(props: StyledRegionProps) {
  const editor = S.useEditorContext();
  const correspondingFragment = editor.contentCache.get(props.region.fragmentId);
  const content = S.currentContent(editor.editorState);
  const effectiveStyle = agglomeratedStyles(props.region.styles, content);
  const id = props.region.fragmentId;
  const fragmentText = D.fragmentContents(id);
  const input = document.getElementById(id);
  const isText = S.isContentRegion(props.region);
  const isBlank = !isText;
  const text = S.isContentRegion(props.region) ? props.region.content : props.region.blank.blankContent;
  const [selected, setSelected] = useState(id === editor.activeComponentId());
  useEffect(() => {
    if (input && props.requestFocus) {
      input.focus();
      props.indicateFocus();
    }
  }, [input, props.requestFocus]);
  const blanksOnly = props.editable === 'blanks-only';
  const editable = props.editable === 'editable' || (blanksOnly && isBlank);
  const empty = fragmentText === "" || fragmentText === null;
  const contentDependentClass = empty ? 'empty-styled-region' : 'styled-region';
  const blankContentDependentClass = empty ? 'empty-blank-region' : 'blank-region';
  const draggableClass = (props.draggable || props.draggableReplacement) ? 'draggable-styled-region' : contentDependentClass;
  const regionClass = styles[isText ? draggableClass : blanksOnly ? 'quiz-blank' : blankContentDependentClass];
  const dragClassesString = dragClasses(props.draggable, props.externalDragging);
  const className = `${regionClass} ${dragClassesString}`;
  const onClickOption = (s: string) => {
    editor.setFragmentContents(id, s, false);
  };
  const closeBlank = () => {
    setSelected(false);
  };
  const textual = (
    <div
        contentEditable={editable}
        style={effectiveStyle}
        className={className}
        id={id}
        onClick={event => {
        }}
        onChange={event => {
        }}
        onInput={event => {
        }}
        onFocus={event => {
        }}
        onMouseDown={event => {
          props.mouseDown(event);
          editor.setActiveFragmentNonFocus(event.clientX, event.clientY);
          return false;
        }}
        onMouseMove={event => {
          props.mouseMove(event);
          return false;
        }}
        onMouseUp={event => {
          props.mouseUp(event);
          setSelected(true);
          return false;
        }}
        onKeyDown={async event => {
          const shift = event.shiftKey;
          const meta = event.metaKey;
          const key = event.keyCode;
          event.stopPropagation();
          event.preventDefault();
          if (K.horizontalArrow(key)) {
            editor.horizontalArrow(key, shift);
          } else if (K.verticalArrow(key)) {
            editor.verticalArrow(key, shift);
          } else if (key === K.TAB) {
            if (blanksOnly) {
              editor.focusNextBlank();
            }
          } else if (K.letter(key, 'a') && meta) {
            editor.selectAll();
          } else if (K.letter(key, 'v') && meta) {
            editor.executePaste(blanksOnly);
          } else if (K.letter(key, 'c') && meta) {
            editor.executeCopy();
          } else if (K.letter(key, 'x') && meta) {
            editor.executeCut(blanksOnly);
          } else if (K.letter(key, 'k') && meta) {
            editor.initializeBlank();
          } else if (K.backspace(key)) {
            editor.deleteSelection(blanksOnly);
          } else if (K.shift(key)) {
          } else if (K.meta(key)) {
          } else if (K.capslock(key)) {
          } else if (K.enter(key)) {
            editor.insertCharacter(blanksOnly, "\n")
          } else {
            editor.insertCharacter(blanksOnly, event.key);
          }
          return false;
        }}
      >
        {text}
      </div>
  )
  if (isText) {
    return textual;
  } else {
    return (
      <BlankWithDropdown
        textual={textual}
        blanksOnly={blanksOnly}
        isMath={false}
        options={(props.region as S.BlankRegion).blank.options}
        selected={selected}
        closeBlank={closeBlank}
        onClickOption={onClickOption}
      />
    );
  }
}

type BlankWithDropdownProps = {
  textual: ReactNode;
  blanksOnly: boolean;
  isMath: boolean;
  options: string[];
  selected: boolean;
  closeBlank: () => void;
  onClickOption: (_: string) => void;
};

function BlankWithDropdown(props: BlankWithDropdownProps) {
  return (
    <div className={styles['fragment-wrapper']}>
      {props.textual}
      {props.selected && props.blanksOnly ? (
        <BlankDropdown
          isMath={props.isMath}
          options={props.options}
          onClick={props.onClickOption}
          close={props.closeBlank}
        />
      ) : null}
    </div>
  )
}

function dragClasses(draggable: boolean, dragging: boolean) {
  const draggingClass = (dragging && draggable) ? styles['dragging-region'] : "";
  const draggableClass = (draggable && !dragging) ? styles['draggable-region'] : "";
  return `${draggingClass} ${draggableClass}`;
}

type MathRegionProps = {
  region: S.MathRegion;
  metadataTarget: S.MetadataType;
  draggable: boolean;
  dragging: boolean;
  blanksOnly: boolean;
} & MouseEventProps;

function MathRegion(props: MathRegionProps) {
  const componentId = props.region.fragmentId;
  const editor = props.draggable ? null : S.useEditorContext();
  const active = (props.editable !== 'selectable' && props.editable !== 'uneditable') && props.region.fragmentId === editor?.activeComponentId();
  if (S.isMathRegion(props.region)) {
    const basicClass = editor?.componentSelected(componentId) && (props.editable !== 'uneditable') && (props.editable !== 'selectable') && !props.draggable ? styles['active-component'] : styles['inactive-component'];
    const blanksOnlyClass = (props.blanksOnly && !props.draggable) ? styles['blanks-only-math'] : "";
    const className = `${basicClass} ${dragClasses(props.draggable, props.dragging)} ${blanksOnlyClass}`;
    return (
      <div className={className} id={props.region.fragmentId}>
        <MathDisplay
          content={props.region.mathContent}
          metadataTarget={props.metadataTarget}
          draggable={props.draggable}
          fragmentId={props.region.fragmentId}
          editor={editor}
          editable={props.editable}
        />
      </div>
    );
  }
  return null;
}

type MathDisplayProps = {
  metadataTarget: S.MetadataType;
  content: string;
  draggable: boolean;
  fragmentId: string;
  editor: EditorContextValue | null;
  editable: EditState;
}

const MathDisplay = memo(function MD(props: MathDisplayProps) {
  const mathContent = `\\(${props.content}\\)`;
  return (
    <MathJax
      inline={true}
      hideUntilTypeset={'every'}
      renderMode="post"
      dynamic={true}
      onClick={() => {
        if (!props.draggable && props.editor && mathEditable(props.editable)) {
          props.editor.setActiveComponent(props.fragmentId, props.metadataTarget);
        }
      }}

    >{mathContent}</MathJax>
  );
});

function mathEditable(editable: EditState): boolean {
  return editable === 'blanks-only' || editable === 'editable';
}

type SimpleMathDisplayProps = {
  content: string;
}

function SimpleMathDisplay(props: SimpleMathDisplayProps) {
  return <MathJax>{`\\(${props.content}\\)`}</MathJax>;
}

function MathError() {
  return <div>...</div>;
}

type BlankMathRegionProps = {
  region: S.BlankMathRegion;
  draggable: boolean;
  dragging: boolean;
  supplied: boolean;
} & MouseEventProps;

function BlankMathRegion(props: BlankMathRegionProps) {
  const editor = S.useEditorContext();
  const className = styles[props.editable === 'blanks-only' ? 'quiz-blank-math-region' : 'blank-math-region'];
  const activeFragment = props.region.fragmentId === editor.activeComponentId();
  const [selected, setSelected] = useState(activeFragment);
  const activeClass = activeFragment ? styles["selected-math-blank"] : "";
  const classes = `${className} ${activeClass}`;
  const id = props.region.fragmentId;
  const textual = (
    <MathRegion
      region={S.toMathRegion(props.region)}
      mouseMove={props.mouseMove}
      mouseUp={props.mouseUp}
      mouseDown={props.mouseDown}
      dragging={props.dragging}
      draggable={props.draggable}
      editable={props.editable}
      metadataTarget={"BLANK_MATH"}
      blanksOnly={props.editable === 'blanks-only'}
    />
  );
  return (
    <div
      className={classes}
      onClick={() => {
        if (!props.supplied) {
          setSelected(true);
          editor.setActiveComponent(props.region.fragmentId, "BLANK_MATH");
        }
      }}
    >
      {props.editable === 'blanks-only' ? (
        <BlankWithDropdown
          isMath={true}
          textual={textual}
          options={props.region.blank.options}
          onClickOption={m => editor.setFragmentContents(id, m, false)}
          closeBlank={() => setSelected(false)}
          blanksOnly={true}
          selected={selected}
        />
      ) : textual}
    </div>
  );
}

function forceContent(section: S.DiscreteStyle): string {
  if (section && S.isContentRegion(section)) {
    return section.content;
  }
  return "";
}

type LinkComponentProps = {
  region: S.LinkRegion;
  editable: boolean;
}

function LinkComponent(props: LinkComponentProps) {
  const editor = S.useEditorContext();
  return (
    <div
      className={styles['link-component']}
      onClick={() => editor.setActiveComponent(props.region.fragmentId, 'LINK')}
    >{props.region.displayText}</div>
  )
}

type BlankDropdownProps = {
  options: string[];
  isMath: boolean;
  onClick: (_: string) => void;
  close: () => void;
};

function BlankDropdown(props: BlankDropdownProps) {
  const ref = useRef(null);
  useClosable(ref, () => props.close());
  return props.options.length > 0 ? (
    <div className={styles['blank-dropdown']} ref={ref}>
      {props.options.map(option => (
        <div className={styles['blank-option']} onClick={() => props.onClick(option)}>
          {props.isMath ? <SimpleMathDisplay content={option}/> : option}
        </div>
      ))}
    </div>
  ) : null;
}

type SuppliedRegionProps = {
  section: S.SuppliedRegion;
} & MouseEventProps;

function SuppliedRegion(props: SuppliedRegionProps) {
  const content = props.section.suppliedContent;
  return props.section.isMath ? (
    <BlankMathRegion
      region={S.suppliedToBlankMathRegion(props.section)}
      draggable={false}
      dragging={false}
      mouseMove={event => false}
      mouseUp={event => false}
      mouseDown={event => false}
      editable={'uneditable'}
      supplied={true}
    />
  ) : (
    <StyledRegion
      region={S.suppliedToBlankRegion(props.section)}
      draggable={false}
      draggableReplacement={false}
      dragging={false}
      mouseMove={props.mouseMove}
      mouseUp={props.mouseUp}
      mouseDown={props.mouseDown}
      editable={'uneditable'}
      requestFocus={false}
      indicateFocus={() => {}}
      externalDragging={false}
    />
  )
}