export const BACKSPACE = 8;
export const TAB = 9;
export const ENTER = 13;
export const SHIFT = 16;
export const CAPS_LOCK = 20;
export const LEFT_ARROW = 37;
export const UP_ARROW = 38;
export const RIGHT_ARROW = 39;
export const DOWN_ARROW = 40;
export const META = 91;

export function horizontalArrow(key: number): boolean {
  return key === LEFT_ARROW || key === RIGHT_ARROW;
}

export function verticalArrow(key: number): boolean {
  return key === UP_ARROW || key === DOWN_ARROW;
}

export function letter(key: number, letter: string): boolean {
  return key === letter.toUpperCase().charCodeAt(0);
}

export function backspace(key: number): boolean {
  return key === BACKSPACE;
}

export function shift(key: number): boolean {
  return key === SHIFT;
}

export function enter(key: number): boolean {
  return key === ENTER;
}

export function meta(key: number): boolean {
  return key === META;
}

export function capslock(key: number): boolean {
  return key === CAPS_LOCK;
}
