import React, { useEffect, useState, ReactNode } from 'react';
import { retrieveQuestionContext, loadAllQuestionVersions, success } from "network";
import { useParams, Link, useSearchParams } from "react-router-dom";
import QuestionEditor, { DisplayContext } from "QuestionEditor";
import {
  QuestionEditContext,
  QuestionContext,
  QuestionStatistics,
  QuizAppearance,
  getByVersion,
  AggregateQuizQuestionStatistics,
  averagePoints,
  partiallyCorrect
} from "questionContext";
import styles from "./QuestionPage.module.scss";
import { SubmissionResult, QuestionSubmissionIndicator } from "SubmissionResult";
import { g, gt, gd, gp, GlossaryKey } from "language";
import { Question, functionalityByType, QuestionTypeView, partialCreditPossible } from "questionTypes";
import { useUser } from "user";
import EditIcon from "commonComponents/EditIcon";
import QuestionTimeline, { versionResetter } from "QuestionTimeline";
import { labelType } from "QuestionSettings";
import FormSection from "FormSection";
import { HintsDisplay, HintsRevealed, Hints } from "QuestionHints";
import { ImageDisplayList, handlesToLabeledFiles } from "pictures";
import { TagListDisplay } from "tags";
import { SettingsDisplay } from "QuestionSettings";
import { NotesDisplay } from "QuestionNotes";
import { SummarizedRow } from "SummarizedTable";
import { setToggle } from "logicUtils";
import {
  questionPoints, correctnessDynamicStyling, questionPointDisplay, scoreDecimalDisplay
} from "questionCorrectness";
import { useNiceDateFromString, useNiceDateAndTimeSinceFromString } from "timeUtils";
import { QuizTagDescriptor } from "QuizPage";
import PointsBar from "commonComponents/PointsBar";
import ExpansionIndicator from "ExpansionIndicator";
import SortSpecifier, { SortPriority } from "commonComponents/SortSpecifier";
import { CachedPartialLoadTable, IndexedQuery } from "PartialLoadTable";
import AbsenceIndicator from "commonComponents/AbsenceIndicator";

export default function QuestionPage() {
  const userContext = useUser();
  const questionId = useParams().question_id;
  const editDefault = window.location.href.includes("editing=true");
  const [editing, setEditing] = useState(editDefault || false);
  const [questionContext, setQuestionContext] = useState<QuestionEditContext | null>(null);
  const [quizHistory, setQuizHistory] = useState<QuestionStatistics | null>(null);
  const [baseVersion, setBaseVersion] = useState<number>(questionContext?.question.version || 1);
  useEffect(() => {
    (async () => {
      if (questionContext === null) {
        const context = await retrieveQuestionContext(questionId);
        setQuestionContext(context.questionContext);
        setQuizHistory(context.statistics);
        setBaseVersion(context.questionContext.question.version);
      }
    })();
  }, [questionId, userContext.user.userId()]);
  const onEditCompletion = (q: Question) => {
    if (questionContext) {
      setBaseVersion(q.version);
      setQuestionContext({
        ...questionContext,
        question: q,
        versions: questionContext.versions.concat([q])
      });
    }
  }
  return (
    <div className={styles['question-page']}>
      {(questionContext && quizHistory) ? (
        <QuestionEditableDisplay
          editing={editing}
          setEditing={setEditing}
          onCompletion={onEditCompletion}
          displayContext={'full-page'}
          questionContext={questionContext}
          setQuestionContext={setQuestionContext}
          baseVersion={baseVersion}
          setBaseVersion={setBaseVersion}
          quizHistory={quizHistory}
        />
      ) : <QuestionNotFoundIndicator/>}
    </div>
  );
}

function QuestionNotFoundIndicator() {
  return <h2>{g('questionNotFound')}</h2>
}

type EditableQuestionDisplayProps = {
  editing: boolean;
  setEditing: (_: boolean) => void;
  onCompletion: (_: Question) => void;
  displayContext: DisplayContext;
  questionContext: QuestionEditContext;
  quizHistory: QuestionStatistics;
  setQuestionContext: (_: QuestionEditContext) => void;
  baseVersion: number;
  setBaseVersion: (_: number) => void;
  additionalClasses?: string;
  editOverride?: () => void;
}

export function QuestionEditableDisplay(props: EditableQuestionDisplayProps) {
  const userContext = useUser();
  const resetVersion = versionResetter(
    props.questionContext,
    props.setQuestionContext,
    props.setBaseVersion
  );
  const editingIndicator = g('questionEditingIndicator');
  const editingText = props.editing ? ` ${editingIndicator}` : "";
  const headerText = `${gt('questionWithOrdinal', [props.questionContext?.question.ordinal.toString() || ""])}${editingText}`;
  const viewClasses = props.displayContext === 'inline' ? styles['inline-question-view'] : '';
  const iconClick = props.editOverride || props.setEditing;
  return (
    <div className={props.additionalClasses || ""}>
      <div className={styles['top-page']}>
        <h2>{headerText}</h2>
        <EditIcon editing={props.editing} setEditing={iconClick} additionalClasses={styles['edit-icon']}/>
      </div>
      {props.editing ? (
        <QuestionEditorManager
          displayContext={props.displayContext}
          onCompletion={props.onCompletion}
          questionContext={props.questionContext}
          setQuestionContext={props.setQuestionContext}
          baseVersion={props.baseVersion}
          setBaseVersion={props.setBaseVersion}
          onSelectVersion={resetVersion}
        />
      ) : props.questionContext ? (
        <div className={viewClasses}>
          <QuestionView
            questionContext={props.questionContext}
            setQuestionContext={props.setQuestionContext}
            baseVersion={props.baseVersion}
            setBaseVersion={props.setBaseVersion}
            quizHistory={props.quizHistory}
            onSelectVersion={resetVersion}
          />
        </div>
        ) : null
      }
    </div>
  );
}

type QuestionEditorManagerProps = Omit<EditableQuestionDisplayProps, 'editing' | 'setEditing' | 'quizHistory'> & {
  onSelectVersion: (_: number) => void;
};

export function QuestionEditorManager(props: QuestionEditorManagerProps) {
  const [submissionResult, setSubmissionResult] = useState<SubmissionResult>('none');
  const header = g('editQuestionHeader');
  const notFound = g('questionAbsentMessage');
  const wrapperClass = styles[props.displayContext === 'full-page' ? 'full-page-wrapper' : 'inline-wrapper'];
  return props.questionContext ? (
    <div className={wrapperClass}>
      <QuestionSubmissionIndicator
        result={submissionResult}
        submittedQuestion={null}
        close={() => setSubmissionResult('none')}
      />
      <QuestionEditor
        questionContext={props.questionContext}
        setQuestionContext={props.setQuestionContext}
        setSubmissionResult={setSubmissionResult}
        displayContext={props.displayContext}
        onCompletion={props.onCompletion}
        baseVersion={props.baseVersion}
        setBaseVersion={props.setBaseVersion}
        onSelectVersion={props.onSelectVersion}
      />
    </div>
  ) : (
    <>{notFound}</>
  );
}

type QuestionViewProps = {
  questionContext: QuestionEditContext;
  setQuestionContext: (_: QuestionEditContext) => void;
  baseVersion: number;
  setBaseVersion: (_: number) => void;
  quizHistory: QuestionStatistics;
  onSelectVersion: (_: number) => void;
};

export function QuestionView(props: QuestionViewProps) {
  const question = props.questionContext.question;
  const functionality = functionalityByType(question.questionType);
  const classes = `${styles['question-view']}`;
  const [forceLoad, setForceLoad] = useState(false);
  return (
    <div className={classes}>
      <div className={styles['question-metadata']}>
        <QuestionTimeline
          onSelectVersion={props.onSelectVersion}
          allVersions={props.questionContext.versions}
          currentVersion={props.baseVersion}
        />
        <FormSection title={g('questionTypeLabel')}>
          <QuestionTypeView questionType={question.questionType}/>
        </FormSection>
        <FormSection title={g('questionBodySectionHeader')}>
          {functionality.bodyDisplay({
            question: question.body,
            labelType: labelType(question.settings)
          })}
        </FormSection>
       <FormSection title={g('hintsLabel')}>
          <HintsDisplay hints={question.hints}/>
        </FormSection>
        <FormSection title={g('imageLabel')}>
          <ImageDisplayList
            files={handlesToLabeledFiles(question.images)}
            setFiles={() => {}}
            editable={false}
          />
        </FormSection>
        <FormSection title={g('tagsHeader')}>
          <TagListDisplay tagIds={question.tagIds} indicateNoTags={true}/>
        </FormSection>
        <FormSection title={g('settingsSectionHeader')}>
          <SettingsDisplay questionType={question.questionType} settings={question.settings}/>
        </FormSection>
        <FormSection title={g('notesSectionHeader')}>
          <NotesDisplay text={question.notes} includeHeader={false}/>
        </FormSection>
      </div>
      <QuestionHistoryView quizHistory={props.quizHistory} questionEditContext={props.questionContext}/>
    </div>
  );
}

type QuestionHistoryViewProps = {
  questionEditContext: QuestionEditContext;
  quizHistory: QuestionStatistics;
}

function QuestionHistoryView(props: QuestionHistoryViewProps) {
  const [openResults, setOpenResults] = useState<Set<string>>(new Set());
  const [sortAttribute, setSortAttribute] = useState<SortDirectiveAttribute>('date');
  const [datePriority, setDatePriority] = useState<SortPriority>('greatest');
  const [pointsPriority, setPointsPriority] = useState<SortPriority>('greatest');
  const constructDirective = () => {
    return {
      attribute: sortAttribute,
      priority: sortAttribute === 'date' ? datePriority : pointsPriority
    };
  };
  const [sortDirective, setSortDirective] = useState<SortDirective>(constructDirective());
  const [loadCounter, setLoadCounter] = useState<number>(0);
  function setWithTableReset<T>(setter: (_: T) => void): ((_: T) => void) {
    return (t: T) => {
      setter(t);
      setLoadCounter(loadCounter + 1);
    }
  };
  const appearances = props.quizHistory.appearances;
  const historyExists = appearances.length > 0;
  const expandContract = (quizId: string) => {
    setToggle(quizId, openResults);
    setOpenResults(new Set(openResults));
  }
  const pcp = props.questionEditContext.versions.filter(v => partialCreditPossible(v.body)).length > 0;
  const aggregate = props.quizHistory.aggregate;
  const onFullScroll = () => setLoadCounter(loadCounter + 1);
  return (
    <div className={styles['question-history']}>
      <h3>{g('questionResultsDisplay')}</h3>
      <FormSection title={g('questionResultsSummary')}>
        {historyExists ? (
          <>
            {aggregate ? (
              <AggregateQuizStatisticsSummary statistics={aggregate} showPartialCredit={pcp}/>
            ) : null}
            <HistorySortSection
              selectedSort={sortAttribute}
              setSelectedSort={setWithTableReset(setSortAttribute)}
              pointsPriority={pointsPriority}
              setPointsPriority={setWithTableReset(setPointsPriority)}
              datePriority={datePriority}
              setDatePriority={setWithTableReset(setDatePriority)}
            />
          </>
        ) : <AbsenceIndicator glossaryKey={'noQuizzesForQuestion'}/>
      }
      </FormSection>
      {historyExists ? (
        <CachedPartialLoadTable<QuizAppearance, SortDirective, null, LengthRememberingSlice<QuizAppearance>>
          rowId={a => a.quiz.quizId}
          queryKey={serializeDirective}
          constructQuery={constructDirective}
          currentQuery={sortDirective}
          setCurrentQuery={setSortDirective}
          load={async (d: IndexedQuery<SortDirective>) => {
            return {
              slice: sortByDirective(d.parameters, appearances).slice(d.indexLimits.startIndex, d.indexLimits.endIndex),
              originalLength: appearances.length
            };
          }}
          extractFromLoad={lrs => ({
            rows: lrs.slice,
            metadata: null,
            totalRows: lrs.originalLength
          })}
          row={(appearance, index, globalIndex, _, allAppearances) => (
            <QuizAppearanceRow
              appearance={appearance}
              expandContract={expandContract}
              open={openResults.has(appearance.quiz.quizId)}
              aboveOpen={index > 0 && openResults.has(allAppearances[index - 1].quiz.quizId)}
              key={appearance.quiz.quizId}
              questionEditContext={props.questionEditContext}
            />
          )}
          header={() => <HistoryHeader/>}
          loadCounter={loadCounter}
          variant={'paginated'}
          headerClasses={styles['history-paginated-header']}
          resetCounter={0}
          onFullScroll={onFullScroll}
        />
      ) : null}
    </div>
  );
}

type LengthRememberingSlice<R> = {
  originalLength: number;
  slice: R[];
};

type HistoryHeaderProps = {};

function HistoryHeader(props: HistoryHeaderProps) {
  return (
    <div className={styles['history-header']}>
      <HistoryHeaderLabel glossaryKey={'quizNumberLabel'}/>
      <HistoryHeaderLabel glossaryKey={'quizStartedLabel'}/>
      <HistoryHeaderLabel glossaryKey={'versionHeader'}/>
      <HistoryHeaderLabel glossaryKey={'questionPointsHeader'}/>
    </div>
  );
}

type HistoryHeaderLabelProps = {
  glossaryKey: GlossaryKey;
}

function HistoryHeaderLabel(props: HistoryHeaderLabelProps) {
  return (
    <div className={styles['history-header-label']}>{g(props.glossaryKey)}</div>
  )
}

type SortDirective = {
  attribute: SortDirectiveAttribute;
  priority: SortPriority;
}

type SortDirectiveAttribute = 'date' | 'points';

function sortByDirective(directive: SortDirective, appearances: QuizAppearance[]): QuizAppearance[] {
  const orderedPreliminary = directive.attribute === 'date' ? (
    appearances.sort((a, b) => new Date(a.quiz.startTime).getTime() - new Date(b.quiz.startTime).getTime())
  ) : directive.attribute === 'points' ? (
    appearances.sort((a, b) => questionPoints(a.questionResult.score) - questionPoints(b.questionResult.score))
  ) : appearances;
  return directive.priority === 'least' ? orderedPreliminary : orderedPreliminary.reverse();
}

function serializeDirective(d: SortDirective): string {
  return `${d.attribute}|${d.priority}`;
}

type HistorySortSectionProps = {
  selectedSort: SortDirectiveAttribute;
  setSelectedSort: (_: SortDirectiveAttribute) => void;
  datePriority: SortPriority;
  setDatePriority: (_: SortPriority) => void;
  pointsPriority: SortPriority;
  setPointsPriority: (_: SortPriority) => void;
}

function HistorySortSection(props: HistorySortSectionProps) {
  return (
    <div className={styles['sort-section']}>
      <SortSpecifier
        selected={props.selectedSort === 'date'}
        priority={props.datePriority}
        setPriority={props.setDatePriority}
        onClick={() => props.setSelectedSort('date')}
        sortDescriptorKey={'sortByDate'}
        decreasingKey={'dateSortDecreasing'}
        increasingKey={'dateSortIncreasing'}
      />
      <SortSpecifier
        selected={props.selectedSort === 'points'}
        priority={props.pointsPriority}
        setPriority={props.setPointsPriority}
        onClick={() => props.setSelectedSort('points')}
        sortDescriptorKey={'sortByPoints'}
        decreasingKey={'pointSortDecreasing'}
        increasingKey={'pointSortIncreasing'}
      />
    </div>
  )
}

type QuizAppearanceRowProps = {
  appearance: QuizAppearance;
  expandContract: (_: string) => void;
  open: boolean
  aboveOpen: boolean;
  questionEditContext: QuestionEditContext;
};

function QuizAppearanceRow(props: QuizAppearanceRowProps) {
  const quiz = props.appearance.quiz;
  const question = props.appearance.questionResult;
  const score = question.score;
  const dynamicStyle = correctnessDynamicStyling(score);
  return (
    <SummarizedRow
      open={props.open}
      aboveOpen={props.aboveOpen}
      expandContract={() => props.expandContract(props.appearance.quiz.quizId)}
      additionalStyle={dynamicStyle}
      additionalClasses={styles['quiz-result-row']}
      summary={
        <div className={styles['history-row']}>
          <QuizHistoryEntry>
            <Link to={`/quiz-results/${props.appearance.quiz.quizId}`}>{quiz.ordinal}</Link>
          </QuizHistoryEntry>
          <QuizHistoryEntry additionalClasses={styles['quiz-start-time']}>
            {useNiceDateFromString(quiz.startTime)}
          </QuizHistoryEntry>
          <QuizHistoryEntry>{question.version}</QuizHistoryEntry>
          <QuizHistoryEntry>{questionPointDisplay(score)}</QuizHistoryEntry>
        </div>
      }
      detail={
        <QuizAppearanceDetail
          questionContext={props.questionEditContext}
          appearance={props.appearance}
        />
      }
    />
  );
}

type QuizHistoryEntryProps = {
  children: ReactNode;
  additionalClasses?: string;
};

function QuizHistoryEntry(props: QuizHistoryEntryProps) {
  const className = `${styles['quiz-history-entry']} ${props.additionalClasses || ""}`
  return (
    <div className={className}>{props.children}</div>
  )
}

type QuizAppearanceDetailProps = {
  questionContext: QuestionEditContext;
  appearance: QuizAppearance;
};

function QuizAppearanceDetail(props: QuizAppearanceDetailProps) {
  const effectiveQuestion = getByVersion(props.questionContext, props.appearance.questionResult.version);
  const quizSpecific = props.appearance.questionResult;
  if (effectiveQuestion === null) {
    return null;
  }
  const effectiveQuestionContext = {
    ...props.questionContext,
    question: effectiveQuestion
  };
  const completedQuizQuestion = {
    question: effectiveQuestionContext,
    quizSpecific: props.appearance.questionResult
  };
  const functionality = functionalityByType(props.questionContext.question.questionType);
  const questionSlot = functionality.resultDetailQuestion({
    question: props.questionContext.question.body
  });
  const userContext = useUser();
  const answerSlot = functionality.resultDetailAnswer({
    question: completedQuizQuestion,
    open: true,
    latest: props.questionContext.question,
    addLatest: () => {},
    editing: false,
    toggleEditing: () => {},
    allTags: userContext.user.allTags()
  });
  const quiz = props.appearance.quiz;
  return (
    <div className={styles['quiz-appearance-detail']}>
      <div className={styles['history-question-detail']}>
        <label>{g('historyQuestionDetail')}</label>
        <div className={styles['question-slot-wrapper']}>
          {questionSlot}
        </div>
        <div className={styles['question-slot-wrapper']}>
          {answerSlot}
        </div>
        <HistoryHintsSection hints={effectiveQuestion.hints} revealed={quizSpecific.hintsRevealed}/>
      </div>
      <div>
        <label>{g('historyQuizDetail')}</label>
        <PointsBar
          score={quiz.totalScore}
          pointsEarnedKey={'point'}
          pointsPossibleKey={'questionMinor'}
          additionalClasses={styles['points-bar']}
        />
        <QuizTagDescriptor
          tagPattern={quiz.tagPattern}
          tagIds={quiz.tagIds}
          excludedTagIds={quiz.excludedTagIds}
        />

      </div>
    </div>
  );
}

type HistoryHintsSectionProps = {
  hints: Hints;
  revealed: number;
}

function HistoryHintsSection(props: HistoryHintsSectionProps) {
  const [open, setOpen] = useState(false);
  return props.hints.hints.length > 0 ? (
    <div>
      <span className={styles['hints-indicator']} onClick={() => setOpen(!open)}>
        <label>{gt('hintsRevealedNumeric', [props.revealed, props.hints.hints.length])}</label>
        <ExpansionIndicator open={open} />
      </span>
      {open ? <HintsRevealed hints={props.hints} revealed={props.revealed}/> : null}
    </div>
  ) : null;
}

type AggregateQuizStatisticsSummaryProps = {
  statistics: AggregateQuizQuestionStatistics;
  showPartialCredit: boolean;
};

function AggregateQuizStatisticsSummary(props: AggregateQuizStatisticsSummaryProps) {
  const aggregateSectionClass = styles['aggregate-section'];
  const stats = props.statistics;
  return (
    <div className={styles['aggregate-wrapper']}>
      <label>{g('aggregateQuizStatisticsLabel')}</label>
      <div className={styles['aggregate-display']}>
        <div className={styles['aggregate-section-columnar']}>
          <label>{g('firstQuizAppearanceLabel')}</label>
          <span>{useNiceDateAndTimeSinceFromString(stats.firstAppearance)}</span>
          <label>{g('latestQuizAppearanceLabel')}</label>
          <span>{useNiceDateAndTimeSinceFromString(stats.lastAppearance)}</span>
        </div>
        <div className={aggregateSectionClass}>
          <label>{g('totalPointsLabel')}</label>
          <span>{stats.totalPoints?.toFixed(2) || 0}</span>
          <label>{g('totalQuizAppearancesLabel')}</label>
          <span>{stats.totalAppearances}</span>
          <label>{g('averagePointsEarnedLabel')}</label>
          <span>{averagePoints(stats).toFixed(2)}</span>
        </div>
        <div className={`${aggregateSectionClass} ${styles['last-section']}`}>
          <label>{g('totalFullyCorrectLabel')}</label>
          <span>{stats.fullyCorrect}</span>
          {props.showPartialCredit ? (
            <>
              <label>{g('totalPartiallyCorrectLabel')}</label>
              <span>{partiallyCorrect(stats)}</span>
            </>
          ) : null}
          <label>{g('totalFullyIncorrectLabel')}</label>
          <span>{stats.fullyIncorrect}</span>
        </div>
      </div>
    </div>
  );
}
