import React, { useState, ReactNode } from 'react';
import { CompletedQuizQuestion } from "./quizResults";
import QuestionResultBox from "./QuestionResultBox";
import { TextDisplay, Content, stringRepresentation, constructContent, rawStateFromString } from "TextEditor";
import * as Q from "questionTypes";
import { g, gt, GlossaryKey } from "language";
import styles from "./QuizResultsPage.module.scss";
import { LabeledFileHandle, ImageHandleDisplay } from "pictures";
import * as R from "./quizResults";
import ExpansionIndicator from "ExpansionIndicator";
import { QuestionSettingsModel, potentialErrorMargin, labelType, OrderingModel } from "QuestionSettings";
import { AnswerLabelDisplay, AnswerLabel } from "labels";
import { Tag } from "tags";
import * as B from "questionBodyDisplay";
import { matchPercentage } from "./quizResultOperations";
import { zip } from "utils";

export type QuestionResultDetailProps = {
  question: CompletedQuizQuestion;
  open: boolean;
  latest: Q.Question | null;
  addLatest: (_: Q.Question) => void;
  allTags: Tag[];
  editing: boolean;
  toggleEditing: (_: boolean) => void;
}

export function ResultDetail(props: QuestionResultDetailProps) {
  const questionType = props.question.question.question.questionType;
  const functionality = Q.functionalityByType(questionType);
  return (
    <QuestionResultBox
      question={props.question}
      open={props.open}
      questionSlot={functionality.resultDetailQuestion({
        question: props.question.question.question.body
      })}
      answerSlot={functionality.resultDetailAnswer(props)}
      latest={props.latest}
      addLatest={props.addLatest}
      allTags={props.allTags}
      editing={props.editing}
      toggleEditing={props.toggleEditing}
    />
  )
}

export function WriteInComparison(props: QuestionResultDetailProps) {
  const questionBody = props.question.question.question.body as Q.WriteIn;
  const explanation = props.question.quizSpecific.scoreExplanation as R.WriteInExplanation;
  const answerCount = questionBody.answers.length;
  const singleAnswerHeader = g('answer');
  const multipleAnswerHeader = g('answers');
  const header = answerCount === 1 ? singleAnswerHeader : multipleAnswerHeader;
  const settings = props.question.question.question.settings;
  return (
    <div>
      <B.AnswerHeader>{header}</B.AnswerHeader>
      {explanation.answers.map((answer, index) => (
        <div key={index}>
          <AnswerLabelDisplay position={index} labelType={labelType(settings)}/>
          <TextAnswerDisplay
            response={answer.response.content}
            correctAnswer={answer.match?.matchedAnswer.content || null}
            edits={answer.match?.description || null}
            inline={false}
            settings={settings}
            inOrder={answer.match?.inOrder}
          />
        </div>
      ))}
    </div>
  );
}

export function FillInTheBlankComparison(props: QuestionResultDetailProps) {
  // return <div>fill in the blank comparison</div>
  const questionBody = props.question.question.question.body as Q.FillInTheBlank;
  const explanation = props.question.quizSpecific.scoreExplanation as R.FillInTheBlankExplanation;
  const settings = props.question.question.question.settings;
  const zipped = zip(questionBody.body.fragments, explanation.structure);
  const blankMapper = new Map<string, ReactNode>();
  const filtered = zipped
    .forEach(([questionBodyFragment, explanationFragment]) => {
      if (R.isBlankElementExplanation(explanationFragment)) {
        blankMapper.set(questionBodyFragment.fragmentId, (
          <TextAnswerDisplay
            response={rawStateFromString(explanationFragment.blankResponse)}
            correctAnswer={rawStateFromString(explanationFragment.blankAnswer)}
            edits={explanationFragment.matchDescription}
            inline={true}
            settings={settings}
          />
        )
      )
    }
  });
  return (
    <div className={styles['fill-in-the-blank-comparison']}>
      <TextDisplay
        value={questionBody.body}
        blankMapper={blankMapper}
      />
      {/*explanation.structure.map(element => (
        <FillInTheBlankElement element={element} settings={settings}/>
      ))*/}
    </div>
  );
}

type TextAnswerDisplayProps = {
  response: Content;
  correctAnswer: Content | null;
  edits: R.MatchDescription | null;
  inline: boolean;
  settings: QuestionSettingsModel;
  inOrder?: boolean | null;
};

function TextAnswerDisplay(props: TextAnswerDisplayProps) {
  const inOrder = props.inOrder !== false;
  const inlineClass = props.inline ? styles['text-answer-inline'] : '';
  const outerClass = `${styles['text-answer']} ${inlineClass}`;
  const edits = props.edits?.comparison.explanation.edits || [];
  const correctText = g('correctIndicator');
  const incorrectText = g('incorrectIndicator');
  const accuracyState = (inOrder && props.edits?.correct) ? 'correctly-selected' : 'incorrectly-selected';
  const text = props.edits === null ? '' : props.edits.correct ? correctText : incorrectText;
  const textClass = styles[`${accuracyState}-text`];
  const boxClass = styles[`${accuracyState}-box`];
  const distance = props.edits?.comparison.difference || 0;
  const fullyCorrect = !!(props.edits?.correct && distance === 0);
  return (
    <div className={inlineClass}>
      <div className={textClass}>{text}</div>
      <div className={boxClass}>
        <OutOfOrderIndicator
          inOrder={props.inOrder}
          correct={props.edits?.correct || false}
          distance={distance}
          correctAnswer={props.correctAnswer}
        />
        <AnswerResponseComparison
          answer={props.correctAnswer}
          response={props.response}
          fullyCorrect={fullyCorrect}
        />
        {(edits.length > 0 && props.correctAnswer) ? (
          <EditInstructions
            edits={edits}
            response={props.response}
            answer={props.correctAnswer}
            settings={props.settings}
            distance={distance}
            inline={props.inline}
          />
        ) : null}
      </div>
    </div>
  )
}

// type FillInTheBlankElementProps = {
//   element: R.FillInTheBlankExplanationElement;
//   settings: QuestionSettingsModel;
// };

// function FillInTheBlankElement(props: FillInTheBlankElementProps) {
//   if (R.isTextElement(props.element)) {
//     return <B.TextElementForm content={props.element.text} singleLine={false}/>
//   }
//   if (R.isSuppliedElement(props.element)) {
//     return <B.SuppliedElementForm content={props.element.suppliedAnswer} singleLine={false}/>
//   }
//   if (R.isBlankElementExplanation(props.element)) {
//     return (
//       <TextAnswerDisplay
//         response={props.element.blankResponse}
//         correctAnswer={props.element.blankAnswer}
//         edits={props.element.matchDescription}
//         inline={true}
//         settings={props.settings}
//       />
//     );
//   }
//   return null;
// }

export function MultipleChoiceComparison(props: QuestionResultDetailProps) {
  const questionBody = props.question.question.question.body as Q.MultipleChoice;
  const selection = props.question.quizSpecific.scoreExplanation as R.MultipleChoiceExplanation;
  const selected = (answer: Q.SelectableAnswer) => {
    return !!selection.answer && Q.selectableAnswerEquals(answer, selection.answer);
  };
  const selectionKey = 'singleSelectionIndicator';
  const correct = questionBody.correctAnswer;
  const correctAnswerList = [correct];
  const correctSlot = (
    <SelectableAnswerDisplay
      answer={correct}
      selectionState={selectionState(correct, selected(correct), correctAnswerList)}
      selectionText={selectionKey}
    />
  );
  const otherSlot = questionBody.otherAnswers.map((answer, index) => (
    <SelectableAnswerDisplay
      answer={answer}
      selectionState={selectionState(answer, selected(answer), correctAnswerList)}
      selectionText={selectionKey}
      key={index}
    />
  ));
  return (
    <B.MultipleChoiceOutlineDisplay
      correctSlot={correctSlot}
      otherSlot={<>{otherSlot}</>}
    />
  )
}

export function MultiSelectComparison(props: QuestionResultDetailProps) {
  const questionBody = props.question.question.question.body as Q.MultiSelect;
  const explanation = props.question.quizSpecific.scoreExplanation as R.MultiSelectExplanation;
  const selectedAnswers = explanation.correctSelected.concat(explanation.otherSelected);
  const selectedAnswerSet = new Set(selectedAnswers.map(Q.stringRepresentation));
  const selected = (answer: Q.SelectableAnswer) => {
    return selectedAnswerSet.has(Q.stringRepresentation(answer));
  };
  const selectionKey = 'multiSelectionIndicator';
  const answerDisplay = (answer: Q.SelectableAnswer, index: number) => {
    return (
      <SelectableAnswerDisplay
        answer={answer}
        selectionState={selectionState(answer, selected(answer), questionBody.correctAnswers)}
        selectionText={selectionKey}
        key={index}
      />
    )
  }
  const correct = questionBody.correctAnswers.map((answer, index) => answerDisplay(answer, index));
  const other = questionBody.otherAnswers.map((answer, index) => answerDisplay(answer, index))
  return (
    <B.MultiSelectOutlineDisplay
      correctSlot={<>{correct}</>}
      otherSlot={<>{other}</>}
    />
  )
}

export function TrueFalseComparison(props: QuestionResultDetailProps) {
  const questionBody = props.question.question.question.body as Q.TrueFalse;
  const explanation = props.question.quizSpecific.scoreExplanation as R.TrueFalseExplanation;
  const selectedText = g('singleSelectionIndicator');
  const correct = props.question.quizSpecific.score.points === 1;
  const incorrect = explanation.answerAttempted && !correct;
  const falseCorrect = !questionBody.validity && correct;
  const trueCorrect = questionBody.validity && correct;
  const falseIncorrect = questionBody.validity && incorrect;
  const trueIncorrect = !questionBody.validity && incorrect;
  const trueSelected = trueCorrect || trueIncorrect;
  const falseSelected = falseCorrect || falseIncorrect;
  const falseHeader = falseSelected ? selectedText : "";
  const trueHeader = trueSelected ? selectedText : "";
  const accuracyTextClass = (correct: boolean, incorrect: boolean) => {
    return styles[
      correct ? 'correctly-selected-text' :
      incorrect ? 'incorrectly-selected-text' : 'unselected-text'
    ];
  };
  const variant = correct ? 'correct' : 'incorrect';
  return (
    <div className={styles['true-false-match']}>
      <div className={accuracyTextClass(falseCorrect, falseIncorrect)}>{falseHeader}</div>
      <div className={accuracyTextClass(trueCorrect, trueIncorrect)}>{trueHeader}</div>
      <B.TrueFalseOption onClick={() => {}} variant={variant} textKey={'false'} selected={falseSelected}/>
      <B.TrueFalseOption onClick={() => {}} variant={variant} textKey={'true'} selected={trueSelected}/>
    </div>
  )
}

export function OrderingComparison(props: QuestionResultDetailProps) {
  const responseOrderText = g('responseOrderHeader');
  const questionOrderText = g('questionOrderHeader');
  const questionBody = props.question.question.question.body as Q.Ordering;
  const inactivePartitions = new Set(questionBody.inactivePartitions);
  inactivePartitions.add(questionBody.order.length - 1);
  const explanation = props.question.quizSpecific.scoreExplanation as R.OrderingExplanation;
  const settings = props.question.question.question.settings as OrderingModel;
  const present = new Set(explanation.order.map(answer => answer.answerPosition));
  const responseOrder = [...explanation.order.sort((a, b) => {
    return a.response.position - b.response.position;
  })];
  const questionOrder = [...questionBody.order.sort((a, b) => {
    return a.position - b.position;
  })];
  const labelClass = styles['ordering-label'];
  return (
    <div>
      <B.AnswerHeader>{responseOrderText}</B.AnswerHeader>
      {responseOrder.map((answer, index) => (
        <OrderingAnswerDisplay
          answer={answer.response}
          positionOverride={index}
          labelType={settings.labelType}
          highlight={inOrderHighlight(answer.inOrder)}
          partitionAfter={false}
        />
      ))}
      <B.AnswerHeader>{questionOrderText}</B.AnswerHeader>
      {questionOrder.map((answer, index) => (
        <OrderingAnswerDisplay
          answer={answer}
          positionOverride={index}
          labelType={settings.labelType}
          highlight={presentHighlight(present.has(answer.position))}
          partitionAfter={!inactivePartitions.has(answer.position)}
        />
      ))}
    </div>
  );
}

type SelectionState = 'correctly-selected' | 'incorrectly-selected' | 'unselected';

function selectionState(
  answer: Q.SelectableAnswer,
  selected: boolean,
  correctAnswers: Q.SelectableAnswer[]
): SelectionState {
  if (!selected) {
    return 'unselected';
  }
  return correctAnswers.find(a => {
    return Q.selectableAnswerEquals(answer, a);
  }) ? 'correctly-selected' : 'incorrectly-selected';
}

type SelectableAnswerDisplayProps = {
  answer: Q.SelectableAnswer;
  selectionState: SelectionState;
  selectionText: GlossaryKey;
};

function SelectableAnswerDisplay(props: SelectableAnswerDisplayProps) {
  const boxSelectionClass = `${props.selectionState}-box`;
  const textSelectionClass = styles[`${props.selectionState}-text`];
  const boxClassName = `${styles['selectable-answer-display']} ${styles[boxSelectionClass]}`;
  const selected = props.selectionState === 'correctly-selected' || props.selectionState === 'incorrectly-selected';
  const text = g(props.selectionText);
  return (
    <div className={styles['selectable-answer-wrapper']}>
      {selected ? <div className={textSelectionClass}>{text}</div> : null}
      <B.SelectableAnswerDisplay answer={props.answer} extraClasses={boxClassName}/>
    </div>
  );
}

type OutOfOrderIndicatorProps = {
  inOrder: boolean | undefined | null;
  correct: boolean;
  distance: number;
  correctAnswer: Content | null;
};

function OutOfOrderIndicator(props: OutOfOrderIndicatorProps) {
  const inOrderText = g('inOrderIncorrectIndicator');
  const incorrectText = g('outOfOrderIndicator');
  const noMatchText = g('noMatchIndicator')
  const className = styles['order-indicator'];
  if (props.inOrder === null || props.inOrder === undefined || (props.inOrder && props.correct)) {
    return null;
  }
  let text = "";
  const percent = props.correctAnswer === null ? 0 : matchPercentage(props.distance, stringRepresentation(props.correctAnswer).length);
  const closePercent = percent > 0.5;
  if (props.inOrder && closePercent) {
    text = inOrderText;
  } else if (props.correctAnswer === null) {
    text = noMatchText;
  } else if (closePercent) {
    text = incorrectText;
  } else {
    text = noMatchText;
  }
  return <span className={className}>{text}</span>;
}

type AnswerResponseComparisonProps = {
  answer: Content | null;
  response: Content;
  fullyCorrect: boolean;
}

function AnswerResponseComparison(props: AnswerResponseComparisonProps) {
  const noMatchText = g('noMatchIndicator');
  const responseClass = props.fullyCorrect ? '' : styles['text-response-display'];
  return (
    <div className={styles['answer-response-comparison']}>
      <div className={responseClass}>
        {props.fullyCorrect ? null : <label>{g('singleSelectionIndicator')}</label>}
        <TextDisplay value={props.response}/>
      </div>
      {props.fullyCorrect ? null : (
        <div className={styles['text-answer-display']}>
          {props.answer ? <TextDisplay value={props.answer}/> : <span>{noMatchText}</span>}
          <label>{g('correctAnswer')}</label>
        </div>
      )}
    </div>
  );
}

type EditInstructionsProps = {
  response: Content;
  answer: Content;
  edits: R.EditOperation[];
  settings: QuestionSettingsModel;
  distance: number;
  inline: boolean;
};

function EditInstructions(props: EditInstructionsProps) {
  const [open, setOpen] = useState(false);
  return (
    <div className={styles['edit-instructions']}>
      <span className={styles['edit-instruction-label']} onClick={() => setOpen(!open)}>
        <span className={styles['edit-explanation-open']}>{g('showEditExplanation')}</span>
        <ExpansionIndicator open={open}/>
      </span>
      {open ? <EditExpandableContent {...props}/> : null}
    </div>
  );
}

function EditExpandableContent(props: EditInstructionsProps) {
  const length = stringRepresentation(props.answer).length;
  const maxDistance = potentialErrorMargin(props.settings, length);
  const distanceSummary = gt('distanceSummary', [`${props.distance}`, `${maxDistance}`]);
  const distanceSummaryClass = props.inline ? styles['distance-summary'] : '';
  return (
    <>
      <div className={distanceSummaryClass}>{distanceSummary}</div>
      {props.edits.map(edit => (
        <EditDisplay response={props.response} answer={props.answer} edit={edit}/>
      ))}
    </>
  );
}

type EditDisplayProps = {
  response: Content;
  answer: Content;
  edit: R.EditOperation;
}

export function EditDisplay(props: EditDisplayProps) {
  let editSpecific = null;
  const forwardProps = {
    response: stringRepresentation(props.response),
    answer: stringRepresentation(props.answer),
    edit: props.edit
  }
  if (R.isSubstitution(props.edit)) {
    editSpecific = <SubstitutionEditDisplay {...forwardProps}/>
  }
  if (R.isDeletion(props.edit)) {
    editSpecific = <DeletionEditDisplay {...forwardProps}/>
  }
  if (R.isInsertion(props.edit)) {
    editSpecific = <InsertionEditDisplay {...forwardProps}/>
  }
  return (
    <div className={styles['edit-operation']}>
      {editSpecific}
    </div>
  )
}

type SpecificEditDisplayProps = {
  response: string;
  answer: string;
  edit: R.EditOperation;
};

function SubstitutionEditDisplay(props: SpecificEditDisplayProps) {
  const edit = props.edit as R.Substitution;
  const oldString = props.response.substring(edit.responseStart, edit.responseEnd);
  const newString = props.answer.substring(edit.answerStart, edit.answerEnd);
  return (
    <>
      <label className={styles['edit-label']}>
        {gt('substitutionExplanation', [oldString, newString, `${edit.responseStart}`])}
      </label>
      <EditComparison
        answer={props.answer}
        response={props.response}
        responseMinIndex={edit.responseStart}
        responseMaxIndex={edit.responseEnd}
        answerMinIndex={edit.answerStart}
        answerMaxIndex={edit.answerEnd}
        highlightClass={styles['substitution-trace']}
      />
    </>
  );
}

function InsertionEditDisplay(props: SpecificEditDisplayProps) {
  const edit = props.edit as R.Insertion;
  const minIndex = edit.answerStart;
  const maxIndex = edit.answerEnd;
  const newString = props.answer.substring(minIndex, maxIndex);
  return (
    <>
      <label className={styles['edit-label']}>
        {gt('insertionExplanation', [newString, `${minIndex}`])}
      </label>
      <EditComparison
        answer={props.answer}
        response={props.response}
        answerMinIndex={minIndex}
        answerMaxIndex={maxIndex}
        highlightClass={styles['insertion-trace']}
      />
    </>
  );
}

function DeletionEditDisplay(props: SpecificEditDisplayProps) {
  const edit = props.edit as R.Deletion;
  const minIndex = edit.responseStart;
  const maxIndex = edit.responseEnd;
  const oldString = props.response.substring(minIndex, maxIndex);
  return (
    <>
      <label className={styles['edit-label']}>
        {gt('deletionExplanation', [oldString, `${minIndex}`])}
      </label>
      <EditComparison
        answer={props.answer}
        response={props.response}
        responseMinIndex={minIndex}
        responseMaxIndex={maxIndex}
        highlightClass={styles['deletion-trace']}
      />
    </>
  );
}

type EditComparisonProps = {
  answer: string;
  response: string;
  responseMinIndex?: number;
  responseMaxIndex?: number;
  answerMinIndex?: number;
  answerMaxIndex?: number;
  highlightClass: string;
}

function EditComparison(props: EditComparisonProps) {
  const stylizeContent = (content: string, min: number | undefined, max: number | undefined) => {
    return (min && max && min < max) ? (
      applyEditStyles(content, props.highlightClass, min, max)
    ) : [<span>{content}</span>];
  }
  const response = stylizeContent(props.response, props.responseMinIndex, props.responseMaxIndex);
  const answer = stylizeContent(props.answer, props.answerMinIndex, props.answerMaxIndex);
  return (
    <>
      <div className={styles['text-response-display']}>
        {response}
      </div>
      <div className={styles['text-answer-display']}>
        {answer}
      </div>
    </>
  );
}

function applyEditStyles(
  content: string, className: string, minIndex: number, maxIndex: number
): ReactNode[] {
  const before = content.substring(0, minIndex);
  const highlighted = content.substring(minIndex, maxIndex);
  const after = content.substring(maxIndex);
  return [
    <span>{before}</span>,
    <span className={className}>{highlighted}</span>,
    <span>{after}</span>
  ];
}

type OrderingHighlight = 'correct-order' | 'incorrect-order' | 'present' | 'none';

function inOrderHighlight(inOrder: boolean): OrderingHighlight {
  return inOrder ? 'correct-order' : 'incorrect-order';
}

function presentHighlight(present: boolean): OrderingHighlight {
  return present ? 'present' : 'none';
}

function highlightSelectionState(highlight: OrderingHighlight): SelectionState {
  switch (highlight) {
    case 'correct-order': return 'correctly-selected';
    case 'incorrect-order': return 'incorrectly-selected';
    default: return 'unselected';
  }
}

function correctnessHighlight(highlight: OrderingHighlight): boolean {
  return highlight === 'correct-order' || highlight === 'incorrect-order';
}

type OrderingAnswerDisplayProps = {
  answer: Q.OrderingAnswer;
  positionOverride: number;
  labelType: AnswerLabel | null;
  highlight: OrderingHighlight;
  partitionAfter: boolean;
};

function OrderingAnswerDisplay(props: OrderingAnswerDisplayProps) {
  const highlight = props.highlight;
  const correctness = correctnessHighlight(highlight);
  const text = correctness ? g(highlight === 'correct-order' ? 'answerInOrder' : 'answerOutOfOrder') : "";
  const accuracyState = highlightSelectionState(props.highlight);
  const textStyle = styles[`${accuracyState}-text`];
  const presentStyle = highlight === 'present' ? styles['ordering-present'] : '';
  const boxStyle = `${styles[`${accuracyState}-box`]} ${presentStyle} ${styles['ordering-box']}`;
  return (
    <div>
      {correctness ? <div className={textStyle}>{text}</div> : null}
      <B.OrderingAnswerDisplay
        answer={props.answer}
        positionOverride={props.positionOverride}
        labelType={props.labelType}
        extraClasses={boxStyle}
      />
      <B.OrderingPartition active={props.partitionAfter}/>
    </div>
  );
}
