import React, { useState } from 'react';
import styles from "./TextEditor.module.scss";
import { useGlossary as g } from "../language";
import * as S from "./editorState";

const categoryNames = ['latin', 'greek', 'cyrillic', 'hebrew', 'arabic', 'symbols', 'runic'] as const;
type SymbolCategoryName = typeof categoryNames[number];

type CodeRange = {
  min: number;
  max: number;
}

type SymbolCategory = {
  key: SymbolCategoryName;
  domain: CodeRange[];
}

function constructSymbolCategory(key: SymbolCategoryName, edges: number[]): SymbolCategory {
  if (edges.length % 2 === 1) {
    throw new Error("couldn't construct range from edges array");
  }
  let domain: CodeRange[] = [];
  for (let i = 0; i < edges.length; i += 2) {
    domain.push({
      min: edges[i],
      max: edges[i + 1]
    });
  }
  return { key, domain };
}

function codeDoman(category: SymbolCategory): number[] {
  let codes: number[] = [];
  category.domain.forEach(range => {
    for (let i = range.min; i <= range.max; i++) {
      codes.push(i);
    }
  });
  return codes;
}

const categories = [
  constructSymbolCategory('latin', [0x00A1, 0x02AF, 0x1E00, 0x1EFF]),
  constructSymbolCategory('symbols', [
    0x2000, 0x206F, 0x2070, 0x209F, 0x20A0, 0x20CF, 0x2100, 0x214F, 0x2150, 0x218F,
    0x2190, 0x21FF, 0x2200, 0x22FF, 0x2300, 0x23FF, 0x2600, 0x26FF
  ]),
  constructSymbolCategory('greek', [0x0370, 0x03FF]),
  constructSymbolCategory('cyrillic', [0x0500, 0x052F]),
  constructSymbolCategory('hebrew', [0x0590, 0x05FF]),
  constructSymbolCategory('arabic', [0x0600, 0x06FF]),
  constructSymbolCategory('runic', [0x16A0, 0x16FF])
];

type SymbolPickerProps = {
  blanksOnly: boolean;
};

export default function SymbolPicker(props: SymbolPickerProps) {
  const [openCategories, setOpenCategories] = useState<Set<SymbolCategoryName>>(new Set());
  const editor = S.useEditorContext();
  const opener = (category: SymbolCategoryName) => {
    if (openCategories.has(category)) {
      openCategories.delete(category);
    } else {
      openCategories.add(category);
    }
    setOpenCategories(new Set(openCategories));
  }
  const adder = (code: number) => {
    const stringSymbol = String.fromCharCode(code);
    editor.insertCharacter(props.blanksOnly, stringSymbol);
  };
  return (
    <div className={styles['symbol-picker']}>
      {categories.map(category => (
        <SymbolCategorySection
          category={category}
          open={openCategories.has(category.key)}
          onLabelClick={() => opener(category.key)}
          onSymbolClick={adder}
          key={category.key}
        />
      ))}
    </div>
  )
}

type SymbolCategorySectionProps = {
  category: SymbolCategory;
  open: boolean;
  onLabelClick: () => void;
  onSymbolClick: (_: number) => void;
}

function SymbolCategorySection(props: SymbolCategorySectionProps) {
  return (
    <div>
      <div
        className={styles['symbol-section-header']}
        onClick={event => {
          event.stopPropagation();
          props.onLabelClick();
        }}
      >
        {g(props.category.key)}
      </div>
      {props.open ? (
        <div className={styles['symbol-grid']}>
          {codeDoman(props.category).map(code => (
            <span
              className={styles['unicode-character']}
              key={code}
              onClick={() => props.onSymbolClick(code)}
            >
              {String.fromCharCode(code)}
            </span>
          ))}
        </div>
      ) : null}
    </div>
  )
}
