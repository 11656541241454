import * as S from "./editorState";

const FOCUS_SEARCH_INTERVAL = 5;

export const CURSOR_EPSILON = 0.1;

export function selectionFocusNode(): Text | null {
  const selection = document.getSelection();
  if (selection) {
    return selection.focusNode as Text;
  } else {
    return null;
  }
}

export function focusCorrespondingNode(fragmentId: string, end?: boolean) {
  focusCorrespondingNodePositionally(fragmentId, end ? 'end' : 'start');
}

export type Position = 'start' | 'end' | number;

export function focusCorrespondingNodePositionally(fragmentId: string, position: Position) {
  const focusInterval = setInterval(() => {
    const focusTarget = document.getElementById(fragmentId);
    const selection = document.getSelection();
    if (selection && focusTarget) {
      clearInterval(focusInterval);
      focusTarget.focus();
      if (focusTarget.childNodes.length === 0) {
        const text = document.createTextNode("");
        focusTarget.appendChild(text);
      }
      setPosition(focusTarget.childNodes[0] as Text, position);

    }
  }, FOCUS_SEARCH_INTERVAL);
}

export function setPosition(node: Text, position: Position) {
  const selection = document.getSelection();
  if (selection) {
    selection.removeAllRanges();
    const range = document.createRange();
    const offset = resolvePosition(position, node);
    range.setStart(node, Math.max(node.data.length, Math.max(0, offset)));
  }
}

function resolvePosition(position: Position, node: Text): number {
  switch (position) {
    case 'start': return 0;
    case 'end': return node.data.length;
    default: return position;
  }
}

export function selectionFocusText(): string {
  return selectionFocusNode()?.data || "";
}

export function selectionFocusOffset(): number {
  return document.getSelection()?.focusOffset || 0;
}

export function selectionId(): string | null {
  return selectionFocusNode()?.parentElement?.id || null;
}

export function textFragmentId(text: Text): string | null {
  return text.parentElement?.id || null;
}

export function fragmentIsSelection(fragmentId: string): boolean {
  const selection_id = selectionId();
  return selection_id !== null && fragmentId === selection_id;
}

export function cursorSplitText(): [string, string] | null {
  const focusNode = selectionFocusNode();
  if (focusNode) {
    const focusText = focusNode?.data || "";
    const offset = selectionFocusOffset();
    const priorText = focusText.substring(0, offset);
    const subsequentText = focusText.substring(offset);
    return [priorText, subsequentText];
  } else {
    return null;
  }
}

export function fragmentCursorIndex(): number | null {
  const texts = cursorSplitText();
  if (texts !== null) {
    return texts[0].length;
  } else {
    return null;
  }
}

export function startOffset(): boolean {
  return selectionFocusOffset() === 0;
}

export function endOffset(): boolean {
  return selectionFocusOffset() === selectionFocusText().length;
}

export function fragmentElement(fragmentId: string): Element | null {
  return document.getElementById(fragmentId);
}

export function boundingBox(fragmentId: string): DOMRect | null {
  return fragmentElement(fragmentId)?.getBoundingClientRect() || null;
}

export function cursorCoordinates(): DOMRect | null {
  const selection = document.getSelection();
  return document.getSelection()?.getRangeAt(0).getBoundingClientRect() || null;
}

export function endSelectionCursorCoordinates(selection: S.SelectionDetermination): DOMRect | null {
  if (selection.collapsed) {
    return cursorCoordinates();
  }
  return characterGeometry(selection.end.fragmentId, selection.end.index)?.bounding || null;
}

export function verticalArrowIntersectsSameElement(): boolean {
  return false;
}

export function fragmentLineHeightPixels(fragmentId: string): number | null {
  const element = document.getElementById(fragmentId);
  if (element) {
    const style = window.getComputedStyle(element);
    return Number.parseInt(style.lineHeight?.replace("px", ""), 10);
  } else {
    return null;
  }
}

export function fragmentText(fragmentId: string): Text | null {
  return document.getElementById(fragmentId)?.childNodes[0] as Text || null;
}

export function fragmentTextInstalled(fragmentId: string) {
  const element = document.getElementById(fragmentId);
  if (element) {
    if (element.childNodes.length > 0) {
      return element.childNodes[0]
    } else {
      element.appendChild(document.createTextNode(""));
      return element.childNodes[0];
    }
  }
  return null;
}

export function fragmentContents(fragmentId: string): string | null {
  if (document.getElementById(fragmentId) === null) {
    return null;
  }
  return fragmentText(fragmentId)?.data || "";
}

export function fragmentTextLength(fragmentId: string): number {
  return document.getElementById(fragmentId)?.innerText.length || 0;
}

export function setFragmentText(fragmentId: string, text: string) {
  const element = document.getElementById(fragmentId);
  if (element) {
    element.textContent = text;
  }
}

export function characterGeometry(fragmentId: string, index: number): S.CharacterLocality | null {
  try {
    const text = fragmentText(fragmentId);
    if (text) {
      const range = document.createRange();
      range.setStart(text, index);
      range.setEnd(text, index + 1);
      const rectangle = range.getBoundingClientRect();
      return {
        bounding: rectangle,
        encapsulating: text,
        index,
        fragmentId,
        character: range.toString()
      };
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

export function emptyBlankGeometry(fragmentId: string): S.CharacterLocality | null {
  let text = fragmentText(fragmentId);
  if (!text) {
    const element = document.getElementById(fragmentId);
    if (element) {
      text = document.createTextNode("");
      element.appendChild(text)
    }  
  }
  if (text) {
    const range = document.createRange();
    range.setStart(text, 0);
    range.setEnd(text, 0);
    const rectangle = range.getBoundingClientRect();
    return {
      bounding: rectangle,
      encapsulating: text,
      index: fragmentTextLength(fragmentId) || 0,
      fragmentId,
      character: ""
    }
  } else {
    return null;
  }
}

export function characterPastCursor(boundingRectangle: DOMRect, s: S.SelectionDetermination): boolean {
  const rightx = boundingRectangle.right;
  const leftx = boundingRectangle.left;
  const midy = boundingRectangle.top + boundingRectangle.height / 2;
  const cc = endSelectionCursorCoordinates(s)!;
  const cmidy = cc.top + cc.height / 2;
  const cursorx = cc.x;
  const rightmatch = Math.abs(cursorx - rightx) < CURSOR_EPSILON;
  const leftmatch = Math.abs(cursorx - leftx) < CURSOR_EPSILON;
  const xmatch = rightmatch || leftmatch;
  const ymatch = Math.abs(cmidy - midy) < CURSOR_EPSILON;
  return xmatch && ymatch;
}

export function selectionRange(): Range | null {
  return document.getSelection()?.getRangeAt(0) || null;
}

export function setSelection(s: S.SelectionDetermination) {
  let range = document.createRange();
  const anchorNode = fragmentTextInstalled(s.anchor.fragmentId);
  const endNode = fragmentTextInstalled(s.end.fragmentId);
  if (anchorNode && endNode) {
    
    // range.setStart(anchorNode, s.anchor.index);
    // range.setEnd(endNode, s.end.index);
    setRangeSafe(range, anchorNode as Text, endNode as Text, s.anchor.index, s.end.index)
    const ds = document.getSelection();
    ds?.removeAllRanges();
    const anchorIndex = safeTextIndex(anchorNode as Text, s.anchor.index);
    const endIndex = safeTextIndex(endNode as Text, s.end.index);
    ds?.setBaseAndExtent(anchorNode as Text, anchorIndex, endNode as Text, endIndex);
    if (s.collapsed) {
      ds?.collapse(endNode, endIndex);
    }
  }
}

function setRangeSafe(range: Range, anchorNode: Text, endNode: Text, anchorIndex: number, endIndex: number) {
  if (range && anchorNode?.data && endNode?.data) {
    const anchorNodeLength = anchorNode.data.length;
    const endNodeLength = endNode.data.length;
    range.setStart(anchorNode, Math.max(0, Math.min(anchorNodeLength, anchorIndex)));
    range.setEnd(endNode, Math.max(0, Math.min(endNodeLength, endIndex)));
  }
}

function safeTextIndex(node: Text, target: number): number {
  if (node?.data) {
    return Math.max(0, Math.min(target, node.data.length));
  }
  return 0;
}

export function setFutureSelection(s: S.SelectionDetermination) {
  const interval = setInterval(() => {
    if (document.getElementById(s.end.fragmentId) && document.getElementById(s.anchor.fragmentId)) {
      setSelection(s);
      clearInterval(interval);
    }
  }, 10);
}

export function documentSelectionDetermination(): S.SelectionDetermination | null {
  const selection = document.getSelection();
  if (selection) {
    const range = selection.getRangeAt(0);
    const anchorFragment = textFragmentId(selection.anchorNode as Text);
    const endFragment = textFragmentId(selection.focusNode as Text);
    const anchorIndex = selection.anchorOffset;
    const endIndex = selection.focusOffset;
    if (anchorFragment && endFragment) {
      return S.SelectionDetermination(anchorFragment, anchorIndex, endFragment, endIndex);
    }
  }
  return null;
}

export function fixSelection(editorSelection: S.SelectionDetermination) {
  const documentSelection = documentSelectionDetermination();
  if (documentSelection && !S.selectionEquals(editorSelection, documentSelection)) {
    setSelection(editorSelection);
  }
}

export function *iterateFragmentText(fragmentId: string): Generator<S.CharacterLocality> {
  for (let i = 0; i < fragmentTextLength(fragmentId); i++) {
    const cg = characterGeometry(fragmentId, i);
    if (cg) {
      yield cg;
    }
  }
}

export function xIntersect(box: DOMRect, x: number): boolean {
  return x >= box.x && x <= box.x + box.width;
}

export function yIntersect(box: DOMRect, y: number): boolean {
  return y >= box.y && y <= box.y + box.height;
}

export function intersectBox(box: DOMRect, x: number, y: number): boolean {
  return xIntersect(box, x) && yIntersect(box, y);
}

export async function readClipboard(): Promise<S.CopyPayload> {
  let text = "";
  let payload: S.CopyPayload | null = null;
  const items = await navigator.clipboard.read();
  for (const clipboardItem of items) {
    for (const t of clipboardItem.types) {
      const result = await clipboardItem.getType(t).then(result => result.text());

      if (t === "text/html") {
        try {
          const r = result.replace("<meta charset=\"utf-8\">", "");
          const potentialPayload = JSON.parse(r);
          if (S.isPayload(potentialPayload)) {
            payload = potentialPayload;
          }
        } catch (e) {
          console.error(e);
        }
      } else if (t === "text/plain") {
        text = result;
      }
    }
  }
  if (payload === null) {
    return S.payloadFromString(text);
  } else {
    return payload;
  }
}

export function refreshedFragments(fragments: S.EditorFragment[]): S.EditorFragment[] {
  return fragments.map(fragment => {
    if (S.isTextContent(fragment)) {
      return {
        ...fragment,
        content: fragmentContents(fragment.fragmentId) || ""
      };
    } else if (S.isBlank(fragment)) {
      return {
        ...fragment,
        blankContent: fragmentContents(fragment.fragmentId) || ""
      }
    } else {
      return fragment;
    }
  })
}

export type FragmentGeometry = {
  fragment: S.EditorFragment;
  rectangle: DOMRect;
  index: number;
}

export function *iterateBlankGeometry(fragments: S.EditorFragment[]): Generator<FragmentGeometry> {
  for (let i = 0; i < fragments.length; i++) {
    if (S.isAnyBlank(fragments[i])) {
      const rectangle = document.getElementById(fragments[i].fragmentId)?.getBoundingClientRect();
      if (rectangle) {
        yield { fragment: fragments[i], rectangle, index: i };
      }
    }
  }
}